import {useCallback, useEffect, useState} from 'react';

export function useLocalStorageSync(key: string, initialValue: string) {
  const getSnapshot = () => getLocalStorageItem(key);
  const store = useSyncStore(subscribe, getSnapshot);

  const setStore = useCallback(
    (value: string) => {
      try {
        if (value === undefined || value === null) {
          removeLocalStorageItem(key);
        } else {
          setLocalStorageItem(key, value);
        }
      } catch (e) {
        console.warn(e);
      }
    },
    [key, store]
  );

  useEffect(() => {
    if (
      getLocalStorageItem(key) === null &&
      typeof initialValue !== 'undefined'
    ) {
      setLocalStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  return [store ? store : initialValue, setStore] as const;
}

function useSyncStore(subscribe: any, getSnapshot: any) {
  const [data, setData] = useState(getSnapshot());

  const update = useCallback(() => {
    setData(getSnapshot());
  }, []);

  useEffect(() => {
    update();
    return subscribe(update);
  }, [update]);

  return data;
}

function subscribe(callback: any) {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
}

function getLocalStorageItem(key: string) {
  return window.localStorage.getItem(key);
}

function setLocalStorageItem(key: string, value: string) {
  window.localStorage.setItem(key, value);
}

function removeLocalStorageItem(key: string) {
  window.localStorage.removeItem(key);
}
