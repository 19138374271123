import * as mapper from './configs';
import {EnvironmentFormConfigKey} from '../types';

export const findEnvironmentFormConfig = <T extends EnvironmentFormConfigKey>(
  type: T
) => {
  const config = mapper[type];

  if (!config) {
    throw new Error(`Config type ${type} not found`);
  }

  return config;
};
