import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';
import {okayResponse} from '../schemas';

export const contactRemove = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'DELETE /accountContactRemove',
  requestSchema: z.object({
    contactId: z.string().min(1).trim(),
    accountId: z.string().min(1).trim(),
    reason: z.string(),
  }),
  responseSchema: okayResponse,
});
