import React from 'react';

type Props = {
  rows: number;
};

export const ActionTableSkeleton: React.FC<Props> = ({rows}) => {
  const fakeRows = Array(rows).fill(null);
  return (
    <>
      {fakeRows.map((_, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                  <div className="animate-pulse w-36 bg-gray-300 h-6 rounded-md " />
                </div>
              </div>
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">
              <div className="animate-pulse w-36 bg-gray-300 h-6 rounded-md " />
            </div>
          </td>
          <td className="px-6 whitespace-nowrap text-right text-sm font-medium">
            <div className="animate-pulse w-6 bg-gray-300 h-6 rounded-md float-right" />
          </td>
        </tr>
      ))}
    </>
  );
};
