import React from 'react';
import NumberFormat from 'react-number-format';

import {InformationCircleIcon} from '@heroicons/react/24/outline';
import {PaymentMethod} from '../../lib/graphql/API';

import classNames from 'classnames';
import {getImageAndAltText} from 'lib/logos';
import {formatLast4} from 'payble-shared';

type PaymentMethodItemProps = {
  paymentMethod: PaymentMethod;
};

export const PaymentMethodItem: React.FC<PaymentMethodItemProps> = ({
  paymentMethod,
}) => {
  const {image} = getImageAndAltText(paymentMethod);

  const [showDetails, setShowDetails] = React.useState(false);

  return (
    <div
      className={classNames(
        'min-w-0 flex-1 text-sm relative',
        paymentMethod.status === 'removed' ? 'opacity-40' : 'opacity-100',
        paymentMethod.status === 'disabled' ? 'bg-red-50' : ''
      )}
    >
      {paymentMethod.status === 'disabled' && (
        <>
          <InformationCircleIcon
            className="absolute top-0 right-0 w-5 h-5 text-gray-500 cursor-pointer"
            onMouseOver={() => setShowDetails(true)}
            onMouseOut={() => setShowDetails(false)}
          />
          <div
            className={classNames(
              'absolute top-5 right-0 text-right bg-white p-1.5 rounded-md shadow-md',
              showDetails ? 'block' : 'hidden'
            )}
          >
            <span className="text-sm font-semibold text-red-400">
              Disabled - {paymentMethod.reason}
            </span>
          </div>
        </>
      )}
      {paymentMethod.status === 'removed' && (
        <>
          <InformationCircleIcon
            className="absolute top-0 right-0 w-5 h-5 text-gray-500 cursor-pointer"
            onMouseOver={() => setShowDetails(true)}
            onMouseOut={() => setShowDetails(false)}
          />
          <div
            className={classNames(
              'absolute top-5 right-0 text-right bg-white p-1.5 rounded-md shadow-md',
              showDetails ? 'block' : 'hidden'
            )}
          >
            <span className="text-sm font-semibold text-grey-400">
              Removed by contact
            </span>
          </div>
        </>
      )}

      {paymentMethod.type === 'card' && paymentMethod.card && (
        <>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            className="font-semibold text-gray-700"
          >
            Credit/Debit Card
          </label>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            id={`account-${paymentMethod.id}-description`}
            className="text-gray-500"
          >
            <div className="flex">
              <img className="flex-none object-contain h-14 w-14" src={image} />
              <div className="flex-1 mt-2 ml-4">
                <div className="font-mono">
                  {formatLast4(paymentMethod.card.last4)}
                </div>
                <div>
                  {paymentMethod.card.expiryMonth} /{' '}
                  {paymentMethod.card.expiryYear}
                </div>
              </div>
            </div>
          </label>
        </>
      )}

      {paymentMethod.type === 'directDebit' && paymentMethod.bank && (
        <>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            className="font-semibold text-gray-700"
          >
            Direct Debit{' - '}
            <span className="text-gray-500">({paymentMethod.bank.name})</span>
          </label>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            id={`account-${paymentMethod.id}-description`}
            className="text-gray-500"
          >
            <div className="flex">
              <div
                className="flex-none h-10 my-2 bg-center bg-no-repeat bg-contain w-14"
                style={{backgroundImage: `url(${image})`}}
              ></div>

              <div className="flex-1 mt-2 ml-4">
                <div className="font-mono">
                  <NumberFormat
                    value={paymentMethod.bank.bsb}
                    displayType={'text'}
                    format="###-###"
                  />
                </div>
                <div>{paymentMethod.bank.accountNumber}</div>
              </div>
            </div>
          </label>
        </>
      )}

      {paymentMethod.type === 'nzDirectDebit' && paymentMethod.nzBank && (
        <>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            className="font-semibold text-gray-700"
          >
            Direct Debit{' - '}
            <span className="text-gray-500">({paymentMethod.nzBank.name})</span>
          </label>
          <label
            htmlFor={`account-${paymentMethod.id}`}
            id={`account-${paymentMethod.id}-description`}
            className="text-gray-500"
          >
            <div className="flex">
              <div
                className="flex-none h-10 my-2 bg-center bg-no-repeat bg-contain w-14"
                style={{backgroundImage: `url(${image})`}}
              ></div>

              <div className="flex-1 mt-2 ml-4">
                <div className="font-mono">
                  <span>{paymentMethod.nzBank.accountName}</span>
                </div>
                <div>{paymentMethod.nzBank.accountNumber}</div>
              </div>
            </div>
          </label>
        </>
      )}
    </div>
  );
};
