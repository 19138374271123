import * as Sentry from '@sentry/react';
import {Result} from 'payble-shared';
import {client} from 'lib/graphql/apolloClient';
import {
  GetSignedUrlMutation,
  GetSignedUrlMutationVariables,
  GetSignedUrlDocument,
} from 'lib/graphql/API';

type GetSignedUrlOptions = {
  fileName: string;
  fileType: string;
  fileFormat: string;
};

export async function getSignedUrl({
  fileFormat,
  fileName,
  fileType,
}: GetSignedUrlOptions): Promise<Result<{fields: any; url: string}>> {
  try {
    const result = await client.mutate<
      GetSignedUrlMutation,
      GetSignedUrlMutationVariables
    >({
      mutation: GetSignedUrlDocument,
      variables: {
        input: {
          fileFormat,
          fileName,
          fileType,
        },
      },
      fetchPolicy: 'network-only',
    });

    if (result.errors) {
      return new Error(result.errors[0].message);
    }

    return {
      url: result.data?.getSignedUrl?.url as string,
      fields: result.data?.getSignedUrl?.fields,
    };
  } catch (error: unknown) {
    Sentry.captureException(error);
    return new Error('Network error');
  }
}
