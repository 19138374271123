import {
  ChevronDoubleRightIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import {Paginator} from 'components/atoms/Paginator';
import {PayerEdge} from 'lib/graphql/API';
import React from 'react';
import NumberFormat from 'react-number-format';
import {Link} from 'react-router-dom';
import {toTitleCase} from 'payble-shared';
import classNames from 'classnames';
import {TooltipMessage} from 'components/organisms/TooltipMessage';

type PayersTableProps = {
  loading: boolean;
  error?: Error;
  payers: PayerEdge[];
  goToPage?: (p: number) => void;
  page?: number;
  total?: number;
};

export const PayersTable: React.FC<PayersTableProps> = ({
  loading,
  error,
  payers,
  goToPage,
  page,
  total,
}) => {
  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Account
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Description
            </th>

            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Amount
            </th>

            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Status
            </th>

            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {loading ? (
            <tr key={'loading'}>
              <td colSpan={5} className="py-10">
                <Loading />
              </td>
            </tr>
          ) : null}
          {error ? (
            <tr key={'loading'}>
              <td colSpan={5} className="pb-10">
                <ErrorMessage message={error.message} />
              </td>
            </tr>
          ) : null}
          {!loading &&
            !error &&
            payers &&
            payers.map(edge => (
              <tr key={edge?.node?.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <div className="font-medium text-gray-900">
                        {edge?.node?.account.externalId}{' '}
                      </div>
                      <div className="text-gray-500 pt-1">
                        {toTitleCase(
                          edge?.node?.account.type ? edge.node.account.type : ''
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="divide-y divide-gray-200 md:max-w-sm overflow-hidden truncate">
                    {edge?.node?.account.description}
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="divide-y divide-gray-200">
                    {edge?.node?.account?.amountOwing && (
                      <NumberFormat
                        value={(edge.node.account.amountOwing as number) / 100}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                      />
                    )}
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <span
                    className={classNames(
                      'inline-flex rounded-full px-6 py-3 text-sm font-semibold leading-5 ',
                      `${
                        !edge?.node?.deletedAt
                          ? 'bg-green-100 text-green-800'
                          : 'bg-gray-100 text-gray-800'
                      }`
                    )}
                  >
                    {!edge?.node?.deletedAt ? (
                      'Active'
                    ) : (
                      <>
                        {edge?.node?.deletedReason ? (
                          <TooltipMessage
                            tooltipText={
                              edge?.node?.deletedReason ?? 'No reason given'
                            }
                            tooltipBgColorAndHue="gray-50"
                            tooltipTextColorAndHue="gray-600"
                            showTooltip={true}
                          >
                            <span className="inline-flex">
                              Archived
                              <QuestionMarkCircleIcon className="h-4 w-4 ml-2 text-gray-600 relative top-0.5" />
                            </span>
                          </TooltipMessage>
                        ) : (
                          'Archived'
                        )}
                      </>
                    )}
                  </span>
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <Link
                    to={`/audience/payer/${edge?.node?.payerId}`}
                    className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronDoubleRightIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paginator
        loading={loading}
        rows={payers?.length || 0}
        goToPage={goToPage}
        page={page}
        total={total}
      />
    </div>
  );
};
