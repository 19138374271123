import React from 'react';
import cn from 'classnames';
import {motion, AnimatePresence} from 'framer-motion';
import './SmsPreview.css';

function underLineLinksInText(text: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <span style={{textDecoration: 'underline'}} key={`underline-${index}`}>
          {part}
        </span>
      );
    }
    return part;
  });
}

type SmsPreviewProps = {
  messages: {key: string; text: string[]; sent: boolean}[];
};

export const SmsPreview: React.FC<SmsPreviewProps> = ({messages}) => {
  const transition = {
    type: 'spring',
    stiffness: 200,
    mass: 0.2,
    damping: 20,
  };

  return (
    <div className="mt-4">
      <div className="mt-8 flex">
        <div className="flex-1 place-items-center">
          {/* The iPhone shell */}
          <div className="mx-auto h-[712px] w-[350px] bg-black rounded-[60px] border-[14px] border-black relative overflow-hidden  shadow-xl mt-1">
            <div className="absolute inset-0 h-full w-full object-cover bg-white"></div>
            <div className="absolute top-0 inset-x-0">
              <div className="mx-auto bg-black h-6 w-40 rounded-b-3xl"></div>
            </div>
            <div className="relative">
              {/* Small icons on top right */}
              <div className="mr-5 mt-2 flex justify-end space-x-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-black"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-black"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M17.778 8.222c-4.296-4.296-11.26-4.296-15.556 0A1 1 0 01.808 6.808c5.076-5.077 13.308-5.077 18.384 0a1 1 0 01-1.414 1.414zM14.95 11.05a7 7 0 00-9.9 0 1 1 0 01-1.414-1.414 9 9 0 0112.728 0 1 1 0 01-1.414 1.414zM12.12 13.88a3 3 0 00-4.242 0 1 1 0 01-1.415-1.415 5 5 0 017.072 0 1 1 0 01-1.415 1.415zM9 16a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-black"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M8 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM15 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                  <path d="M3 4a1 1 0 00-1 1v10a1 1 0 001 1h1.05a2.5 2.5 0 014.9 0H10a1 1 0 001-1V5a1 1 0 00-1-1H3zM14 7a1 1 0 00-1 1v6.05A2.5 2.5 0 0115.95 16H17a1 1 0 001-1v-5a1 1 0 00-.293-.707l-2-2A1 1 0 0015 7h-1z" />
                </svg>
              </div>
              <div className="h-0.5 w-10 mt-1.5 mr-7 bg-black rounded ml-auto"></div>
              <div className="overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-200 hover:scrollbar-thumb-gray-300 place-self-stretch flex rounded h-[655px] min-w-full p-4">
                <AnimatePresence>
                  <ul className="chat">
                    {messages.map(({key, text, sent}, i) => (
                      <motion.li
                        key={key}
                        className={cn('messages', sent ? 'mine' : 'yours')}
                        initial="hidden"
                        animate="show"
                        variants={{
                          hidden: {
                            opacity: 0,
                            y: 300,
                          },
                          show: {
                            opacity: 1,
                            y: 0,
                            transition: {...transition, delay: i},
                          },
                        }}
                        layout
                      >
                        <div
                          className="message last text-sm antialiased"
                          style={{
                            fontFamily: 'BlinkMacSystemFont, QuickSand',
                            userSelect: 'none',
                            maxWidth: '293px',
                            wordWrap: 'break-word',
                          }}
                        >
                          {text.map((t, i) =>
                            t === '' ? (
                              <br key={i} />
                            ) : (
                              <p key={i}>{underLineLinksInText(t)}</p>
                            )
                          )}
                        </div>
                      </motion.li>
                    ))}
                  </ul>
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
