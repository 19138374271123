import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Formik} from 'formik';
import {PaymentMethodType, useAddPaymentMethodMutation} from 'lib/graphql/API';
import {auth} from '../../../lib';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from './components/FormHeader';
import {FormSubmission} from './components/FormSubmission';
import {FormErrorMessage} from '../components/FormErrorMessage';
import {DirectDebitForm} from './components/DirectDebitForm';
import {err, getBillerConfig} from 'payble-shared';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {BlockedSidebar} from '../components/BlockedSidebar';
import {
  validateBankAccount,
  validateNZBankAccount,
} from './utils/validateBankAccount';
import {getBillerGateway} from 'payble-shared/src/biller-config/handlers';
import {DirectDebitFormNZ} from './components/DirectDebitFormNZ';

function billerSupportsDirectDebit(billerSlug: string) {
  const billerConfig = getBillerConfig(billerSlug);

  const gateway = getBillerGateway({
    billerSlug: billerSlug,
    paymentMethodType:
      billerConfig.region === 'NZ' ? 'nz_direct_debit' : 'direct_debit',
  });

  return !err(gateway);
}
const FORM_INITIAL_VALUES = {
  accountName: '',
  bsbNumber: '',
  accountNumber: '',
  bank: {
    bsb: '',
    name: '',
    icon: '',
  },
};

type AddContactFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  contactId: string;
};

export const AddPaymentMethodForm: React.FC<AddContactFormProps> = ({
  disclosure: {isOpen, onClose},
  contactId,
}) => {
  const navigate = useNavigate();
  const {billerSlug: slug, billerConfig} = auth.useCurrentUser();
  const supportsDirectDebit = billerSupportsDirectDebit(slug);
  const [directDebitValid, setDirectDebitValid] = useState(false);

  const [addPaymentMethod] = useAddPaymentMethodMutation();

  if (!supportsDirectDebit) {
    return (
      <BlockedSidebar
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerTitle="Add Payment Method"
        headerDescription="Add payment method to contact."
        headline="Missing direct debit gateway"
        text="Please contact us if you would like to enable direct debit."
        icon="BuildingLibraryIcon"
      />
    );
  }

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={{
          ...FORM_INITIAL_VALUES,
          paymentMethodType:
            billerConfig.region === 'NZ'
              ? PaymentMethodType.NzDirectDebit
              : PaymentMethodType.DirectDebit,
        }}
        validate={values =>
          billerConfig.region === 'NZ'
            ? validateNZBankAccount({values})
            : validateBankAccount({values})
        }
        onSubmit={async (values, {setSubmitting}) => {
          const result = await addPaymentMethod({
            variables: {
              input: {
                contactId,
                bank:
                  values.paymentMethodType === PaymentMethodType.DirectDebit
                    ? {
                        bsb: values.bsbNumber,
                        accountName: values.accountName,
                        accountNumber: values.accountNumber,
                      }
                    : undefined,
                nzBank:
                  values.paymentMethodType === PaymentMethodType.NzDirectDebit
                    ? {
                        accountName: values.accountName,
                        accountNumber: values.accountNumber,
                      }
                    : undefined,
                paymentMethodType: values.paymentMethodType,
              },
            },
          });

          setSubmitting(false);

          const returnedContactId = result?.data?.addPaymentMethod.contact.id;

          if (returnedContactId) {
            navigate(`/audience/contact/${returnedContactId}/payment-methods`);
            onClose();
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form
            className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Add Payment Method"
                description="Add payment method to contact."
              />

              {billerConfig.region === 'NZ' ? (
                <DirectDebitFormNZ
                  value={{
                    accountNumber: values.accountNumber,
                    accountName: values.accountName,
                  }}
                  onChange={e => {
                    setFieldValue('accountNumber', e.accountNumber);
                    setFieldValue('accountName', e.accountName);
                  }}
                  onValid={setDirectDebitValid}
                  disabled={false}
                />
              ) : (
                <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                  <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="account-name"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                      >
                        Account Name
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <input
                        type="text"
                        name="accountName"
                        id="account-name"
                        placeholder="Account Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.accountName}
                        maxLength={32}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                      {errors.accountName && touched.accountName && (
                        <FormErrorMessage message={errors.accountName} />
                      )}
                    </div>
                  </div>

                  <DirectDebitForm
                    disabled={false}
                    onChange={e => {
                      setFieldValue('bsbNumber', e.bsbNumber);
                      setFieldValue('bank', e.bank);
                      setFieldValue('accountNumber', e.accountNumber);
                    }}
                    onValid={setDirectDebitValid}
                    value={{
                      accountNumber: values.accountNumber,
                      bsbNumber: values.bsbNumber,
                      bank: values.bank,
                    }}
                    errors={{
                      accountNumber: errors.accountNumber,
                      bsbNumber: errors.bsbNumber,
                    }}
                    touched={{
                      accountNumber: touched.accountNumber,
                      bsbNumber: touched.bsbNumber,
                    }}
                  />
                </div>
              )}
            </div>
            <FormSubmission
              isSubmitting={isSubmitting}
              onCancel={onClose}
              submissionDisabled={
                Object.keys(errors).length > 0 || !directDebitValid
              }
              submissionButtonText="Add Payment Method"
            />
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
