import React from 'react';
import {Payment, PaymentMethod} from 'lib/graphql/API';
import {QuestionMarkCircleIcon} from '@heroicons/react/24/outline';
import {useNavigate} from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {DateTime} from 'luxon';
import {PaymentMethodItem} from 'components/organisms/PaymentMethodItem';
import {TooltipMessage} from 'components/organisms/TooltipMessage';
import {formatToDollars} from 'payble-shared';
import {useCurrentUser} from '../../../lib/auth';

type PaymentRowProps = {
  payment: Payment;
};

export const PaymentRow: React.FC<PaymentRowProps> = ({payment}) => {
  const navigate = useNavigate();

  const click = () => {
    navigate(`/audience/account/${payment.accountId}`);
  };
  const {billerConfig} = useCurrentUser();

  const showFailedTooltip = payment.failedReason !== '';

  return (
    <tr className="hover:bg-gray-100 cursor-pointer" onClick={click}>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {payment.account ? (
          <div key={payment.account.id} className="py-2">
            <div className="text-gray-900 font-medium">
              {payment.account.externalId} (
              {billerConfig.getAccountTypeTitle(payment.account.type)})
            </div>
            <div className="text-gray-500 truncate w-96">
              {payment.account.description}
            </div>
            <div className="text-gray-500">
              <NumberFormat
                value={formatToDollars(payment.account.amountOwing)}
                displayType={'text'}
                thousandSeparator={true}
                decimalSeparator={'.'}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={'$'}
              />
            </div>
          </div>
        ) : null}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <PaymentMethodItem
          paymentMethod={payment.paymentMethod as PaymentMethod}
        />
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {payment.mode === 'payInFull' && <p className="flex">Paid in full</p>}
        {payment.mode === 'payAmount' && <p className="flex">Paid one-off</p>}
        {payment.mode === 'payInstalment' && (
          <p className="flex">Pay instalment</p>
        )}
        {payment.mode === 'payInstalmentPlanRemainder' && (
          <p className="flex">Pay remainder</p>
        )}
        <NumberFormat
          value={formatToDollars(payment.amount)}
          displayType={'text'}
          thousandSeparator={true}
          decimalSeparator={'.'}
          fixedDecimalScale={true}
          decimalScale={2}
          prefix={'$'}
        />
      </td>
      <td className="py-4 whitespace-nowrap justify-center">
        <div className="px-6 whitespace-nowrap">
          {payment.status === 'processing' && (
            <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
              Processing
            </span>
          )}
          {payment.status === 'succeeded' && (
            <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              Paid
            </span>
          )}
          {payment.status === 'failed' && (
            <TooltipMessage
              tooltipText={payment.failedReason?.replaceAll('_', ' ')}
              tooltipBgColorAndHue="gray-50"
              tooltipTextColorAndHue="red-800"
              showTooltip={showFailedTooltip}
            >
              <span
                className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800"
                onMouseOver={() => {
                  console.log('on span');
                }}
              >
                Failed
                {showFailedTooltip && (
                  <QuestionMarkCircleIcon className="h-4 w-4 ml-2 text-red-800 relative top-0.5" />
                )}
              </span>
            </TooltipMessage>
          )}
        </div>
      </td>
      <td className="py-4 whitespace-nowrap justify-center">
        <div className="px-6 whitespace-nowrap">
          {payment.paymentMethod?.type === 'card' ? (
            <div>
              {payment.status === 'succeeded' && (
                <>
                  <div className="text-gray-500 text-xs font-semibold mt-2">
                    Succeeded At
                  </div>
                  <div className="text-sm">
                    {DateTime.fromISO(payment.succeededAt ?? '').toFormat(
                      'dd MMM yyyy'
                    )}
                  </div>
                </>
              )}
              {payment.status === 'failed' && (
                <>
                  <div className="text-gray-500 text-xs font-semibold mt-2">
                    Failed At
                  </div>
                  <div className="text-sm">
                    {!!payment.failedAt &&
                      DateTime.fromISO(payment.failedAt).toFormat(
                        'dd MMM yyyy'
                      )}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div>
              <div className="text-gray-500 text-xs font-semibold mt-2">
                Processed At
              </div>
              <div className="text-sm">
                {!!payment.processedAt &&
                  DateTime.fromISO(payment.processedAt).toFormat('dd MMM yyyy')}
              </div>

              {payment.status === 'succeeded' && (
                <>
                  <div className="text-gray-500 text-xs font-semibold mt-2">
                    Succeeded At
                  </div>
                  <div className="text-sm">
                    {!!payment.succeededAt &&
                      DateTime.fromISO(payment.succeededAt).toFormat(
                        'dd MMM yyyy'
                      )}
                  </div>
                </>
              )}
              {payment.status === 'failed' && (
                <>
                  <div className="text-gray-500 text-xs font-semibold mt-2">
                    Failed At
                  </div>
                  <div className="text-sm">
                    {!!payment.failedAt &&
                      DateTime.fromISO(payment.failedAt).toFormat(
                        'dd MMM yyyy'
                      )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};
