import {FormErrorMessage} from 'features/audience/components/FormErrorMessage';
import {FormikErrors} from 'formik';
import {AbsoluteDate, formatToCents, formatToDollars} from 'payble-shared';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import {AddInstalmentPlanFormValues} from '../AddInstalmentPlanForm';

type EditInstalmentProps = {
  index: number;
  setFieldValue: (field: string, value: any) => void;
  setInstalment: () => void;
  values: AddInstalmentPlanFormValues;
  errors: FormikErrors<AddInstalmentPlanFormValues>;
  localStartOfDay: AbsoluteDate;
};

export const EditInstalment: React.FC<EditInstalmentProps> = ({
  index,
  setFieldValue,
  setInstalment,
  errors,
  values,
  localStartOfDay,
}) => {
  return (
    <div className="py-2 px-5">
      <div key={index} className="flex text-xs sm:text-sm items-center">
        <div className="basis-4/12 px-2">
          <label
            htmlFor={`instalments.${index}.dueAt`}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Due at
          </label>
          <div className="mt-2">
            <input
              min={localStartOfDay.toISO()}
              max={localStartOfDay.plus({years: 1}).toISO()}
              type="date"
              name={`instalments.${index}.dueAt`}
              id={`instalments.${index}.dueAt`}
              value={values.instalments[index].dueAt?.toISO()}
              onChange={e => {
                const date = AbsoluteDate.maybeFromISO(e.target.value);
                if (date) {
                  setFieldValue(`instalments.${index}.dueAt`, date);
                }
              }}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="basis-4/12 text-left px-2">
          <label
            htmlFor={`instalments.${index}.amount`}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Amount
          </label>
          <div className="mt-2">
            <CurrencyInput
              className="focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md w-full"
              placeholder="Amount"
              name={`instalments.${index}.amount`}
              id={`instalments.${index}.amount`}
              prefix="$"
              allowNegativeValue={false}
              decimalsLimit={2}
              defaultValue={formatToDollars(values.instalments[index].amount)}
              onValueChange={(value, __name) => {
                setFieldValue(
                  `instalments.${index}.amount`,
                  value ? formatToCents(parseFloat(value)) : ''
                );
              }}
            />
          </div>
        </div>
        <div className="basis-2/12 justify-center text-right px-2 pl-6"></div>
        <div className="basis-2/12 px-2 text-right">
          <button
            type="button"
            disabled={!!errors.instalments?.[index]}
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => {
              setInstalment();
            }}
          >
            Set
          </button>
        </div>
      </div>
      {errors?.instalments?.[index] && (
        <div className="sm:col-span-12 px-2">
          <FormErrorMessage
            message={errors.instalments[index].toString() || ''}
          />
        </div>
      )}
    </div>
  );
};
