import React from 'react';

import NumberFormat from 'react-number-format';
import {formatToDollars} from 'payble-shared';

import {InboxArrowDownIcon} from '@heroicons/react/24/outline';

type InstalmentOverview = {
  label: string;
  amount: number;
};

export const InstalmentsOverview: React.FC<{
  instalments: InstalmentOverview[];
  heading: string;
  label: string;
  showTotal?: boolean;
  showEmptyMessage?: boolean;
}> = ({instalments, heading, label, showTotal, showEmptyMessage}) => {
  if (instalments.length === 0 && showEmptyMessage) {
    return (
      <div className="flex  flex-col overflow-y-scroll bg-white">
        <div className="relative block w-full p-12 text-center mt-32">
          <InboxArrowDownIcon className="mx-auto h-12 w-12 text-gray-400" />
          <span className="mt-2 block font-semibold text-gray-700 m-2">
            Account has no synced instalments
          </span>
          <p className="text-sm text-gray-700">
            Currently, there are no instalments for this account. When new
            instalments become available, they will be added to the payment
            scheduled.
          </p>
        </div>
      </div>
    );
  }

  if (instalments.length === 0) {
    return null;
  }

  return (
    <>
      <div className="py-2 px-7">
        <h4 className="font-bold text-gray-900">{heading}</h4>
      </div>

      <div className="py-2 px-5">
        <div className="flex text-xs sm:text-sm items-center">
          <div className="basis-6/12 px-2">
            <strong>{label}</strong>
          </div>
          <div className="basis-6/12 text-right px-2">
            <strong>Amount</strong>
          </div>
        </div>
      </div>
      {instalments.map(({label, amount}) => (
        <div className="py-2 px-5" key={label}>
          <div className="flex text-xs sm:text-sm items-center">
            <div className="basis-6/12 px-2">{label}</div>
            <div className="basis-6/12 text-right px-2">
              <NumberFormat
                value={formatToDollars(amount)}
                displayType={'text'}
                thousandSeparator={true}
                decimalSeparator={'.'}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={'$'}
              />
            </div>
          </div>
        </div>
      ))}
      {showTotal && (
        <div className="py-2 px-5">
          <div className="flex text-xs sm:text-sm items-center">
            <div className="basis-6/12 px-2">
              <strong>Total</strong>
            </div>
            <div className="basis-6/12 text-right px-2">
              <strong>
                <NumberFormat
                  value={formatToDollars(
                    instalments.reduce((acc, {amount}) => acc + amount, 0)
                  )}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalSeparator={'.'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                />
              </strong>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
