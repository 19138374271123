import {
  DateContext,
  DateFrequency,
  err,
  generateDateSeries,
  getFrequencyDuration,
} from 'payble-shared';
import {InstalmentPreview, getId} from './generatePreviewInstlaments';
import {AbsoluteDate} from 'payble-shared/src/util/time/AbsoluteDate';

type GenerateInstallmentsBasedOnTotalAmount = {
  frequency: DateFrequency;
  startDate: AbsoluteDate;
  instalmentAmount: number;
  amountOwing: number;
};

export function generateInstalmentsBasedOnTotalAmount(
  {
    amountOwing,
    instalmentAmount,
    frequency,
    startDate,
  }: GenerateInstallmentsBasedOnTotalAmount,
  context: DateContext
): InstalmentPreview[] {
  const numberOfInstalments = Math.ceil(amountOwing / instalmentAmount);
  const totalDuration = Object.entries(getFrequencyDuration(frequency)).reduce(
    (_, [key, value]) => ({[key]: value * numberOfInstalments}),
    {}
  );

  const series = generateDateSeries(
    {
      start: startDate,
      end: startDate.plus(totalDuration),
      frequency: frequency,
    },
    context
  );

  if (err(series)) {
    throw series;
  }

  let remainingAmount = amountOwing;

  return series.map((date, index) => {
    const installmentAmountToUse =
      numberOfInstalments - index === 1 ? remainingAmount : instalmentAmount;

    const amount =
      index === numberOfInstalments ? remainingAmount : installmentAmountToUse;

    remainingAmount -= amount;

    return {
      dueAt: date,
      amount,
      __id: getId(),
    };
  });
}
