import {GetAccountsSearchQuery} from 'lib/graphql/API';

export function formatSearchResult(
  accountData: GetAccountsSearchQuery | undefined
) {
  if (!accountData?.accounts?.edges) {
    return [];
  }

  return accountData.accounts.edges?.map(edges => {
    const {id, externalId, type, description} = edges?.node || {};

    return {
      id: id || '',
      name: `${externalId} ${type} ${description}`,
      type: type || '',
    };
  });
}
