import {ClipboardDocumentCheckIcon} from '@heroicons/react/24/outline';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import {PlanRequestsTable} from 'features/plan-requests/components/PlanRequestsTable';
import {
  PlanRequest,
  useGetRequestedPlansBySearchLazyQuery,
} from 'lib/graphql/API';
import React, {useEffect} from 'react';
import {ReviewPlanRequestForm} from '../forms/ReviewPlanRequestForm';
import {useDisclosure} from 'lib/hooks/useDisclosure';

type NotificationsProps = {
  contactId: string;
};

export const Requests: React.FC<NotificationsProps> = ({contactId}) => {
  const [fetch, {data, loading, error}] = useGetRequestedPlansBySearchLazyQuery(
    {
      variables: {
        contactId,
        offset: 0,
      },
    }
  );

  const [requestedPlans, setRequestedPlans] = React.useState<
    PlanRequest | undefined
  >();

  useEffect(() => {
    fetch();
  }, []);

  const reviewRequestedPlanDisclosure = useDisclosure();

  const [page, setPage] = React.useState(1);

  const goToPage = (pageNum: number) => {
    fetch({
      variables: {contactId, offset: (pageNum - 1) * 10},
    });
    setPage(pageNum);
  };

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <ErrorMessage message={error.message} />
      </div>
    );
  }

  if (!data?.planRequests?.edges?.length) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <ClipboardDocumentCheckIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No Request</h3>
          <p className="mt-1 text-sm text-gray-500">
            All request by this contact will be displayed here.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  mt-8">
      <PlanRequestsTable
        loading={loading}
        error={error}
        planRequests={data?.planRequests?.edges ?? []}
        total={data?.planRequests.total}
        goToPage={goToPage}
        page={page}
        onClickRequestedPlan={requestedPlan => {
          setRequestedPlans(requestedPlan);
          reviewRequestedPlanDisclosure.onOpen();
        }}
      />

      <ReviewPlanRequestForm
        disclosure={reviewRequestedPlanDisclosure}
        planRequest={requestedPlans}
      />
    </div>
  );
};
