import {FC, ReactNode, createContext, useContext} from 'react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {toast} from 'payble-ui';
import {errs} from 'payble-shared';
import {ReactQueryClientError} from 'payble-shared/src/errs';
import {getBasePath} from '../lib/url';

const defaultErrorHandler = (error: unknown) => {
  const err = error as ReactQueryClientError;
  const errors = errs.fromReactQueryClient(err);
  console.error(errors);
  if (errors.find(errs.AuthenticationError)) {
    window.location.href = `${getBasePath()}/login#session-expired`;
  }
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      defaultErrorHandler(error);
    },
  }),
  defaultOptions: {
    mutations: {
      onError(error) {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Something went wrong',
        });
        defaultErrorHandler(error);
      },
    },
  },
});

type QueryProviderProps = {
  apiUrl: string;
  publicApiUrl: string;
  children: ReactNode;
};

const QueryContext = createContext({
  apiUrl: '',
  publicApiUrl: '',
});

export const useQueryContext = () => useContext(QueryContext);

export const QueryProvider: FC<QueryProviderProps> = ({
  children,
  apiUrl,
  publicApiUrl,
}) => {
  return (
    <QueryContext.Provider value={{apiUrl, publicApiUrl}}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </QueryContext.Provider>
  );
};
