import React from 'react';
import {Notification} from '../../../../lib/graphql/API';

type EmailPreviewProps = {
  notification: Notification;
};

export const EmailPreview: React.FC<EmailPreviewProps> = ({notification}) => {
  return (
    <iframe
      srcDoc={notification.preview}
      width="100%"
      style={{height: 'calc(100vh - 96px)'}}
    />
  );
};
