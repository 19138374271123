import {QuestionMarkCircleIcon, TrashIcon} from '@heroicons/react/24/outline';
import {TooltipMessage} from 'components/organisms/TooltipMessage';
import {useAPIMutation} from 'lib/api';
import {Account, ContactFragment} from 'lib/graphql/API';
import {useToast} from 'payble-ui';
import React, {useState} from 'react';
import {CancelModal} from './CancelModal';
import {classNames} from 'lib/styles';

type ContactAccountRemoveProps = {
  account: Account;
  contact: ContactFragment;
  refetch?: () => void;
};

const UNLINKABLE_PLAN_STATUS = ['cancelled', 'completed'];

export const ContactAccountRemove: React.FC<ContactAccountRemoveProps> = ({
  account,
  contact,
  refetch,
}) => {
  const [showRemoveContactModal, setShowRemoveContactModal] = useState(false);
  const {toast} = useToast();
  const {mutateAsync: contactRemove} = useAPIMutation('contactRemove', {
    query: {
      onSuccess: async () => {
        if (refetch) {
          refetch();
        }
        setShowRemoveContactModal(false);
      },
      onError: e => {
        toast({
          title: 'Failed to remove contact',
          description: e.message,
        });
      },
    },
  });

  const isPlanOwnedByContact =
    account.standing?.owner?.type === 'CONTACT' &&
    account.standing?.owner?.id === contact?.id;

  const canRemoveAccount =
    !isPlanOwnedByContact ||
    UNLINKABLE_PLAN_STATUS.includes(account.standing?.status ?? '');

  if (!contact) {
    return <div key={account.id} className="py-4 h-20"></div>;
  }

  return (
    <div key={account.id} className="py-4 h-20">
      <TooltipMessage
        tooltipText="Can't unlink account with active plans or payments"
        tooltipBgColorAndHue="gray-50"
        tooltipTextColorAndHue="gray-800"
        showTooltip={!canRemoveAccount}
      >
        <button
          className={classNames(
            canRemoveAccount
              ? 'border-red-300 bg-red-100 text-red-600'
              : 'border-gray-300 bg-gray-100 text-gray-800',
            'inline-flex rounded-full px-6 py-3 gap-2 rounded-md border text-sm font-medium'
          )}
          onClick={() => setShowRemoveContactModal(true)}
          disabled={!canRemoveAccount}
        >
          Unlink account from contact
          {canRemoveAccount ? (
            <TrashIcon className="h-5 w-5" aria-hidden="true" />
          ) : (
            <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
          )}
        </button>
      </TooltipMessage>
      <CancelModal
        headline="Unlink account"
        description="Are you sure you want to unlink this account from this contact? Please select a reason from the options below:"
        isOpen={showRemoveContactModal}
        onClose={() => setShowRemoveContactModal(false)}
        onReject={async reason => {
          await contactRemove({
            accountId: account.id,
            contactId: contact.id,
            reason,
          });
        }}
        reasons={['Customer requested', 'Ownership change', 'Other']}
      />
    </div>
  );
};
