import React, {KeyboardEventHandler, useState} from 'react';

import {XCircleIcon} from '@heroicons/react/20/solid';

import {auth} from '../../lib';
import {validateEmail} from './lib/validation';

interface PasswordInputProps {
  disabled: boolean;
  setPassword: (password: string) => void;
  password: string;
  onKeyUp: KeyboardEventHandler;
}

const PasswordInput = ({
  disabled,
  onKeyUp,
  password,
  setPassword,
}: PasswordInputProps) => {
  return (
    <>
      <label
        htmlFor="password"
        className="block text-sm font-medium text-gray-700 mt-4"
      >
        Password
      </label>
      <div className="mt-1">
        <input
          id="password"
          name="password"
          type="password"
          autoComplete="password"
          required
          minLength={3}
          disabled={disabled}
          onChange={e => setPassword(e.target.value)}
          onKeyUp={onKeyUp}
          value={password}
          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
    </>
  );
};

interface EmailInputProps {
  disabled: boolean;
  setEmail: (email: string) => void;
  email: string;
  onKeyUp: KeyboardEventHandler;
}

const EmailInput = ({disabled, onKeyUp, email, setEmail}: EmailInputProps) => {
  const styles = disabled ? 'bg-slate-100' : '';
  return (
    <>
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        Email address
      </label>
      <div className="mt-1">
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          required
          minLength={3}
          disabled={disabled}
          onChange={e => setEmail(e.target.value)}
          onKeyUp={onKeyUp}
          autoFocus={true}
          value={email}
          className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${styles}`}
        />
      </div>
    </>
  );
};

const Page: React.FC<{children: React.ReactNode}> = ({children}) => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="/cms/payble-logo.png"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-navy">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {children}
        </div>
      </div>
    </div>
  );
};

interface SubmitButtonProps {
  submit: () => unknown;
  disabled: boolean;
  loading: boolean;
}
const SubmitButton = ({submit, disabled, loading}: SubmitButtonProps) => {
  return (
    <button
      type="submit"
      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
      onClick={disabled ? undefined : submit}
      disabled={disabled || loading}
    >
      {loading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      Login
    </button>
  );
};

export const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loginFlow = auth.useLoginFlow();

  const error = auth.useAuthState(s => s.loginFlow?.error);

  const send = async () => {
    if (loginFlow.type === 'LOGGED_OUT') {
      return loginFlow.lookup(email);
    }

    if (loginFlow.type === 'STARTED') {
      return loginFlow.login(password);
    }
  };

  const loading = loginFlow.type === 'LOADING';
  const disabled = loading;

  const canSubmit =
    !loading &&
    ((loginFlow.type === 'LOGGED_OUT' && validateEmail(email)) ||
      (loginFlow.type === 'STARTED' && password.length > 3));

  const sendOnEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !disabled) {
      await send();
    }
  };

  return (
    <Page>
      <div className="space-y-6">
        <div>
          <EmailInput
            disabled={loginFlow.type === 'STARTED'}
            onKeyUp={sendOnEnter}
            email={email}
            setEmail={setEmail}
          />
          {loginFlow.type === 'STARTED' && loginFlow.method === 'PASSWORD' && (
            <PasswordInput
              disabled={false}
              onKeyUp={sendOnEnter}
              password={password}
              setPassword={setPassword}
            />
          )}
        </div>
        <div className="space-y-4">
          <SubmitButton disabled={!canSubmit} loading={loading} submit={send} />
          {loginFlow.type === 'STARTED' && (
            <button
              onClick={() => {
                loginFlow.back();
                setPassword('');
              }}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md  text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 text-blue-600 hover:text-blue-500"
            >
              Back
            </button>
          )}
        </div>
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{error}</h3>
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center justify-end">
          <div className="text-sm">
            <a
              href="https://payble.com.au/merchants/"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Don't have an account?
            </a>
          </div>
        </div>
      </div>
    </Page>
  );
};
