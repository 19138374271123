import React, {useEffect, useState} from 'react';
import {CheckIcon, XMarkIcon} from '@heroicons/react/20/solid';

import {classNames} from 'lib/styles';
import {
  NZ_FE_ACCOUNT_NAME_MAX_LENGTH,
  NZBankAccountNumber,
  validateNZBankAccountName,
} from 'payble-shared';
import {useGetBankByCodeLazyQuery} from 'lib/graphql/API';
import {useDebouncedCallback} from 'use-debounce';
import {MiniSpinner} from 'components/atoms/Spinner';
import {DirectDebitAccountNumberInputNZ} from 'payble-app-shared/src/components/DirectDebitAccountNumberInputNZ';

export type NZDirectDebitValues = {
  accountName: string;
  accountNumber: string;
};

type Props = {
  disabled: boolean;
  onChange: (value: NZDirectDebitValues) => void;
  onValid: (value: boolean) => void;
  value: NZDirectDebitValues;
};

export const DirectDebitFormNZ: React.FC<Props> = ({
  disabled,
  onChange,
  onValid,
  value,
}) => {
  const [getBankByCode, {data, loading, called, error}] =
    useGetBankByCodeLazyQuery();
  const [accountName, setAccountName] = useState<string>(value.accountName);
  const [accountNameError, setAccountNameError] = useState<string | undefined>(
    undefined
  );
  const bank = data?.bankByCode;

  const validateBank = useDebouncedCallback(
    async (nzBankAccountNumber: NZBankAccountNumber) => {
      const code = nzBankAccountNumber.toBankBranchCode();
      await getBankByCode({
        variables: {
          code,
          region: 'nz',
        },
      });
    },
    500
  );

  const [accountNumber, setAccountNumber] = useState<string>('');
  const [accountNumberError, setAccountNumberError] = useState<
    string | undefined
  >(undefined);

  const hasValidBankBranchCode = !!(called && !loading && bank);

  useEffect(() => {
    onChange({
      accountName,
      accountNumber,
    });
    onValid(
      !accountNumberError &&
        !accountNameError &&
        !!accountNumber &&
        !!accountName &&
        hasValidBankBranchCode
    );
  }, [
    accountNumberError,
    accountNameError,
    accountNumber,
    accountName,
    hasValidBankBranchCode,
  ]);

  const onAccountNameChange = (value: string) => {
    const accountName = value.trim();
    setAccountName(accountName);
    if (!validateNZBankAccountName(accountName)) {
      setAccountNameError(
        "Invalid bank account name. Must contain at least one alphanumeric character and only the symbols +-()'.&/:? are allowed."
      );
    } else {
      setAccountNameError(undefined);
    }
  };

  const onAccountNumberChange = (value: string) => {
    const accountNumber = value.trim();
    setAccountNumber(accountNumber);

    const nzBankAccountNumber =
      NZBankAccountNumber.maybeFromJSON(accountNumber);
    if (nzBankAccountNumber) {
      setAccountNumberError(undefined);
      validateBank(nzBankAccountNumber);
    } else {
      setAccountNumberError('Invalid account number');
    }
  };

  return (
    <>
      <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
        <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="accountName"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Bank Account Name
            </label>
          </div>
          <div className="sm:col-span-2">
            <input
              type="text"
              name="accountName"
              id="accountName"
              maxLength={NZ_FE_ACCOUNT_NAME_MAX_LENGTH}
              disabled={disabled}
              defaultValue={accountName}
              onChange={e => onAccountNameChange(e.target.value)}
              className={classNames(
                'mt-1 focus:ring-blue-600 focus:border-blue-600 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
                !accountNameError ? 'border-blue-600' : 'border-red-600'
              )}
            />
            {accountNameError && (
              <span className="mt-1 text-sm text-red-600">
                {accountNameError}
              </span>
            )}
          </div>
        </div>

        <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <label
            htmlFor="accountNumber"
            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
          >
            Account number
          </label>
          <div className="col-span-2 pb-2">
            <DirectDebitAccountNumberInputNZ
              onChange={value => {
                onAccountNumberChange(value);
              }}
            />
            <div className="absolute !mt-1">
              {loading && <MiniSpinner />}
              {accountNumber && accountNumberError ? (
                <div className="flex items-center gap-1">
                  <XMarkIcon className="w-4 h-4 text-red-500" />
                  <span className="text-sm text-gray-400">
                    {accountNumberError}
                  </span>
                </div>
              ) : called && !loading ? (
                bank ? (
                  <div className="flex items-center gap-1">
                    <CheckIcon className="w-4 h-4 text-green-500" />
                    <span className="text-sm text-gray-400">{bank?.name}</span>
                  </div>
                ) : (
                  <div className="flex items-center gap-1">
                    <XMarkIcon className="w-4 h-4 text-red-500" />
                    <span className="text-sm text-gray-400">
                      {error ? error.message : 'Unknown bank branch code'}
                    </span>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
