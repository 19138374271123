import {AbsoluteDate, formatMoney} from 'payble-shared';
import React from 'react';
import {InstalmentPreview} from 'features/audience/helpers/generatePreviewInstlaments';
import {fieldSorter} from 'payble-shared/src/util/time';

function groupInstalments(instalments: InstalmentPreview[]) {
  return instalments.reduce(
    (acc, payment) => {
      const amount = payment.amount;

      const existingEntry = acc.find(entry => entry.amount === amount);

      if (existingEntry) {
        existingEntry.count++;
      } else {
        acc.push({amount, count: 1});
      }

      return acc;
    },
    [] as {amount: number; count: number}[]
  );
}

type PlanSummaryProps = {
  startDate?: AbsoluteDate;
  endDate?: AbsoluteDate;
  instalments: InstalmentPreview[];
};

export const PlanSummary: React.FC<PlanSummaryProps> = ({
  startDate,
  endDate,
  instalments,
}) => {
  if (instalments.length === 0) {
    return null;
  }

  const validInstalments = instalments
    .filter(instalment => instalment.amount > 0 && instalment.dueAt)
    .sort(
      fieldSorter(instalment => instalment.dueAt, {
        dir: 'ASC',
      })
    );

  const groupedPayments = groupInstalments(validInstalments);

  const planTotal = validInstalments.reduce(
    (acc, curr) => acc + curr.amount,
    0
  );

  return (
    <section
      aria-labelledby="summary-heading"
      className="rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
    >
      <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
        Plan Summary
      </h2>

      <dl className="mt-6 space-y-4">
        {startDate ? (
          <div className="flex items-center justify-between">
            <dt className="text-sm text-gray-600">Start date</dt>
            <dd className="text-sm font-medium text-gray-900">
              {validInstalments[0]?.dueAt.toFormat('dd MMM yyyy')}
            </dd>
          </div>
        ) : null}
        {endDate ? (
          <div className="flex items-center justify-between">
            <dt className="text-sm text-gray-600">End date</dt>
            <dd className="text-sm font-medium text-gray-900">
              {validInstalments[validInstalments.length - 1]?.dueAt.toFormat(
                'dd MMM yyyy'
              )}
            </dd>
          </div>
        ) : null}

        {groupedPayments.map(({amount, count}) => (
          <div
            className="flex items-center justify-between"
            key={`${amount}-${count}`}
          >
            <dt className="text-sm text-gray-600">
              {count} Payment{count > 1 ? 's' : ''} of
            </dt>
            <dd className="text-sm font-medium text-gray-900">
              {formatMoney(amount)}
            </dd>
          </div>
        ))}

        <div className="flex items-center justify-between border-t border-gray-200 pt-4">
          <dt className="text-base font-medium text-gray-900">Plan total</dt>
          <dd className="text-base font-medium text-gray-900">
            {formatMoney(planTotal)}
          </dd>
        </div>
      </dl>
    </section>
  );
};
