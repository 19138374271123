import React from 'react';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from 'features/audience/forms/components/FormHeader';
import {DateTime} from 'luxon';
import {BillerAnalyticsEvent} from 'payble-api-client/schemas';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  event: BillerAnalyticsEvent;
}

export const AnalyticsPreview: React.FC<Props> = ({isOpen, onClose, event}) => {
  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
        <div className="flex-1">
          <FormHeader
            setOpen={onClose}
            title="Timestamp"
            description={`Sent at ${DateTime.fromJSDate(
              event.timestamp
            ).toFormat('dd MMM yyyy hh:mm a')}`}
          />

          <pre className="p-4 text-sm text-gray-500 bg-gray-100">
            {JSON.stringify(event, null, 2)}
          </pre>
        </div>
      </div>
    </PopOverSidebar>
  );
};
