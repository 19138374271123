import React from 'react';

import {PaymentMethod, ScheduledPayment} from 'lib/graphql/API';
import {useNavigate} from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {PaymentMethodItem} from 'components/organisms/PaymentMethodItem';
import {formatToDollars} from 'payble-shared/src/core';
import {useCurrentUser} from '../../../lib/auth';

type ScheduledPaymentRowProps = {
  payment: ScheduledPayment;
};

export const ScheduledPaymentRow: React.FC<ScheduledPaymentRowProps> = ({
  payment,
}) => {
  const navigate = useNavigate();

  const click = () => {
    navigate(`/audience/account/${payment.accountId}`);
  };

  const {billerConfig} = useCurrentUser();

  return (
    <tr className="hover:bg-gray-100 cursor-pointer" onClick={click}>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {payment.account ? (
          <div key={payment.account.id} className="py-2">
            <div className="text-gray-900 font-medium">
              {payment.account.externalId} (
              {billerConfig.getAccountTypeTitle(payment.account.type)})
            </div>
            <div className="text-gray-500 truncate w-96">
              {payment.account.description}
            </div>
            <div className="text-gray-500">
              <NumberFormat
                value={formatToDollars(payment.account.amountOwing)}
                displayType={'text'}
                thousandSeparator={true}
                decimalSeparator={'.'}
                fixedDecimalScale={true}
                decimalScale={2}
                prefix={'$'}
              />
            </div>
          </div>
        ) : null}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <PaymentMethodItem
          paymentMethod={payment.paymentMethod as PaymentMethod}
        />
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        <p className="flex">Pay instalment</p>
        <NumberFormat
          value={formatToDollars(payment.amount)}
          displayType={'text'}
          thousandSeparator={true}
          decimalSeparator={'.'}
          fixedDecimalScale={true}
          decimalScale={2}
          prefix={'$'}
        />
      </td>
      <td className="py-4 whitespace-nowrap justify-center">
        <div className="px-6 whitespace-nowrap">
          <span className="px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
            Scheduled
          </span>
        </div>
      </td>
      <td className="py-4 whitespace-nowrap justify-center">
        <div className="px-6 whitespace-nowrap">
          <div className="text-gray-500 text-xs font-semibold mt-2">Due At</div>
          <div className="text-sm">{payment.dueAt.toFormat('dd MMM yyyy')}</div>
        </div>
      </td>
    </tr>
  );
};
