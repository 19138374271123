import {gql} from 'graphql-request';

export const getBankByCodeGql = gql/* GraphQL */ `
  query getBankByCode($code: String!, $region: String!) {
    bankByCode(code: $code, region: $region) {
      name
      code
    }
  }
`;
