import * as React from 'react';
import {List, ListItem} from './List';
import {Text} from './Text';
import {cn} from '../lib/utils';

export interface ListKeyValueProps {
  contents: {
    label: React.ReactNode;
    value: React.ReactNode;
  }[];
  className?: string;
}

export const ListKeyValue: React.FC<ListKeyValueProps> = ({
  contents,
  className,
}) => {
  return (
    <List className={cn('rounded-md border p-4 space-y-4 my-3', className)}>
      {contents.map(({label, value}) => {
        return (
          <ListItem key={`${label}-${value}`} className="justify-between">
            <Text size="sm" weight="bold" variant="heading">
              {label}
            </Text>
            <Text size="sm" className="ml-0 text-right justify-self-end">
              {value}
            </Text>
          </ListItem>
        );
      })}
    </List>
  );
};
