import {useAPIMutation, useAPIQuery} from 'lib/api';
import {Button, ListKeyValue, Loading} from 'payble-ui';
import {useNavigate} from 'react-router-dom';

function translateDefaultValue(value: string | number | boolean) {
  if (value === true) return 'Yes';
  if (value === false) return 'No';

  return value;
}

export const AutoImportsConfig = () => {
  const {
    data: autoImportConfigs,
    isPending,
    refetch,
  } = useAPIQuery('getAllAutoImportConfigs', {data: {}});
  const navigate = useNavigate();

  const {
    mutate: archiveAutoImportConfig,
    variables: archiveVars,
    isPending: isArchiving,
  } = useAPIMutation('archiveAutoImportConfig', {
    query: {
      onSuccess: () => {
        refetch();
      },
    },
  });

  return (
    <div className="flex flex-col">
      <div className="flex flex-col w-full gap-4 p-8 my-8 bg-white rounded-lg">
        {isPending ? (
          <Loading />
        ) : !autoImportConfigs?.length ? (
          <>No auto import configs</>
        ) : (
          autoImportConfigs.map(config => (
            <div
              key={config.autoImportConfigId}
              className="flex justify-between gap-2 pl-4 bg-gray-100 rounded-md cursor-pointer"
              onClick={() => {
                navigate(
                  `/config/auto-imports/edit/${config.autoImportConfigId}`
                );
              }}
            >
              <div className="flex gap-4">
                <ListKeyValue
                  className="w-[400px] bg-white"
                  contents={[
                    {label: 'Filename Matcher', value: config.filenameMatcher},
                    {label: 'Process', value: config.processType},
                    {label: 'Strategy', value: config.strategy},
                  ]}
                />
                {config.defaults && (
                  <ListKeyValue
                    className="bg-white"
                    contents={Object.entries(config.defaults).map(
                      ([key, value]) => ({
                        label: key,
                        value: translateDefaultValue(value),
                      })
                    )}
                  />
                )}
              </div>
              <div className="grow-0">
                <Button
                  variant="ghost"
                  iconL="close"
                  isLoading={
                    archiveVars?.autoImportConfigId ===
                      config.autoImportConfigId &&
                    (isArchiving || isPending)
                  }
                  onClick={() => {
                    archiveAutoImportConfig({
                      autoImportConfigId: config.autoImportConfigId,
                    });
                  }}
                />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
