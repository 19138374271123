import React from 'react';
import {
  ChevronDoubleRightIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import {ContactEdge} from 'lib/graphql/API';
import NumberFormat from 'react-number-format';
import {Link} from 'react-router-dom';
import {AccountStanding} from './AccountStanding';
import {formatToDollars} from 'payble-shared';
import {Paginator} from 'components/atoms/Paginator';
import {auth} from '../../../lib';
import {PhoneNumberFormat} from 'payble-app-shared/src/components/PhoneNumberFormat';

type ContactTableProps = {
  loading: boolean;
  error?: Error;
  contacts: ContactEdge[];
  goToPage?: (p: number) => void;
  page?: number;
  total?: number;
  minimal?: boolean;
};

export const ContactTable: React.FC<ContactTableProps> = ({
  loading,
  error,
  contacts,
  goToPage,
  page,
  total,
  minimal,
}) => {
  const {billerSlug: slug, billerConfig} = auth.useCurrentUser();
  const displayStandings = slug !== 'agl-energy';

  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Contact
            </th>
            {!minimal && (
              <>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Account
                </th>
                {displayStandings && (
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Standing
                  </th>
                )}
              </>
            )}
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {loading ? (
            <tr key={'loading'}>
              <td colSpan={4} className="py-10">
                <Loading />
              </td>
            </tr>
          ) : null}
          {error ? (
            <tr key={'error'}>
              <td colSpan={4} className="pb-10">
                <ErrorMessage message={error.message} />
              </td>
            </tr>
          ) : null}
          {!loading &&
            !error &&
            contacts &&
            contacts.map(contact => (
              <tr key={contact?.node?.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <div className="font-medium text-gray-900">
                        {contact?.node?.title} {contact?.node?.givenName}{' '}
                        {contact?.node?.familyName}
                      </div>
                      <div className="text-gray-500 pt-1">
                        <ShieldCheckIcon
                          className={classNames(
                            'inline-flex mr-2 h-5 w-5',
                            contact?.node?.mobileVerified
                              ? 'text-green-400'
                              : 'text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                        <PhoneNumberFormat
                          value={contact?.node?.mobile}
                          region={billerConfig.region}
                        />
                      </div>
                      <div className="text-gray-500">
                        <ShieldCheckIcon
                          className={classNames(
                            'inline-flex mr-2 h-5 w-5',
                            contact?.node?.emailVerified
                              ? 'text-green-400'
                              : 'text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                        {contact?.node?.email || 'No email linked'}
                      </div>
                    </div>
                  </div>
                </td>
                {!minimal && (
                  <>
                    <td className="whitespace-nowrap pl-3 py-4 text-sm text-gray-500">
                      <div className="divide-y divide-gray-200">
                        {contact?.node?.accounts?.length === 0 && (
                          <div>No accounts linked</div>
                        )}
                        {contact?.node?.accounts?.map(account => (
                          <div key={account.id} className="py-2 h-20">
                            <div className="text-gray-900 font-medium">
                              {account.externalId} (
                              {billerConfig.getAccountTypeTitle(account.type)})
                            </div>
                            <div className="text-gray-500">
                              {account.description}
                            </div>
                            <div className="text-gray-500">
                              <NumberFormat
                                value={formatToDollars(account.amountOwing)}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalSeparator={'.'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={'$'}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </td>

                    {displayStandings && (
                      <td className="whitespace-nowrap pr-3 py-4 text-sm text-gray-500">
                        <div className="divide-y divide-gray-200">
                          {contact?.node?.accounts?.length === 0 && (
                            <div>No accounts linked</div>
                          )}

                          {contact?.node?.accounts?.map(account => (
                            <AccountStanding
                              key={account.id}
                              account={account}
                              contact={contact.node}
                            />
                          ))}
                        </div>
                      </td>
                    )}
                  </>
                )}
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <Link
                    to={`/audience/contact/${contact?.node?.id}`}
                    className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronDoubleRightIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paginator
        loading={loading}
        rows={contacts?.length || 0}
        goToPage={goToPage}
        page={page}
        total={total}
      />
    </div>
  );
};
