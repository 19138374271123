import {Button, Form, FormBuilder, Loading, useToast} from 'payble-ui';
import {autoImportsFormConfig} from 'payble-shared';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useAPIInvalidate, useAPIMutation, useAPIQuery} from 'lib/api';

export const AutoImportsConfigForm = () => {
  const {toast} = useToast();
  const invalidate = useAPIInvalidate();
  const {mutateAsync: saveAutoImportConfig} = useAPIMutation(
    'saveAutoImportConfig',
    {
      query: {
        onSuccess: () => {
          toast({
            title: 'Success',
            description: 'Saved an auto import config',
          });
        },
        onError: () => {
          toast({
            title: 'Error',
            description: 'Failed to save auto import config',
          });
        },
      },
    }
  );
  const navigate = useNavigate();
  const {autoImportConfigId} = useParams();
  const {data: defaultValues, isPending: isLoadingDefault} = useAPIQuery(
    'getAutoImportConfig',
    {
      data: autoImportConfigId ? {autoImportConfigId} : undefined,
    }
  );

  if (autoImportConfigId && isLoadingDefault) return <Loading />;

  return (
    <div className="w-full p-8 my-8 bg-white rounded-lg">
      <FormBuilder
        id="auto-import-config-form"
        context={autoImportsFormConfig.context}
        fields={autoImportsFormConfig.form}
        layout={autoImportsFormConfig.layout}
        schema={autoImportsFormConfig.validation}
        defaultValues={defaultValues}
        onSubmit={async data => {
          await saveAutoImportConfig({
            autoImportConfigId,
            filenameMatcher: data.filenameMatcher,
            processType: data.processType,
            strategy: data.strategy,
            defaults: data.defaults,
          });
          invalidate({
            queryKey: ['getAllAutoImportConfigs'],
          });
          navigate('/config/auto-imports');
        }}
      >
        <div className="flex gap-4 pt-8">
          <Link to="/config/auto-imports" className="flex w-full">
            <Button variant="outline">Cancel</Button>
          </Link>
          <Form.SubmitButton>Save</Form.SubmitButton>
        </div>
      </FormBuilder>
    </div>
  );
};
