import {GetAudienceStatsQuery} from 'lib/graphql/API';

// Format number as string
const formatNumber = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// Get percentage change between two numbers
const getPercentageChange = (oldValue: number, newValue: number) => {
  if (oldValue === 0 && newValue === 0) {
    return '-';
  }
  if (oldValue === 0) {
    oldValue = 1;
    newValue += 1;
  }

  const change = newValue - oldValue;
  const percentage = (change / oldValue) * 100;
  return `${percentage.toFixed(0)}%`;
};

type FormatStats = {
  name: string;
  stat: string;
  previousStat: string;
  change: string;
  changeType: string;
};

export function formatStats(statsData?: GetAudienceStatsQuery): FormatStats[] {
  return !statsData
    ? []
    : [
        {
          name: 'Total Accounts',
          stat: formatNumber(statsData.audienceStats.totalAccounts),
          previousStat: formatNumber(
            statsData.audienceStats.totalAccountsLastMonth
          ),
          change: getPercentageChange(
            statsData.audienceStats.totalAccountsLastMonth,
            statsData.audienceStats.totalAccounts
          ),
          changeType:
            statsData.audienceStats.totalAccounts >=
            statsData.audienceStats.totalAccountsLastMonth
              ? 'increase'
              : 'decrease',
        },
        {
          name: 'Total Contacts',
          stat: formatNumber(statsData.audienceStats.totalContacts),
          previousStat: formatNumber(
            statsData.audienceStats.totalContactsLastMonth
          ),
          change: getPercentageChange(
            statsData.audienceStats.totalContactsLastMonth,
            statsData.audienceStats.totalContacts
          ),
          changeType:
            statsData.audienceStats.totalContacts >=
            statsData.audienceStats.totalContactsLastMonth
              ? 'increase'
              : 'decrease',
        },
        {
          name: 'Active Plans',
          stat: formatNumber(statsData.audienceStats.activeInstalmentPlans),
          previousStat: formatNumber(
            statsData.audienceStats.activeInstalmentPlansLastMonth
          ),
          change: getPercentageChange(
            statsData.audienceStats.activeInstalmentPlansLastMonth,
            statsData.audienceStats.activeInstalmentPlans
          ),
          changeType:
            statsData.audienceStats.activeInstalmentPlans >=
            statsData.audienceStats.activeInstalmentPlansLastMonth
              ? 'increase'
              : 'decrease',
        },
      ];
}
