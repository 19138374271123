import {useCallback, useEffect, useRef} from 'react';

// https://github.com/chakra-ui/chakra-ui/blob/0f925f60d020896180235adf9ddeb70fa7b00c4d/packages/hooks/use-callback-ref/src/index.ts
export function useCallbackRef<T extends (...args: any[]) => any>(
  callback: T | undefined,
  deps: React.DependencyList = []
) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  return useCallback(((...args) => callbackRef.current?.(...args)) as T, deps);
}
