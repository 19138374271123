import {ChevronDoubleRightIcon} from '@heroicons/react/24/outline';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import {Paginator} from 'components/atoms/Paginator';
import {Account, AccountEdge, ContactFragment} from 'lib/graphql/API';
import React from 'react';
import NumberFormat from 'react-number-format';
import {Link} from 'react-router-dom';
import {AccountStanding} from './AccountStanding';
import {formatToDollars, toTitleCase} from 'payble-shared';
import {auth} from '../../../lib';
import {ContactAccountRemove} from './ContactAccountRemove';

type AccountTableProps = {
  loading: boolean;
  error?: Error;
  accounts: AccountEdge[];
  contact?: ContactFragment;
  goToPage?: (p: number) => void;
  page?: number;
  total?: number;
  refetch?: () => void;
};

export const AccountTable: React.FC<AccountTableProps> = ({
  loading,
  error,
  accounts,
  contact,
  goToPage,
  page,
  total,
  refetch,
}) => {
  const {billerSlug: slug} = auth.useCurrentUser();
  const displayStandings = slug !== 'agl-energy';
  const displayAmount = slug !== 'agl-energy';

  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Account
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Description
            </th>
            {displayAmount && (
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Amount
              </th>
            )}
            {displayStandings && (
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Standing
              </th>
            )}
            {contact && (
              <th
                scope="col"
                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                style={{width: 100}}
              >
                <span className="sr-only">Unlink account</span>
              </th>
            )}
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {loading ? (
            <tr key={'loading'}>
              <td colSpan={5} className="py-10">
                <Loading />
              </td>
            </tr>
          ) : null}
          {error ? (
            <tr key={'loading'}>
              <td colSpan={5} className="pb-10">
                <ErrorMessage message={error.message} />
              </td>
            </tr>
          ) : null}
          {!loading &&
            !error &&
            accounts &&
            accounts.map(edge => (
              <tr key={edge?.node?.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <div className="font-medium text-gray-900">
                        {edge?.node?.externalId}{' '}
                      </div>
                      <div className="text-gray-500 pt-1">
                        {toTitleCase(edge?.node?.type ? edge.node.type : '')}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="divide-y divide-gray-200 md:max-w-sm overflow-hidden truncate">
                    {edge?.node?.description}
                  </div>
                </td>
                {displayAmount && (
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div className="divide-y divide-gray-200">
                      <NumberFormat
                        value={formatToDollars(
                          edge?.node?.amountOwing as number
                        )}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'$'}
                      />
                    </div>
                  </td>
                )}
                {displayStandings && (
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <AccountStanding
                      key={edge?.node?.id}
                      account={edge?.node as Account}
                    />
                  </td>
                )}
                {contact && (
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <ContactAccountRemove
                      key={edge?.node?.id}
                      account={edge?.node as Account}
                      contact={contact}
                      refetch={refetch}
                    />
                  </td>
                )}
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <Link
                    to={`/audience/account/${edge?.node?.id}`}
                    className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronDoubleRightIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paginator
        loading={loading}
        rows={accounts?.length || 0}
        goToPage={goToPage}
        page={page}
        total={total}
      />
    </div>
  );
};
