import React from 'react';
import {XCircleIcon} from '@heroicons/react/20/solid';

import {BanknotesIcon} from '@heroicons/react/24/outline';
import {AnalyticsTableSkeleton} from './AnalyticsTableSkeleton';
import {AnalyticsRow} from './AnalyticsRow';
import {Paginator} from 'components/atoms/Paginator';
import {BillerAnalyticsEvent} from 'payble-api-client/schemas';

type AnalyticsTableProps = {
  events: BillerAnalyticsEvent[];
  isLoading: boolean;
  error?: Error;
  goToPage: (p: number) => void;
  page?: number;
  total?: number;
};

export const AnalyticsTable: React.FC<AnalyticsTableProps> = ({
  events,
  error,
  isLoading,
  goToPage,
  page,
  total,
}) => {
  return (
    <div className="px-4 py-8 sm:px-0">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-4">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-navy uppercase tracking-wider"
                    >
                      Event
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-navy uppercase tracking-wider"
                    >
                      Path
                    </th>
                    <th
                      scope="col"
                      className="px-7 py-3 text-left text-xs font-bold text-navy uppercase tracking-wider"
                    >
                      Device
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-navy uppercase tracking-wider"
                    >
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {isLoading && <AnalyticsTableSkeleton rows={4} />}
                  {!isLoading &&
                    !error &&
                    events?.length > 0 &&
                    events.map(event => (
                      <AnalyticsRow
                        key={`${event?.analyticEventId}`}
                        event={event!}
                      />
                    ))}
                  {!isLoading && !error && events?.length === 0 && (
                    <tr>
                      <td colSpan={5}>
                        <div className="text-center py-8">
                          <BanknotesIcon className="mx-auto h-12 w-12 text-gray-400" />
                          <h3 className="mt-2 text-sm font-medium text-gray-900">
                            No contact events yet
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Contact analytics events will display here.
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                  {!isLoading && error && (
                    <tr>
                      <td colSpan={5}>
                        <div className="rounded-md bg-red-50 p-4 m-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <XCircleIcon
                                className="h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-red-800">
                                There was an error loading the data
                              </h3>
                              <div className="mt-2 text-sm text-red-700">
                                <ul
                                  role="list"
                                  className="list-disc pl-5 space-y-1"
                                >
                                  <li>{error.message}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Paginator
                loading={isLoading}
                rows={events?.length || 0}
                goToPage={goToPage}
                page={page}
                total={total}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
