import {CheckCircleIcon, XCircleIcon} from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React, {useCallback} from 'react';
import {getSignedUrl} from 'features/audience/graphql/actions';
import {err} from 'payble-shared';
import axios from 'axios';
import {useDropzone} from 'react-dropzone';
import {useCurrentUser} from '../../../lib/auth';

export const ImportDropZone = ({refetch}: {refetch: () => void}) => {
  const [progress, setProgress] = React.useState(0);
  const [error, setError] = React.useState('');
  const [state, setState] = React.useState<
    'idle' | 'uploading' | 'error' | 'success'
  >('idle');

  const user = useCurrentUser();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const acceptedFile = acceptedFiles[0];

    if (
      import.meta.env.VITE_STAGE === 'production' &&
      user.email.includes('@payble.com.au') &&
      !window.confirm(
        `By uploading ${acceptedFile.name} you might delete or overwrite data. Are you sure you want to continue?`
      )
    ) {
      return;
    }

    setState('uploading');

    const result = await getSignedUrl({
      fileType: acceptedFile.type,
      fileName: acceptedFile.name,
      fileFormat: '',
    });

    if (err(result)) {
      setError(result.message);
      setState('error');
      return;
    }

    const {url, fields} = result;
    const formData = new FormData();
    Object.entries(fields).forEach(([k, v]) => {
      formData.append(k, v as string | Blob);
    });
    formData.append('file', acceptedFile); // The file has be the last element

    try {
      await axios.post(url, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: ({loaded, total}) => {
          if (!total) {
            return;
          }

          setProgress(Math.floor((loaded / total) * 100));
        },
      });

      setState('success');
      refetch();
    } catch (exp) {
      setState('error');
      const error = exp as any;
      if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          error.response.data,
          'application/xml'
        );

        setError(
          doc
            .getElementsByTagName('Error')[0]
            .getElementsByTagName('Message')[0].textContent as string
        );
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
      } else {
        /**
         * Something happened in setting up the request and triggered an Error
         */
        console.log('Error', error.message);
      }
    }
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 h-[172px] mb-4">
      <div className="px-4 py-4 sm:p-3">
        <div className="m-1 sm:col-span-2">
          {state === 'success' ? (
            <div className="rounded-md bg-green-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-green-800">
                    Upload completed
                  </h3>
                  <div className="mt-2 text-sm text-green-700">
                    <p>File has been uploaded and will shortly be processed.</p>
                  </div>
                  <div className="mt-4">
                    <div className="-mx-2 -my-1.5 flex">
                      <button
                        type="button"
                        onClick={() => setState('idle')}
                        className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {state === 'error' ? (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    There was a problem with your upload
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <ul role="list" className="list-disc pl-5 space-y-1">
                      <li>{error}</li>
                    </ul>
                  </div>
                  <div className="mt-4">
                    <div className="-mx-2 -my-1.5 flex">
                      <button
                        type="button"
                        onClick={() => {
                          setState('idle');
                          setError('');
                        }}
                        className="bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-700 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-green-50 focus:ring-red-600"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {state === 'uploading' ? (
            <div className="w-full bg-gray-200 rounded-full h-2.5 mt-[70px]">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all ease-in-out duration-700"
                style={{width: `${progress}%`}}
              ></div>
            </div>
          ) : null}
          {state === 'idle' ? (
            <div
              {...getRootProps()}
              className={classNames(
                'w-full px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md transition cursor-pointer',
                isDragActive ? 'border-blue-500 bg-blue-50' : '',
                state === 'idle' ? 'visible' : 'invisible'
              )}
            >
              <input {...getInputProps()} />

              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600 justify-center">
                  <div className="relative cursor-pointe rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                    <span>Upload a file</span>
                  </div>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">
                  TXT, CSV, TSV up to 100MB
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
