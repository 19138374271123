import React from 'react';
import {useNavigate} from 'react-router-dom';

import {
  BanknotesIcon,
  ChartBarSquareIcon,
  LockClosedIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import {auth} from '../../lib';
import {ActionTable} from '../actions/components/ActionTable';

interface Actions {
  title: string;
  tagline: string;
  href: string;
  icon: React.FC<any>;
  iconForeground: string;
  iconBackground: string;
  disabled?: boolean;
}

const actions: Actions[] = [
  {
    title: 'Payments',
    tagline: 'Review upcoming payments.',
    href: '/payments',
    icon: BanknotesIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'View audience',
    tagline: "See your audience's latest interactions.",
    href: '/audience',
    icon: UsersIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
  },
  {
    title: 'Reporting',
    tagline: 'Download and review your data for reconciliation, reporting etc.',
    href: '/payments',
    icon: ChartBarSquareIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
  },
];

export function Home() {
  const {billerSlug: slug} = auth.useCurrentUser();
  const navigate = useNavigate();

  const billerActions = actions
    .filter(action => {
      switch (slug) {
        case 'city-of-exampleton':
          return true;
        default:
          return action.title !== 'Reporting';
      }
    })
    .map(action => {
      if (slug === 'agl-energy' && action.href === '/payments') {
        return {...action, disabled: true};
      }
      return action;
    });

  return (
    <div className="py-10">
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <h1 className="antialiased text-center text-3xl font-bold mb-1">
            Welcome back
          </h1>
          <div className="mb-8 antialiased text-center text-gray-500 text-sm">
            What would you like to do today?
          </div>

          <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
            {billerActions.map((action, actionIdx) => (
              <div
                key={action.title}
                className={classNames(
                  actionIdx === 0
                    ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
                    : '',
                  actionIdx === 1 ? 'sm:rounded-tr-lg sm:rounded-bl-none' : '',
                  actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                  actionIdx === actions.length - 1
                    ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none sm:rounded-br-none'
                    : '',
                  'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-500 cursor-pointer'
                )}
                onClick={() => {
                  !action.disabled && navigate(action.href);
                }}
              >
                <div
                  className={
                    action.disabled ? 'opacity-50 cursor-not-allowed' : ''
                  }
                >
                  <span
                    className={classNames(
                      action.iconBackground,
                      action.iconForeground,
                      'rounded-lg inline-flex p-3 ring-4 ring-white'
                    )}
                  >
                    <action.icon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <div
                  className={classNames(
                    'mt-8',
                    action.disabled ? 'opacity-50 cursor-not-allowed' : ''
                  )}
                >
                  <h3 className="text-lg font-medium">
                    <div className="focus:outline-none">
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      {action.title}
                      {action.disabled && (
                        <LockClosedIcon className="inline-block h-5 w-5 ml-2" />
                      )}
                    </div>
                  </h3>
                  <p className="text-sm text-gray-500">{action.tagline}</p>
                </div>
                <span
                  className={classNames(
                    'pointer-events-none absolute top-6 right-6 text-gray-300',
                    action.disabled
                      ? ''
                      : 'group-hover:text-gray-400 transition-all duration-100 ease-out group-hover:top-4 group-hover:right-4'
                  )}
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            ))}
          </div>
          <ActionTable />
        </div>
      </main>
    </div>
  );
}
