import * as React from 'react';
import {VariantProps, cn, cva} from '../lib/utils';
import {Icon, IconName} from './Icon';

const containerVariant = cva('rounded-md p-4', {
  variants: {
    variant: {
      error: 'bg-red-50',
      warning: 'bg-yellow-50',
      info: 'bg-blue-50',
    },
  },
});

const headerVariant = cva('mb-4 text-sm font-medium', {
  variants: {
    variant: {
      error: 'text-red-800',
      warning: 'text-yellow-800',
      info: 'text-blue-800',
    },
  },
});

const iconVariant = cva('h-5 w-5', {
  variants: {
    variant: {
      error: 'text-red-400',
      warning: 'text-yellow-400',
      info: 'text-blue-400',
    },
  },
});

const bodyVariant = cva('text-sm', {
  variants: {
    variant: {
      error: 'text-red-700',
      warning: 'text-yellow-700',
      info: 'text-blue-700',
    },
  },
});

const iconNames = {
  error: 'alert',
  warning: 'alert',
  info: 'info',
} as Record<string, IconName>;

export interface AlertProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof containerVariant> {
  title?: string;
}

function Alert({
  className,
  title,
  children,
  variant: _variant,
  ...props
}: AlertProps) {
  const variant = _variant ?? 'error';

  return (
    <div className={cn(containerVariant({variant}), className)} {...props}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon
            name={iconNames[variant]}
            variant="fill"
            className={iconVariant({variant})}
          />
        </div>
        <div className="ml-3">
          {title && <h3 className={headerVariant({variant})}>{title}</h3>}
          <div className={bodyVariant({variant})}>
            {typeof children === 'string' && <p>{children}</p>}
            {typeof children !== 'string' && children}
          </div>
        </div>
      </div>
    </div>
  );
}

export {Alert, containerVariant as alertVariants};
