import {z} from 'zod';
import {BillerAnalyticsResponse} from '../schemas/analytics';
import {defineHTTPEndpoint} from '../utils/endpointDefinition';

export const getAnalytics = defineHTTPEndpoint({
  authn: 'GLOBAL_ADMIN',
  operation: 'GET /analytics/events',
  requestSchema: z.object({
    contactId: z.string().uuid(),
    offset: z.optional(
      z
        .number()
        .int()
        .or(
          z
            .string()
            .transform(s => parseInt(s))
            .pipe(z.number())
        )
    ),
  }),
  responseSchema: BillerAnalyticsResponse,
  queryKey: e => ['analytics', 'events', e?.contactId, e?.offset ?? 0],
});
