import {Link, Outlet, useNavigate, useParams} from 'react-router-dom';
import {environmentFormConfigMap} from 'payble-shared';
import {useEffect} from 'react';
import {OnlyStaff} from 'components/OnlyStaff';
import {Select} from 'payble-ui';

export const EnvironmentsConfig = () => {
  const {type, configType = 'biller'} = useParams<{
    type: string;
    configType: string;
  }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!type) {
      const found = Object.entries(environmentFormConfigMap)
        .map(([key, config]) => ({
          key,
          config,
        }))
        .find(({config}) => {
          return config.configType === configType;
        });

      if (!found) {
        throw new Error('Config not found');
      }

      navigate(`/config/environments/${found.config.configType}/${found.key}`);
    }
  }, [type, configType, navigate]);

  return (
    <OnlyStaff>
      <div className="flex">
        <div className="flex flex-col w-1/3 gap-2 py-8">
          <Select
            value={configType}
            options={[
              {value: 'biller', label: 'Biller'},
              {value: 'global', label: 'Global'},
            ]}
            onChange={e => {
              navigate(`/config/environments/${e.target.value}`);
            }}
          />
          {Object.entries(environmentFormConfigMap)
            .filter(([__, config]) => config.configType === configType)
            .map(([key, config]) => (
              <Link
                key={key}
                to={`/config/environments/${config.configType}/${key}`}
                className={`p-2 px-4 rounded-lg ${
                  key === type
                    ? 'bg-white text-gray-900 font-bold'
                    : 'font-semibold'
                }`}
              >
                {config.title}
              </Link>
            ))}
        </div>
        <div className="flex-1 py-8 pl-8">
          <Outlet />
        </div>
      </div>
    </OnlyStaff>
  );
};
