import {DateTime} from 'luxon';
import {DateContext, toDateTime} from './time';
import {AbsoluteDate} from './AbsoluteDate';

export type Frequency =
  // Internal frequencies
  | 'weekly'
  | 'fortnightly'
  | 'monthly'
  | 'quarterly'
  | 'annually'
  | 'end_of_month';

type FrequencyValue = {
  // Luxon frequencies
  type: 'week' | 'month' | 'year' | 'quarter';
  value: number;
};

function frequencyToValue(frequency: Frequency): FrequencyValue {
  switch (frequency) {
    case 'weekly':
      return {type: 'week', value: 1};
    case 'fortnightly':
      return {type: 'week', value: 2};
    case 'monthly':
      return {type: 'month', value: 1};
    case 'quarterly':
      return {type: 'quarter', value: 1};
    case 'annually':
      return {type: 'year', value: 1};
    case 'end_of_month':
      return {type: 'month', value: 1};
    default: {
      throw new Error(`Unknown frequency: ${frequency}`);
    }
  }
}

interface FrequencyTargetArgs {
  /**
   * Used to calculate potential dates that land on the "frequency"
   */
  anchor: Date | DateTime;
  frequency: Frequency;
  /**
   * This can be any date
   */
  target: Date | DateTime;
}

export const getDateOnOrAfter = (
  args: FrequencyTargetArgs,
  context: DateContext
) => {
  const anchor = toDateTime(args.anchor, context).startOf('day');
  const target = toDateTime(args.target, context).startOf('day');

  const {type, value} = frequencyToValue(args.frequency);

  const diff = target.diff(anchor, type).as(type);

  const nextDate = anchor.plus({
    [type]: Math.ceil(diff / value) * value,
  });

  return nextDate;
};

export const getDateAfter = (
  args: FrequencyTargetArgs,
  context: DateContext
) => {
  const anchor = toDateTime(args.anchor, context).startOf('day');
  const target = toDateTime(args.target, context).startOf('day');

  const {type, value} = frequencyToValue(args.frequency);

  const diff = target.diff(anchor, type).as(type);

  const nextDate = anchor.plus({
    [type]: Math.floor(diff / value) * value + value,
  });

  return nextDate;
};

interface FrequencyAbsoluteDateArgs {
  /**
   * Used to calculate next absolute date given the frequency
   */
  anchor: AbsoluteDate;
  frequency: Frequency;
}

export const getNextAbsoluteDate = (
  args: FrequencyAbsoluteDateArgs
): AbsoluteDate => {
  const date = args.anchor;

  switch (args.frequency) {
    case 'weekly':
      return date.plus({weeks: 1});
    case 'fortnightly':
      return date.plus({weeks: 2});
    case 'monthly':
      return date.plus({months: 1});
    case 'quarterly':
      return date.plus({quarters: 1});
    case 'end_of_month':
      return date.plus({months: 1}).endOf('month');
    default:
      throw new Error(`Invalid frequency: ${args.frequency}`);
  }
};
