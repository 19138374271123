import React, {useState} from 'react';
import {Formik} from 'formik';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';

import {PaymentMethodType, useCreatePayerMutation} from 'lib/graphql/API';
import {useNavigate} from 'react-router-dom';
import {FormErrorMessage} from '../components/FormErrorMessage';
import {FormHeader} from './components/FormHeader';
import {FormSubmission} from './components/FormSubmission';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {DirectDebitForm} from './components/DirectDebitForm';
import {validateBankAccount} from './utils/validateBankAccount';
import {errs} from 'payble-shared';
import {SearchAccount} from 'components/organisms/SearchAccount';
import {DirectDebitFormNZ} from './components/DirectDebitFormNZ';
import {auth} from 'lib/index';

const FORM_INITIAL_VALUES = {
  accountId: '',
  accountName: '',
  bsbNumber: '',
  accountNumber: '',
  bank: {
    bsb: '',
    name: '',
    icon: '',
  },
};

type AddPayerFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
};

export const AddPayerForm: React.FC<AddPayerFormProps> = ({
  disclosure: {isOpen, onClose},
}) => {
  const [createPayer] = useCreatePayerMutation();
  const {billerConfig} = auth.useCurrentUser();
  const navigate = useNavigate();
  const [directDebitValid, setDirectDebitValid] = useState(false);

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={{
          ...FORM_INITIAL_VALUES,
          paymentMethodType:
            billerConfig.region === 'NZ'
              ? PaymentMethodType.NzDirectDebit
              : PaymentMethodType.DirectDebit,
        }}
        validate={values => {
          let errors = {};

          if (!values.accountId) {
            errors = {
              ...errors,
              accountId: 'Account is required.',
            };
          }

          errors = {
            ...errors,
          };

          return errors;
        }}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          setSubmitting(true);

          const result = await createPayer({
            variables: {
              input: {
                accountId: values.accountId,
                bank:
                  values.paymentMethodType === PaymentMethodType.DirectDebit
                    ? {
                        bsb: values.bsbNumber,
                        accountName: values.accountName,
                        accountNumber: values.accountNumber,
                      }
                    : undefined,
                nzBank:
                  values.paymentMethodType === PaymentMethodType.NzDirectDebit
                    ? {
                        accountName: values.accountName,
                        accountNumber: values.accountNumber,
                      }
                    : undefined,
                paymentMethodType: values.paymentMethodType,
              },
            },
          });

          if (result?.errors) {
            setSubmitting(false);

            setErrors({
              accountId:
                errs
                  .fromGraphQL({
                    graphQLErrors: result.errors,
                  })
                  .first().message ?? 'Something went wrong.',
            });

            return;
          }

          const payerId = result?.data?.createPayer.id;

          if (payerId) {
            navigate(`/audience/payer/${payerId}`);
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldError,
          isSubmitting,
        }) => (
          <form
            className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Add Payer"
                description="Add a payer to your audience."
              />

              <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <SearchAccount
                  onDataChanged={data => {
                    setFieldValue('accountId', data?.account?.id);
                  }}
                  onSearchError={error => {
                    setFieldError('accountId', error?.message);
                  }}
                  errorMessage={
                    errors.accountId && touched.accountId
                      ? errors.accountId
                      : undefined
                  }
                />
                {billerConfig.region === 'NZ' ? (
                  <DirectDebitFormNZ
                    value={{
                      accountNumber: values.accountNumber,
                      accountName: values.accountName,
                    }}
                    onChange={e => {
                      setFieldValue('accountNumber', e.accountNumber);
                      setFieldValue('accountName', e.accountName);
                    }}
                    onValid={setDirectDebitValid}
                    disabled={false}
                  />
                ) : (
                  <>
                    <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                      <div>
                        <label
                          htmlFor="account-name"
                          className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                        >
                          Account Name
                        </label>
                      </div>
                      <div className="sm:col-span-2">
                        <input
                          type="text"
                          name="accountName"
                          id="account-name"
                          placeholder="Account Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.accountName}
                          maxLength={32}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                        {errors.accountName && touched.accountName && (
                          <FormErrorMessage message={errors.accountName} />
                        )}
                      </div>
                    </div>
                    <DirectDebitForm
                      disabled={false}
                      onValid={setDirectDebitValid}
                      onChange={e => {
                        setFieldValue('bsbNumber', e.bsbNumber);
                        setFieldValue('bank', e.bank);
                        setFieldValue('accountNumber', e.accountNumber);
                      }}
                      value={{
                        accountNumber: values.accountNumber,
                        bsbNumber: values.bsbNumber,
                        bank: values.bank,
                      }}
                      errors={{
                        accountNumber: errors.accountNumber,
                        bsbNumber: errors.bsbNumber,
                        ...validateBankAccount({values}),
                      }}
                      touched={{
                        accountNumber: touched.accountNumber,
                        bsbNumber: touched.bsbNumber,
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <FormSubmission
              isSubmitting={isSubmitting}
              onCancel={onClose}
              submissionDisabled={
                Object.keys(errors).length > 0 || !directDebitValid
              }
              submissionButtonText="Add Payer"
            />
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
