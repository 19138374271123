import {ApolloClient, HttpLink, InMemoryCache, from} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import * as Sentry from '@sentry/react';

import {captureException} from '@sentry/react';
import {encounteredAuthError} from 'lib/auth/api';
import {apiURL} from 'lib/url';
import {errs} from 'payble-shared';
import {scalarTypePolicies} from './API';

const errorLink = onError(({graphQLErrors, networkError, operation}) => {
  if (graphQLErrors) {

    const errors = errs.fromGraphQL({graphQLErrors})

    const authError = errors.find(errs.AuthenticationError)
    if (authError) {
      encounteredAuthError(authError)
      return
    }
  }
  if (networkError) console.error(`[Network error]: ${networkError}`);

  // Add scoped report details and send to Sentry
  Sentry.withScope(scope => {
    // Annotate whether failing operation was query/mutation/subscription
    scope.setTag('operation', operation.operationName);
    scope.setExtra('variables', operation.variables);

    graphQLErrors?.forEach((err) => captureException(err))
    if (networkError) {
      captureException(networkError)
    }

    let errorMessage = '';
    if (graphQLErrors) {
      errorMessage = graphQLErrors
        .map(
          ({message, path}) =>
            `[GraphQL error]: Message: ${message}, Path: ${path}`
        )
        .join(', ');
    }

    if (networkError) {
      errorMessage = `[Network error]: ${networkError}`;
    }

    if (errorMessage) {
      console.error(errorMessage);
    }
  });


});

const authLink = setContext(async (_, {headers}) => {
  return {headers};
});


const httpLink = new HttpLink({uri: apiURL})

/** authClient is meant to bypass the normal retry rules for session management related queries */
export const authClient = new ApolloClient({
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all'
    },
    watchQuery: {
      errorPolicy: 'all'
    }
  },
  cache: new InMemoryCache({
    typePolicies: scalarTypePolicies
  }),
  link: from([httpLink])
})


export const client = new ApolloClient({
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all'
    },
    watchQuery: {
      errorPolicy: 'all'
    }
  },
  cache: new InMemoryCache({
    typePolicies: scalarTypePolicies
  }),
  link: from([
    errorLink,
    authLink,
    new RetryLink({
      attempts: {
        retryIf: (error, _operation) => {
          return error.statusCode >= 500;
        },
      },
    }),
    httpLink
  ]),
});
