import React from 'react';
import {RadioGroup} from '@headlessui/react';
import classNames from 'classnames';

export type Mode = {
  name: string;
  id: string;
  description: string;
};

export function SelectMode({
  selectedMode,
  modes,
  setMode,
}: {
  selectedMode?: Mode;
  modes: Mode[];
  setMode: (mode: Mode) => void;
}) {
  return (
    <RadioGroup value={selectedMode} onChange={setMode}>
      <RadioGroup.Label className="sr-only">Plan Type</RadioGroup.Label>
      <div className="-space-y-px rounded-md bg-white">
        {modes.map((setting, settingIdx) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting}
            className={({checked}) =>
              classNames(
                settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                settingIdx === modes.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked ? 'z-9 border-blue-200 bg-blue-50' : 'border-gray-200',
                'relative flex cursor-pointer border p-4 focus:outline-none'
              )
            }
          >
            {({active, checked}) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? 'bg-blue-600 border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-blue-600' : '',
                    'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <span className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-blue-900' : 'text-gray-900',
                      'block text-sm font-medium'
                    )}
                    id={`mode-${setting.id}`}
                  >
                    {setting.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? 'text-blue-700' : 'text-gray-500',
                      'block text-sm'
                    )}
                  >
                    {setting.description}
                  </RadioGroup.Description>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
