export const sortObjectByKey = <T extends Record<string, unknown>>(
  obj: T
): T => {
  const sortedKeys = Object.keys(obj).sort();
  const sortedObj: Record<string, unknown> = {};

  sortedKeys.forEach(key => {
    sortedObj[key] = obj[key];
  });

  return sortedObj as T;
};
