import React from 'react';

import {PaymentMethod, PaymentMethodStatus} from '../../../lib/graphql/API';
import {getImageAndAltText} from 'lib/logos';
import {formatLast4} from 'payble-shared';

type PaymentMethodCompactProps = {
  paymentMethod: PaymentMethod;
};

export const PaymentMethodCompact: React.FC<PaymentMethodCompactProps> = ({
  paymentMethod,
}) => {
  const isDisabled = paymentMethod.status === PaymentMethodStatus.Disabled;

  const {image, altText} = getImageAndAltText(paymentMethod);
  return (
    <div className="min-w-0 flex-1 text-sm text-gray-900">
      {paymentMethod.type === 'card' && paymentMethod.card && (
        <>
          <div className="flex">
            <img
              className={`h-14 w-14 flex-none object-contain ${
                isDisabled ? 'opacity-50' : ''
              }`}
              src={image}
              alt={altText}
            />
            <div className="flex-1 ml-4 text-right">
              <div>Card</div>
              <div className="font-mono">
                {formatLast4(paymentMethod.card.last4)}
              </div>
            </div>
          </div>
          <div className="text-red-500">
            {isDisabled && 'Disabled payment method.'}
          </div>
        </>
      )}

      {paymentMethod.type === 'directDebit' && paymentMethod.bank && (
        <>
          <div className="flex">
            <div
              className={`mb-2 mr-2 h-10 w-14 flex-none bg-contain bg-no-repeat bg-center ${
                isDisabled ? 'opacity-50' : ''
              }`}
              style={{backgroundImage: `url(${image})`}}
            ></div>
            <div className="text-right">
              <div>Bank Account</div>
              <div className="font-mono">
                {paymentMethod.bank.accountNumber}
              </div>
            </div>
          </div>
          <div className="text-red-500">
            {isDisabled && 'Disabled payment method. Please change.'}
          </div>
        </>
      )}

      {paymentMethod.type === 'nzDirectDebit' && paymentMethod.nzBank && (
        <>
          <div className="flex">
            <div
              className={`mb-2 mr-2 h-10 w-14 flex-none bg-contain bg-no-repeat bg-center ${
                isDisabled ? 'opacity-50' : ''
              }`}
              style={{backgroundImage: `url(${image})`}}
            ></div>
            <div className="text-right">
              <div>Bank Account</div>
              <div className="font-mono">
                {paymentMethod.nzBank.accountNumber}
              </div>
            </div>
          </div>
          <div className="text-red-500">
            {isDisabled && 'Disabled payment method. Please change.'}
          </div>
        </>
      )}
    </div>
  );
};
