/**
 * Base path for the current biller.
 * @returns {string | undefined}
 */
export const getBasePath = () => {
  const url = window.location.pathname;

  if (url.startsWith('/biller/')) {
    const [, path, slug] = url.split('/');
    return `/${path}/${slug}`;
  }

  return undefined;
};
