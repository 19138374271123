import {PaymentMethod} from 'lib/graphql/API';

export function formatPaymentMethod(paymentMethod: PaymentMethod) {
  const paymentMethodType = paymentMethod.type;
  if (paymentMethodType === 'card') {
    return `Card ${paymentMethod.card?.last4}`;
  }
  if (paymentMethodType === 'directDebit') {
    return `Direct Debit ${paymentMethod.bank?.accountName} ${paymentMethod.bank?.accountNumber}`;
  }

  if (paymentMethodType === 'nzDirectDebit') {
    return `Direct Debit ${paymentMethod.nzBank?.accountNumber} ${paymentMethod.nzBank?.accountName}`;
  }
  const __: never = paymentMethodType;
  throw new Error(`Unknown payment method type: ${paymentMethodType}`);
}
