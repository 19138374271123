import React from 'react';
import {Action} from 'lib/graphql/API';
import {DateTime} from 'luxon';
import {ChevronDoubleRightIcon} from '@heroicons/react/24/outline';
import {Link} from 'react-router-dom';

type ActionRowProps = {
  action: Action;
};

export const ActionRow: React.FC<ActionRowProps> = ({action}) => {
  const description =
    action.description?.split('\n').map((line, i) => (
      <p key={i} className="text-sm text-gray-500">
        {line}
      </p>
    )) ?? null;

  return (
    <>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap">
          <p className="font-medium text-gray-900 text-sm">{action.name}</p>
          <p className="text-xs text-gray-400">
            {DateTime.fromISO(action.timestamp).toFormat('dd MMM yyyy hh:mm a')}
          </p>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {description}
        </td>
        <td className="py-4 whitespace-nowrap justify-center">
          <Link
            to={action.linkTo}
            className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
          >
            <span className="sr-only">Next</span>
            <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
          </Link>
        </td>
      </tr>
    </>
  );
};
