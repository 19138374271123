import React from 'react';
import {DateTime} from 'luxon';
import {toTitleCase} from 'payble-shared';
import {AnalyticsPreview} from './AnalyticsPreview';
import {useDisclosure} from '../../../lib/hooks/useDisclosure';
import {BillerAnalyticsEvent} from 'payble-api-client/schemas';

type AnalyticRowProps = {
  event: BillerAnalyticsEvent;
};

export const AnalyticsRow: React.FC<AnalyticRowProps> = ({event}) => {
  const preview = useDisclosure();

  return (
    <>
      <AnalyticsPreview
        isOpen={preview.isOpen}
        onClose={() => preview.onClose()}
        event={event}
      />
      <tr className="hover:bg-gray-100 cursor-pointer" onClick={preview.onOpen}>
        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
          <div className="font-semibold">
            {toTitleCase(event.type.replace(/_/g, ' '))}
          </div>
          <div className="text-xs text-gray-500">{event.ip}</div>
        </td>
        <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
          <div className="font-semibold">{event.path}</div>
        </td>
        <td className="justify-center py-4 whitespace-nowrap">
          <div className="px-6 whitespace-nowrap">
            <div className="text-sm">
              {event.isMobile ? 'Mobile' : 'Desktop/Other'}
            </div>
            <div className="text-sm">{event.deviceId.slice(0, 8) + '...'}</div>
          </div>
        </td>
        <td className="justify-center py-4 whitespace-nowrap">
          <div className="px-6 whitespace-nowrap">
            <div className="mt-2 text-xs font-semibold text-gray-500">
              Timestamp
            </div>
            <div className="text-sm">
              {DateTime.fromJSDate(event.timestamp).toFormat(
                'dd MMM yyyy hh:mm a'
              )}
            </div>
            <div className="mt-2 text-xs font-semibold text-gray-500">
              Session ID
            </div>
            <div className="text-sm">{event.sessionId.slice(0, 8) + '...'}</div>
            {event.utm && (
              <>
                <div className="mt-2 text-xs font-semibold text-gray-500">
                  Campaign
                </div>
                <div className="text-sm">{event.utm}</div>
              </>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};
