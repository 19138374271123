import React from 'react';
import {Notification, NotificationChannel} from '../../../lib/graphql/API';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from 'features/audience/forms/components/FormHeader';
import {SmsPreview} from 'features/audience/forms/components/SmsPreview';
import {DateTime} from 'luxon';
import {EmailPreview} from 'features/audience/forms/components/EmailPreview';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  notification: Notification;
}

export const NotificationPreview: React.FC<Props> = ({
  isOpen,
  onClose,
  notification,
}) => {
  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
        <div className="flex-1">
          <FormHeader
            setOpen={onClose}
            title="Notification sent"
            description={`Sent at ${DateTime.fromISO(
              notification.sentAt ?? ''
            ).toFormat('dd MMM yyyy hh:mm a')}`}
          />

          {notification.channel === NotificationChannel.Sms && (
            <SmsPreview
              messages={
                notification.preview
                  ? [
                      {
                        key: notification.id,
                        text: [notification.preview],
                        sent: false,
                      },
                    ]
                  : []
              }
            />
          )}

          {notification.channel === NotificationChannel.Email && (
            <EmailPreview notification={notification} />
          )}
        </div>
      </div>
    </PopOverSidebar>
  );
};
