import {create} from 'zustand';
import * as authAPI from './api';
import {BillerConfig, getBillerConfigById} from 'payble-shared';

interface AuthState {
  hasInitialized: boolean;
  initializing: boolean;

  currentUser?: {
    email: string;
    adminId: string;
    billerId: string;
    billerName: string;
    billerSlug: string;
    billerConfig: BillerConfig;
  };

  loginFlow?: {
    error?: string;
    state:
      | {
          type: 'STARTED';
          email: string;
          method: 'PASSWORD';
        }
      | {type: 'LOADING'};
  };
}

const getInitialState = (): AuthState => ({
  hasInitialized: false,
  initializing: false,
});

export const useAuthState = create<AuthState>(getInitialState);

export const useCurrentUser = () => {
  const user = useAuthState(s => s.currentUser);
  if (!user) {
    throw new Error('No current user');
  }

  return user;
};

export const reset = (state?: Partial<AuthState>) =>
  useAuthState.setState(
    () => ({
      initializing: false,
      hasInitialized: false,
      ...state,
    }),
    true
  );

export const setLoginFlow = (loginFlow: AuthState['loginFlow']) =>
  useAuthState.setState(() => ({
    loginFlow,
  }));

export const useLoginFlow = (api = authAPI) => {
  const state = useAuthState();

  if (state.loginFlow?.state.type === 'LOADING') {
    return {
      type: 'LOADING' as const,
    };
  }

  if (!state.hasInitialized) {
    return {
      type: 'UNINITIALIZED' as const,
      initialize: async () => {
        if (state.initializing) return;
        useAuthState.setState({initializing: true});

        await api.loadSession().tapAsync(
          async session => {
            useAuthState.setState({
              currentUser: {
                ...session,
                billerConfig: getBillerConfigById(session.billerId),
              },
              hasInitialized: true,
              initializing: false,
              loginFlow: undefined,
            });
          },
          async () => {
            useAuthState.setState({
              hasInitialized: true,
              initializing: false,
            });
            await api.clear();
          }
        );
      },
    };
  }

  if (state.currentUser) {
    return {
      type: 'LOGGED_IN' as const,
      currentUser: state.currentUser,
      logout: async () => {
        const done = () => reset({hasInitialized: true, initializing: false});

        await api.logout().withTimeout(2_000).tapEither(done);
      },
    };
  }

  if (!state.loginFlow) {
    return {
      type: 'LOGGED_OUT' as const,
      lookup: async (email: string) => {
        setLoginFlow({
          state: {type: 'LOADING'},
        });

        await api.lookup(email).tap(
          response => {
            if (response.method === 'SAML') {
              window.location.href = `/api/saml/${
                response.identityProviderId
              }/redirectTo/${btoa(window.location.pathname)}`;
              return;
            }

            setLoginFlow({
              state: {
                email,
                method: response.method,
                type: 'STARTED',
              },
            });
          },
          error => {
            console.error(error);
            setLoginFlow(undefined);
          }
        );
      },
    };
  }

  if (state.loginFlow.state.type === 'STARTED') {
    const email = state.loginFlow.state.email;
    return {
      ...state.loginFlow.state,
      back: () => {
        setLoginFlow(undefined);
      },
      login: async (password: string) => {
        setLoginFlow({state: {type: 'LOADING'}});

        await api
          .login({
            password,
            username: email,
          })
          .withTimeout(15_000)
          .tap(
            // Resetting will load the token from local storage and follow the
            // normal auth flow.
            () => reset(),
            err => {
              console.error(err);
              setLoginFlow({
                error: err instanceof Error ? err.message : 'Could not log in',
                state: {
                  email,
                  method: 'PASSWORD',
                  type: 'STARTED',
                },
              });
            }
          );
      },
    };
  }

  const __: never = state.loginFlow.state;
  throw new Error(`Invalid login flow state: ${JSON.stringify(__)}`);
};
