import {BellAlertIcon} from '@heroicons/react/24/outline';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import {AnalyticsTable} from 'features/analytics/components/AnalyticsTable';
import React, {useState} from 'react';
import {useAPIQuery} from '../../../lib/api';

type AnalyticsProps = {
  contactId: string;
};

export const Analytics: React.FC<AnalyticsProps> = ({contactId}) => {
  const [page, setPage] = useState<number>(1);

  const analytics = useAPIQuery('getAnalytics', {
    data: {
      contactId,
      offset: (page - 1) * 10,
    },
  });

  const goToPage = (pageNum: number) => {
    setPage(pageNum);
  };

  if (!analytics.data) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (analytics.error) {
    return (
      <div>
        <ErrorMessage message={analytics.error.message} />
      </div>
    );
  }

  if (!analytics.data.events.length) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <BellAlertIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No analytics
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            All analytics sent to this contact will be displayed here.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-12">
      <AnalyticsTable
        events={analytics.data.events}
        isLoading={analytics.isLoading}
        error={analytics.error ?? undefined}
        goToPage={goToPage}
        page={page}
        total={analytics.data.total}
      />
    </div>
  );
};
