import {z} from 'zod';
import {defineHTTPEndpoint} from '../utils';
import {notificationSettingsSchema} from '../schemas/notification';

export const updateNotificationSettings = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'PUT /updateNotificationSettings',
  requestSchema: z.object({
    contactId: z.string(),
    groups: notificationSettingsSchema,
  }),
  responseSchema: null,
});

export const getNotificationSettings = defineHTTPEndpoint({
  authn: 'BILLER',
  operation: 'GET /getNotificationSettings',
  queryKey: () => ['notification-settings'],
  requestSchema: z.object({
    contactId: z.string(),
  }),
  responseSchema: notificationSettingsSchema,
});
