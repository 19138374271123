import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {
  Account,
  useArchivePayerMutation,
  useGetPayerLazyQuery,
} from 'lib/graphql/API';
import React, {Fragment, useEffect} from 'react';
import {
  matchPath,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {getBillerConfig, getFormattedAccountMeta} from 'payble-shared';
import {Loading} from 'components/atoms/Loading';

import {Breadcrumbs} from 'components/atoms/Breadcrumbs';
import {Tabs} from 'components/atoms/Tabs';
import {ContactAccounts} from './ContactAccounts';
import {ContactPaymentMethods} from './ContactPaymentMethods';
import {Payments} from './Payments';

import slugify from 'slugify';
import classNames from 'classnames';
import {Menu, Transition} from '@headlessui/react';
import {
  ArchiveBoxIcon,
  EllipsisHorizontalIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {AddInstalmentPlanForm} from '../forms/AddInstalmentPlanForm';
import {auth} from 'lib/index';
import {AddAutoPayForm} from '../forms/AddAutoPayForm';
import {CancelModal} from '../components/CancelModal';
import {TooltipMessage} from 'components/organisms/TooltipMessage';
import {CreatePlansMenuOptions} from '../components/CreatePlansMenuOptions';
import {AddFlexiblePlanForm} from '../forms/AddFlexiblePlanForm';

export const Payer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showCancelModal, setShowCancelModal] = React.useState(false);

  const {lookup: payerId} = useParams<{lookup: string}>();

  const {billerSlug: slug} = auth.useCurrentUser();
  const billerConfig = getBillerConfig(slug);

  if (!payerId) {
    return <ErrorMessage message="No lookup provided" />;
  }

  const addInstalmentPlanFormDisclosure = useDisclosure();
  const enableAutoPayDisclosure = useDisclosure();
  const enableFlexibleDisclosure = useDisclosure();

  const [getPayerData, {data, loading, error}] = useGetPayerLazyQuery({
    variables: {
      id: payerId,
    },
  });

  const [archivePayer] = useArchivePayerMutation();

  const onCancelConfirm = async (reason: string) => {
    setShowCancelModal(false);

    await archivePayer({
      variables: {input: {payerId: payerId, deletedReason: reason}},
      refetchQueries: ['getPayer'],
    });
  };

  useEffect(() => {
    getPayerData();
  }, []);

  const disabled = data === null || data?.payer?.deletedAt !== null;

  return (
    <>
      <CancelModal
        headline="Archive Payer"
        description="Are you sure you want to archive this payer? This action cannot be undone."
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onReject={onCancelConfirm}
        reasons={[
          'Migrating to a new plan',
          'Customer requested',
          'Last or non-payment',
          'Ownership change',
          'Other',
        ]}
      />
      {loading ? <Loading /> : null}
      {error ? <ErrorMessage message={error.message} /> : null}
      {!loading && !error && data && data.payer ? (
        <div>
          <div className="mt-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <Breadcrumbs
                pages={[
                  {name: 'Audience', href: '/audience'},
                  {
                    name: 'Payer',
                    href: `/audience/payer/${data.payer.id}`,
                    current: true,
                  },
                ]}
              />

              <div className="bg-white shadow sm:rounded-lg mt-8">
                <div className="flex justify-between">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Payer Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Details and payer information.
                    </p>
                  </div>
                  {!disabled && (
                    <Menu
                      as="div"
                      className="relative top-5 right-6 inline-block text-right"
                    >
                      <div className="">
                        <Menu.Button
                          className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
                          id="payer-menu"
                        >
                          <span className="sr-only">Open options</span>
                          <EllipsisHorizontalIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              {({active}) => (
                                <button
                                  onClick={() => setShowCancelModal(true)}
                                  className={classNames(
                                    active
                                      ? 'bg-gray-100 text-gray-900'
                                      : 'text-gray-500',
                                    'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
                                  )}
                                >
                                  Archive Payer
                                  <ArchiveBoxIcon
                                    aria-hidden="true"
                                    className="h-5 w-5"
                                  />
                                </button>
                              )}
                            </Menu.Item>
                            <CreatePlansMenuOptions
                              billerConfig={billerConfig}
                              onOpenInstalmentPlanForm={() =>
                                addInstalmentPlanFormDisclosure.onOpen()
                              }
                              onOpenEnableAutoPay={() =>
                                enableAutoPayDisclosure.onOpen()
                              }
                              onOpenFlexiblePaymentPlan={() => {
                                enableFlexibleDisclosure.onOpen();
                              }}
                            />
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                  <AddInstalmentPlanForm
                    payerId={data.payer.id}
                    accountId={data.payer.account.id}
                    amountOwing={data.payer.account.amountOwing}
                    disclosure={addInstalmentPlanFormDisclosure}
                    paymentMethods={data.payer.paymentMethods}
                  />
                  <AddAutoPayForm
                    payerId={data.payer.id}
                    account={data.payer.account}
                    paymentMethods={data.payer.paymentMethods}
                    disclosure={enableAutoPayDisclosure}
                  />
                  <AddFlexiblePlanForm
                    disclosure={enableFlexibleDisclosure}
                    paymentMethods={data.payer.paymentMethods}
                    payerId={data.payer.id}
                    account={data.payer.account}
                  />
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        External id
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {data.payer.account.externalId}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Status
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {disabled ? (
                          <>
                            <>
                              {data.payer.deletedReason ? (
                                <TooltipMessage
                                  tooltipText={data.payer.deletedReason}
                                  tooltipBgColorAndHue="gray-50"
                                  tooltipTextColorAndHue="gray-600"
                                  showTooltip={true}
                                >
                                  <span className="inline-flex">
                                    Archived
                                    <QuestionMarkCircleIcon className="h-4 w-4 ml-2 text-gray-600 relative top-0.5" />
                                  </span>
                                </TooltipMessage>
                              ) : (
                                'Archived'
                              )}
                            </>
                          </>
                        ) : (
                          'Active'
                        )}
                      </dd>
                    </div>

                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Description
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {data.payer.account.description}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <Tabs
                tabs={[
                  {
                    name: 'Metadata',
                    id: 'tab-metadata',
                    onClick: () => {
                      navigate(`/audience/payer/${payerId}`, {
                        state: {lookup: payerId},
                      });
                    },
                    current:
                      matchPath(
                        '/audience/payer/:lookup',
                        location.pathname
                      ) !== null,
                  },
                  {
                    name: 'Payments',
                    id: 'tab-payments',
                    onClick: () => {
                      navigate(`/audience/payer/${payerId}/payments`, {
                        state: {lookup: payerId},
                      });
                    },
                    current:
                      matchPath(
                        '/audience/payer/:lookup/payments',
                        location.pathname
                      ) !== null,
                  },
                  {
                    name: 'Payment Method',
                    id: 'tab-payment-method',
                    onClick: () => {
                      navigate(`/audience/payer/${payerId}/payment-method`, {
                        state: {lookup: payerId},
                      });
                    },
                    current:
                      matchPath(
                        '/audience/payer/:lookup/payment-method',
                        location.pathname
                      ) !== null,
                  },
                  {
                    name: 'Account',
                    id: 'tab-account',
                    onClick: () => {
                      navigate(`/audience/payer/${payerId}/account`, {
                        state: {lookup: payerId},
                      });
                    },
                    current:
                      matchPath(
                        '/audience/payer/:lookup/account',
                        location.pathname
                      ) !== null,
                  },
                ]}
              />
            </div>
          </div>

          <div className="mt-8">
            <Routes>
              <Route
                path=""
                element={
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-8">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-8">
                      <div className="px-4 py-5 sm:px-6 relative">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Meta Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          Additional data which has been attached to the
                          account, normally as part of an integration. Payble
                          uses this data in communication templates and can
                          trigger custom business rules when it changes.
                        </p>
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                          {data?.payer?.meta &&
                            getFormattedAccountMeta(data.payer.meta).map(
                              ({title, value}) => (
                                <div
                                  className="sm:col-span-1"
                                  key={slugify(title)}
                                >
                                  <dt className="text-sm font-medium text-gray-500">
                                    {title}
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {value}
                                  </dd>
                                </div>
                              )
                            )}
                        </dl>
                      </div>
                    </div>
                  </div>
                }
              />
              <Route
                path="account"
                element={
                  <ContactAccounts
                    accounts={[data.payer.account] as Account[]}
                  />
                }
              />
              <Route
                path="payment-method"
                element={
                  <ContactPaymentMethods
                    paymentMethods={data.payer.paymentMethods}
                  />
                }
              />
              <Route
                path="payments"
                element={<Payments accountId={data.payer.account.id} />}
              />
            </Routes>
          </div>
        </div>
      ) : null}
    </>
  );
};
