import {z} from 'zod';
import {zISODate} from './generic';

export const autoImportConfigResponseSchema = z.object({
  autoImportConfigId: z.string(),
  billerId: z.string(),
  processType: z.string(),
  filenameMatcher: z.string(),
  strategy: z.string(),
  defaults: z.object({}).catchall(z.any()).optional(),
  createdAt: zISODate,
  updatedAt: zISODate,
});
