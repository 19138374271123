import React, {useState} from 'react';
import {XCircleIcon} from '@heroicons/react/20/solid';

import {useGetActionsBySearchQuery} from 'lib/graphql/API';
import {CheckCircleIcon} from '@heroicons/react/24/outline';
import {ActionTableSkeleton} from './ActionTableSkeleton';
import {ActionRow} from './ActionRow';
import {Paginator} from 'components/atoms/Paginator';

export const ActionTable = () => {
  const [page, setPage] = useState<number>(1);

  const query = useGetActionsBySearchQuery({
    variables: {
      offset: (page - 1) * 10,
    },
  });

  return (
    <div className="px-4 py-8 sm:px-0">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-4">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-navy uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-navy uppercase tracking-wider"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-bold text-navy uppercase tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {query.loading && <ActionTableSkeleton rows={4} />}
                  {!query.loading &&
                    !query.error &&
                    query.data &&
                    query.data.actions.edges.length > 0 &&
                    query.data.actions.edges.map(({node: action}) => (
                      <ActionRow key={`${action?.id}`} action={action!} />
                    ))}
                  {!query.loading &&
                    !query.error &&
                    query.data?.actions.edges?.length === 0 && (
                      <tr>
                        <td colSpan={5}>
                          <div className="text-center py-8">
                            <CheckCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
                            <h3 className="mt-2 text-sm font-medium text-gray-900">
                              Nothing requires your input
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              Check back later for more actions
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  {!query.loading && query.error && (
                    <tr>
                      <td colSpan={5}>
                        <div className="rounded-md bg-red-50 p-4 m-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <XCircleIcon
                                className="h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-red-800">
                                There was an error loading the data
                              </h3>
                              <div className="mt-2 text-sm text-red-700">
                                <ul
                                  role="list"
                                  className="list-disc pl-5 space-y-1"
                                >
                                  <li>{query.error.message}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Paginator
                loading={query.loading}
                rows={query.data?.actions.edges?.length || 0}
                goToPage={() => setPage(page + 1)}
                page={page}
                total={query.data?.actions.total}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
