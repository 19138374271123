import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/20/solid';
import React, {useEffect, useState} from 'react';
import {PageNumber} from './PageNumber';
import {Spinner} from './Spinner';

type PaginatorProps = {
  loading: boolean;
  rows: number;
  goToPage?: (p: number) => void;
  page?: number;
  pageSize?: number;
  total?: number;
};

const calculateLastPage = (total: number, pageSize: number) => {
  return Math.ceil(total / pageSize);
};

export const Paginator: React.FC<PaginatorProps> = ({
  loading,
  rows,
  goToPage,
  page,
  pageSize = 10,
  total,
}) => {
  if (!page || total === undefined) {
    return <></>;
  }

  const [lastPage, setLastPage] = useState(calculateLastPage(total, pageSize));
  const [displayPageNumbers, setDisplayPageNumbers] = useState<number[]>([]);

  // We display a max of 11 page numbers at a time if available
  // Whenever we click on a particular page number, it shows the page number in the center and 5 page numbers on each side if available

  // The arrows on both sides of page numbers are used to scroll through the available page numbers, they wouldn't change the page itself until we click on the page number
  // Previous and next shift the page numbers by 5 if available and if not they shift to show whatever pages are available in that direction
  const next = () => {
    setDisplayPageNumbers(prevDisplayPageNumbers => {
      const isLastSetOfPages = prevDisplayPageNumbers.find(
        p => p + 5 > lastPage
      );
      if (isLastSetOfPages) {
        return [
          lastPage - 10,
          lastPage - 9,
          lastPage - 8,
          lastPage - 7,
          lastPage - 6,
          lastPage - 5,
          lastPage - 4,
          lastPage - 3,
          lastPage - 2,
          lastPage - 1,
          lastPage,
        ];
      } else {
        return prevDisplayPageNumbers.map(p => p + 5);
      }
    });
  };
  const previous = () => {
    setDisplayPageNumbers(prevDisplayPageNumbers => {
      const isFirstSetOfPages = prevDisplayPageNumbers.find(p => p - 5 < 1);
      if (isFirstSetOfPages) {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      } else {
        return prevDisplayPageNumbers.map(p => p - 5);
      }
    });
  };

  useEffect(() => {
    setLastPage(__ => calculateLastPage(total, pageSize));
  }, [total]);

  useEffect(() => {
    let arr: number[] = [];
    if (lastPage < 11) {
      // If we have less than 11 pages we just show all the page numbers with both the arrows disabled
      for (let i = 0; i < lastPage; i++) {
        arr.push(i + 1);
      }
    } else if (page < 6) {
      // If we have more than 11 pages
      // but the current page is less than 6, it means that we're in the first set of 11 pages, hence we display them
      arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    } else if (page > lastPage - 5) {
      // If we have more than 11 pages
      // but the current page is greater than lastpage-5, it means that we're in the last set of 11 pages, hence we display them
      arr = [
        lastPage - 10,
        lastPage - 9,
        lastPage - 8,
        lastPage - 7,
        lastPage - 6,
        lastPage - 5,
        lastPage - 4,
        lastPage - 3,
        lastPage - 2,
        lastPage - 1,
        lastPage,
      ];
    } else {
      // For all other case
      // we show the current page number in the center and 5 page numbers on each side

      arr = [
        page - 5,
        page - 4,
        page - 3,
        page - 2,
        page - 1,
        page,
        page + 1,
        page + 2,
        page + 3,
        page + 4,
        page + 5,
      ];
    }
    setDisplayPageNumbers(__ => arr);
  }, [page, lastPage]);

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          href="#"
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {loading ? (
            <Spinner />
          ) : (
            <>
              {total === 0 ? (
                <p className="text-sm text-gray-700">No Results</p>
              ) : (
                <p className="text-sm text-gray-700">
                  Showing{' '}
                  <span className="font-medium">
                    {' '}
                    {(page - 1) * pageSize + 1}
                  </span>{' '}
                  to{' '}
                  <span className="font-medium">
                    {(page - 1) * pageSize + rows}
                  </span>{' '}
                  of <span className="font-medium">{total}</span> results
                </p>
              )}
            </>
          )}
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading || !previous || displayPageNumbers[0] === 1}
              onClick={previous}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {goToPage &&
              displayPageNumbers.map(pageNumber => (
                <PageNumber
                  key={pageNumber}
                  page={pageNumber}
                  isCurrent={page === pageNumber}
                  goToPage={goToPage}
                />
              ))}
            <button
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={
                loading ||
                !next ||
                displayPageNumbers[displayPageNumbers.length - 1] === lastPage
              }
              onClick={next}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};
