import {useCallback, useEffect, useRef, useState} from 'react';

export function useArrayPagination<T>(
  data: T[],
  itemsPerPage: number
): {
  page: T[];
  pageIndex: number;
  paginate: (index: number) => void;
} {
  const dataRef = useRef<T[]>(data);
  const [page, setPage] = useState<T[]>(data.slice(0, itemsPerPage));
  const [pageIndex, setPageIndex] = useState<number>(0);

  useEffect(() => {
    if (data.length) {
      dataRef.current = data;
      setPage(data.slice(0, itemsPerPage));
      setPageIndex(pageIndex);
    }
  }, [data, itemsPerPage]);

  const paginate = useCallback(
    (index: number) => {
      setPage(
        dataRef.current.slice(
          index * itemsPerPage,
          index * itemsPerPage + itemsPerPage
        )
      );
      setPageIndex(index);
    },
    [itemsPerPage]
  );

  return {
    page,
    pageIndex,
    paginate,
  };
}
