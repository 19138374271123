import React from 'react';
import NumberFormat from 'react-number-format';
import {formatToDollars} from 'payble-shared';
import {InstalmentPreview} from 'features/audience/helpers/generatePreviewInstlaments';

type ViewInstalmentProps = Omit<InstalmentPreview, '__id'> & {
  edit: () => void;
  remove: () => void;
};
export const ViewInstalment: React.FC<ViewInstalmentProps> = ({
  edit,
  remove,
  dueAt,
  amount,
}) => {
  return (
    <div className="divide-y divide-gray-500 py-2 px-5 transition-opacity">
      <div key={dueAt.toISO()} className="flex text-xs sm:text-sm items-center">
        <div className="basis-4/12 px-2">{dueAt.toFormat('dd MMM yyyy')}</div>
        <div className="basis-2/12 text-left px-2">
          <NumberFormat
            value={formatToDollars(amount)}
            displayType={'text'}
            thousandSeparator={true}
            decimalSeparator={'.'}
            fixedDecimalScale={true}
            decimalScale={2}
            prefix={'$'}
          />
        </div>
        <div className="basis-4/12 justify-center text-right px-2 pl-6">
          <button
            type="button"
            onClick={() => edit()}
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Edit
          </button>
        </div>
        <div className="basis-2/12 px-2 text-right">
          <button
            type="button"
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => remove()}
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};
