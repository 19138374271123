import React from 'react';
import {useAuthState} from '../lib/auth';

export const OnlyStaff: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({children}) => {
  const {currentUser} = useAuthState();

  if (currentUser?.email?.endsWith('payble.com.au')) {
    return <>{children}</>;
  }
  return null;
};
