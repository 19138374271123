import {generateInstalmentsBasedOnTotalAmount} from './generateInstalmentsBasedOnTotalAmount';
import {generateInstalmentsBasedOnInstalmentAmount} from './generateInstalmentsBasedOnInstalmentAmount';
import {AbsoluteDate, DateContext, DateFrequency} from 'payble-shared';

export function getId() {
  return (
    (window.crypto && window.crypto?.randomUUID()) ??
    Math.floor(Math.random() * 9e9 + 1)
  );
}

export type InstalmentPreview = {
  dueAt: AbsoluteDate;
  amount: number;
  __id: string;
};

type GeneratePreviewInstalments = {
  frequency: DateFrequency;
  startDate: AbsoluteDate;
  endDate?: AbsoluteDate;
  instalmentAmount: number;
  mode: string;
  amountOwing: number;
};
export function generatePreviewInstalments(
  {
    startDate,
    endDate,
    frequency,
    instalmentAmount,
    mode,
    amountOwing,
  }: GeneratePreviewInstalments,
  context: DateContext
): InstalmentPreview[] {
  if (mode === 'totalAmount') {
    return generateInstalmentsBasedOnTotalAmount(
      {
        amountOwing,
        instalmentAmount,
        frequency,
        startDate,
      },
      context
    );
  }

  if (mode === 'instalmentAmount') {
    if (!endDate) {
      throw new Error('endDate is required for instalmentAmount mode');
    }

    return generateInstalmentsBasedOnInstalmentAmount(
      {
        frequency,
        startDate,
        endDate,
        instalmentAmount,
      },
      context
    );
  }

  throw new Error(
    `Invalid mode: ${mode} failed to generate PreviewInstalments`
  );
}
