import React, {useState} from 'react';
import {useGetAuditLogsQuery, AuditLogType} from 'lib/graphql/API';
import {AuditLogTable} from '../components/AuditLogTable';
import {useSearch} from '../../../lib/hooks/useSearch';
import {DateTime} from 'luxon';
import {MagnifyingGlassIcon, XMarkIcon} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import {Select} from 'components/atoms/Select';

export const AuditLogs: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const search = useSearch();

  const [admin, setAdmin] = useState<
    | {
        adminId: string;
        adminName: string;
      }
    | undefined
  >();

  const [type, setType] = useState<'WRITES' | 'READS' | 'ALL'>('WRITES');

  const [dateRange] = useState({
    end: DateTime.now().toJSDate(),
    start: DateTime.now().minus({days: 30}).toJSDate(),
  });

  const {data, loading, error} = useGetAuditLogsQuery({
    variables: {
      input: {
        offset: (page - 1) * 10,
        search: search.value ? search.value : undefined,
        dateRange,
        adminId: admin?.adminId,
        type:
          type === 'WRITES'
            ? AuditLogType.Mutation
            : type === 'READS'
              ? AuditLogType.Query
              : undefined,
      },
    },
  });

  return (
    <>
      <div>
        <div className="mt-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative border-b border-gray-200 sm:pb-0">
              <div className="md:flex md:items-center md:justify-between">
                <h3 className="text-3xl leading-6 font-bold text-gray-900">
                  Audit Logs
                  <br />
                  {/** The 30 day limit is arbitrary, I just didn't know how to
                       implement a date range picker */}
                  <p className="flex mt-2 mb-2 text-sm text-gray-700 font-normal max-w-sm">
                    A list of all actions taken by admins within the
                    application. Logs are searchable here for 30 days.
                  </p>
                </h3>
              </div>
              <div className="mt-4">
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8 flex-row mb-4">
                    <div>
                      <Select
                        wrapperClassNames="w-40"
                        onChange={async value => setType(value as typeof type)}
                        values={[
                          {
                            id: 'WRITES',
                            name: 'Changes',
                          },
                          {
                            id: 'READS',
                            name: 'Views',
                          },
                          {
                            id: 'ALL',
                            name: 'All',
                          },
                        ]}
                      />
                    </div>

                    {admin && (
                      <span
                        className={classNames(
                          'border-gray-300 bg-gray-100 text-gray-900 whitespace-nowrap py-1 px-3 border-2 font-semibold text-sm  transition-all rounded-lg flex items-center space-x-2'
                        )}
                      >
                        <div
                          className="cursor-pointer"
                          onClick={() => setAdmin(undefined)}
                        >
                          <XMarkIcon className="h-5 w-5 text-gray-800 opacity-50" />
                        </div>
                        <span>{admin.adminName}</span>
                      </span>
                    )}

                    <div className="flex flex-grow justify-end" />

                    {/** send to right */}
                    <div className="relative rounded-md flex">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MagnifyingGlassIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        type="text"
                        name="search"
                        id="search"
                        className="focus:ring-blue-500 focus:border-blue-500 block pl-10 sm:text-sm border-gray-300 rounded-md w-96"
                        placeholder="Search action title"
                        autoFocus={true}
                        onChange={e => {
                          search.onChange(e.target.value);
                        }}
                      />
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <AuditLogTable
              loading={loading || search.isTyping}
              error={error}
              auditLogs={data?.auditLogs?.edges ?? []}
              total={data?.auditLogs.total}
              goToPage={setPage}
              onClickAdmin={setAdmin}
              page={page}
            />
          </div>
        </div>
      </div>
    </>
  );
};
