let appURL: string;
if (window.location.hostname === 'localhost') {
  appURL = 'https://' + window.location.hostname + ':' + window.location.port;
} else {
  appURL = 'https://' + window.location.hostname;
}

export const apiURL = appURL + '/api';
export const consumerURL =
  window.location.hostname === 'localhost'
    ? 'https://localhost:3000'
    : appURL.replace('billers', 'app');
