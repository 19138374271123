import React from 'react';

import {Switch} from '@headlessui/react';
import {classNames} from 'lib/styles';

export const PlanRules: React.FC<{
  missed: number;
  canSkip: boolean;
  setFieldValue: (field: string, value: number | boolean) => void;
}> = ({missed, setFieldValue, canSkip}) => {
  return (
    <>
      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="missed"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Missed instalment
            </label>
          </div>
          <div className="sm:col-span-2">
            <input
              className="w-full"
              type="range"
              id="missed"
              name="missed"
              min="0"
              max="5"
              defaultValue={missed}
              step="1"
              onChange={v => setFieldValue('missed', Number(v.target.value))}
            />
            <p className="w-full text-right font-small">
              Allows {missed} missed instalment(s) in a row
            </p>
          </div>
        </div>
      </div>

      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="missed"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Allow skipped instalment
            </label>
          </div>
          <div className="sm:col-span-2 flex items-center">
            <Switch
              checked={canSkip}
              onChange={e => setFieldValue('canSkip', e)}
              className={classNames(
                canSkip ? 'bg-blue-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  canSkip ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};
