type NavigationItem = {
  priority: number;
  name: string;
  href: string;
  isExternal?: boolean;
  disabled?: boolean;
};

export const getNavbarItems = (billerSlug: string): NavigationItem[] => {
  switch (billerSlug) {
    // @TODO: Delete once energy-on is released
    case 'energy-on-v1':
      return [{priority: 4, name: 'Requests', href: '/requests'}];
    case 'energy-on':
      return [
        {priority: 0, name: 'Audience', href: '/audience'},
        {priority: 2, name: 'Payments', href: '/payments'},
        {
          priority: 10,
          name: 'Import/Export',
          href: '/import-export',
        },
        {
          priority: 11,
          name: 'Support',
          href: '/support',
        },
      ];

    case 'agl-energy':
    case 'agl':
      return [
        {priority: 0, name: 'Audience', href: '/audience'},
        {
          priority: 10,
          name: 'Import/Export',
          href: '/import-export',
        },
        {priority: 2, name: 'Payments', href: '/payments', disabled: true},
        {
          priority: 11,
          name: 'Support',
          href: 'https://payble.freshdesk.com',
          isExternal: true,
        },
      ];
    case 'city-of-exampleton':
      return [
        {priority: 0, name: 'Audience', href: '/audience'},
        {priority: 2, name: 'Payments', href: '/payments'},
        {
          priority: 10,
          name: 'Import/Export',
          href: '/import-export',
        },
        {
          priority: 11,
          name: 'Requested Plans',
          href: '/plan-requests',
        },
        {
          priority: 12,
          name: 'Audit',
          href: '/audit',
        },
        {
          priority: 13,
          name: 'Support',
          href: 'https://payble.freshdesk.com',
          isExternal: true,
        },
      ];
    default:
      return [
        {priority: 0, name: 'Audience', href: '/audience'},
        {priority: 2, name: 'Payments', href: '/payments'},
        {
          priority: 10,
          name: 'Import/Export',
          href: '/import-export',
        },
        {
          priority: 11,
          name: 'Requested Plans',
          href: '/plan-requests',
        },
        {
          priority: 12,
          name: 'Support',
          href: 'https://payble.freshdesk.com',
          isExternal: true,
        },
      ];
  }
};
