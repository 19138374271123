import React from 'react';

export const PopOverCtaBottomRow: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  return (
    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6 sticky bottom-0 bg-white">
      <div className="flex justify-end space-x-3">{children}</div>
    </div>
  );
};
