import {
  NZBankAccountNumber,
  validateBankAccountName,
  validateBankAccountNumber,
  validateNZBankAccountName,
} from 'payble-shared';

type BankAccountValue = {
  accountName: string;
  bsbNumber: string;
  accountNumber: string;
  [key: string]: unknown;
};
type ValidateBankAccount = {
  values: BankAccountValue;
};

export function validateBankAccount({values}: ValidateBankAccount) {
  let errors = {};

  if (!values.accountName) {
    errors = {
      ...errors,
      accountName: 'Account name is required.',
    };
  }

  if (values.accountName && !validateBankAccountName(values.accountName)) {
    errors = {
      ...errors,
      accountName: 'Invalid account name.',
    };
  }

  if (!values.bsbNumber) {
    errors = {
      ...errors,
      bsbNumber: 'BSB is required.',
    };
  }

  if (!values.accountNumber) {
    errors = {
      ...errors,
      accountNumber: 'Account number is required.',
    };
  }

  if (
    values.accountNumber &&
    !validateBankAccountNumber(values.accountNumber)
  ) {
    errors = {
      ...errors,
      accountNumber: 'Invalid account number.',
    };
  }

  return errors;
}

export function validateNZBankAccount({
  values,
}: {
  values: {accountName: string; accountNumber: string};
}) {
  let errors = {};

  if (!validateNZBankAccountName(values.accountName)) {
    errors = {
      ...errors,
      accountName: 'Invalid bank account name.',
    };
  }

  if (!values.accountNumber) {
    errors = {
      ...errors,
      accountNumber: 'Account number is required.',
    };
  }

  if (!NZBankAccountNumber.maybeFromJSON(values.accountNumber)) {
    errors = {
      ...errors,
      accountNumber: 'Invalid account number.',
    };
  }

  return errors;
}
