import {Outlet, useLocation} from 'react-router-dom';
import {OnlyStaff} from 'components/OnlyStaff';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {StageResetButton} from './StageResetButton';
import {Button} from 'payble-ui';

const stage = import.meta.env.VITE_STAGE;

export const ConfigLayout = () => {
  const location = useLocation();
  const [, page] = location.pathname.split('/').filter(Boolean);
  const isProd = stage === 'production' || !stage;

  return (
    <OnlyStaff>
      <div className="py-10">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h3 className="flex items-center justify-between text-3xl font-bold leading-6 text-gray-900">
            Manage Config
            {!isProd && (
              <div className="grow-0">
                <StageResetButton />
              </div>
            )}
          </h3>
          <div className="mt-12">
            <div className="flex items-center justify-between">
              <nav className="flex -mb-px space-x-8">
                <Link
                  className={classNames(
                    page === 'environments'
                      ? 'border-payble-violet text-payble-violet'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap pb-4 px-6 border-b-2 font-semibold text-sm cursor-pointer transition-all'
                  )}
                  to="/config/environments/biller"
                >
                  Environments
                </Link>
                <Link
                  className={classNames(
                    page === 'auto-imports'
                      ? 'border-payble-violet text-payble-violet'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap pb-4 px-6 border-b-2 font-semibold text-sm cursor-pointer transition-all'
                  )}
                  to="/config/auto-imports"
                >
                  Auto Imports
                </Link>
              </nav>
              <div>
                {page === 'auto-imports' && (
                  <Link to="/config/auto-imports/create">
                    <Button iconL="add">Add New</Button>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </OnlyStaff>
  );
};
