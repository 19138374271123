import React, {ReactNode, useEffect} from 'react';
import {Breadcrumbs} from 'components/atoms/Breadcrumbs';
import {auth} from 'lib/index';
import {getBillerConfig} from 'payble-shared';
import {useGetRequestedPlansBySearchLazyQuery} from 'lib/graphql/API';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {PlanRequestsTable} from 'features/plan-requests/components/PlanRequestsTable';
import {WrenchScrewdriverIcon} from '@heroicons/react/24/outline';
import {ReviewPlanRequestForm} from '../forms/ReviewPlanRequestForm';

const PlanRequestsPageWrapper: React.FC<{children: ReactNode}> = ({
  children,
}) => {
  return (
    <div>
      <div className="mt-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <Breadcrumbs
            pages={[{name: 'Requested Plans', href: '/plan-requests'}]}
          />
          {children}
        </div>
      </div>
    </div>
  );
};

export const PlanRequests: React.FC = () => {
  const [requestedPlanToReview, setRequestedPlanToReview] = React.useState<
    string | undefined
  >();
  const {billerSlug: slug} = auth.useCurrentUser();
  const billerFeatures = getBillerConfig(slug);
  const reviewRequestedPlanDisclosure = useDisclosure();

  const [fetch, {data, loading, error}] = useGetRequestedPlansBySearchLazyQuery(
    {
      variables: {
        offset: 0,
      },
    }
  );

  useEffect(() => {
    if (!data && !loading && !error) {
      fetch();
    }
  }, []);

  useEffect(() => {
    if (!reviewRequestedPlanDisclosure.isOpen) {
      setRequestedPlanToReview(undefined);
    }
  }, [reviewRequestedPlanDisclosure.isOpen]);

  if (!billerFeatures.hasRequestPlan) {
    return (
      <PlanRequestsPageWrapper>
        <div className="relative block w-full max-w-190 p-12 text-center mt-2">
          <WrenchScrewdriverIcon className="mx-auto h-12 w-12 text-gray-400" />
          <span className="mt-2 block font-semibold text-gray-700 m-2">
            Coming soon
          </span>
          <p className="text-sm text-gray-700">
            We are still working on requested payment plans.
          </p>
          <p className="text-sm text-gray-700">
            If you would like to find out more about this feature please contact
            us.
          </p>
        </div>
      </PlanRequestsPageWrapper>
    );
  }

  const [page, setPage] = React.useState(1);

  const goToPage = (pageNum: number) => {
    fetch({
      variables: {offset: (pageNum - 1) * 10},
    });
    setPage(pageNum);
  };

  return (
    <>
      <PlanRequestsPageWrapper>
        <PlanRequestsTable
          loading={loading}
          error={error}
          planRequests={data?.planRequests?.edges ?? []}
          total={data?.planRequests.total}
          goToPage={goToPage}
          page={page}
          onClickRequestedPlan={requestedPlan => {
            setRequestedPlanToReview(requestedPlan.id);
            reviewRequestedPlanDisclosure.onOpen();
          }}
        />
      </PlanRequestsPageWrapper>
      <ReviewPlanRequestForm
        disclosure={reviewRequestedPlanDisclosure}
        planRequest={
          data?.planRequests?.edges.find(
            edge => edge.node?.id === requestedPlanToReview
          )?.node
        }
      />
    </>
  );
};
