import {
  DateContext,
  DateFrequency,
  err,
  generateDateSeries,
} from 'payble-shared';
import {InstalmentPreview, getId} from './generatePreviewInstlaments';
import {AbsoluteDate} from 'payble-shared/src/util/time/AbsoluteDate';

type GenerateInstalmentsBasedOnInstalmentAmount = {
  frequency: DateFrequency;
  startDate: AbsoluteDate;
  endDate: AbsoluteDate;
  instalmentAmount: number;
};

export function generateInstalmentsBasedOnInstalmentAmount(
  {
    startDate,
    endDate,
    frequency,
    instalmentAmount,
  }: GenerateInstalmentsBasedOnInstalmentAmount,
  context: DateContext
): InstalmentPreview[] {
  const series = generateDateSeries(
    {
      start: startDate,
      end: endDate,
      frequency: frequency,
      inclusive: true,
    },
    context
  );

  if (err(series)) {
    throw series;
  }

  return series.map(date => ({
    dueAt: date,
    amount: instalmentAmount,
    __id: getId(),
  }));
}
