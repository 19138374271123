import {Account, GetAccountQuery} from 'lib/graphql/API';

export function extractAccountDataWithKey<T extends keyof Account>({
  account,
  accountData,
  key,
}: {
  account?: Account;
  accountData?: GetAccountQuery;
  key: T;
}): Account[T] | undefined {
  return account?.[key] || accountData?.account?.[key];
}
