import {Form} from 'payble-ui';
import {UseTokenExProps, useTokenex} from '../hooks/useTokenex';
import {z} from 'zod';
import {TokenExCreditCardNumber} from './TokenExCreditCardNumber';
import {TokenExCreditCardCvv} from './TokenExCreditCardCvv';
import {DateTime} from 'luxon';

type ContactDetails = {
  givenName: string;
  familyName: string;
  email: string;
};

interface TokenExCreditCardFormProps extends UseTokenExProps {
  id: string;
  children?: React.ReactNode;
  onSubmit: (data: {
    card: {
      numberToken: string;
      numberTokenHmac: string;
      expiryMonth: number;
      expiryYear: number;
      brand: string;
      last4: string;
      holderName: string;
      referenceNumber: string;
    };
    contact: ContactDetails;
    usePaymentMethodForPlans?: string[];
    sendReceipts?: boolean;
  }) => Promise<void>;
}

const schema = z
  .object({
    title: z.enum(['Dr', 'Miss', 'Mr', 'Mrs', 'Ms', 'Mx', 'Prof']),
    givenName: z.string().min(1, {message: 'Required'}).max(255),
    familyName: z.string().min(1, {message: 'Required'}).max(255),
    cardholder: z.string().min(1, {message: 'Required'}).max(255),
    cardNumber: z.string(),
    expiryDate: z.string().regex(/^\d{2}\/\d{2}$/),
    cvv: z.string(),
    email: z.string().email(),
  })
  .required();

export const TokenExCreditCardForm = ({
  id,
  authKeyData,
  isLoadingAuthKey,
  reloadAuthKey,
  onSubmit,
  children,
}: TokenExCreditCardFormProps) => {
  const {data, tokenEx, tokenizeAsync} = useTokenex({
    authKeyData,
    isLoadingAuthKey,
    reloadAuthKey,
  });

  return (
    <Form
      id={id}
      schema={schema}
      onError={a => console.log(a)}
      onSubmit={async data => {
        if (!tokenEx) throw new Error('Tokenex is not ready');

        const expiry = DateTime.fromFormat(data.expiryDate, 'MM/yy');
        const cardData = await tokenizeAsync();
        const {givenName, familyName, email, cardholder} = data;
        const card = {
          holderName: cardholder,
          expiryMonth: parseInt(expiry.toFormat('MM')),
          expiryYear: parseInt(expiry.toFormat('yy')),
          last4: cardData.lastFour,
          numberToken: cardData.token,
          referenceNumber: cardData.referenceNumber,
          numberTokenHmac: cardData.tokenHMAC,
          brand: cardData.cardType,
        };

        const contact = {
          givenName,
          familyName,
          email,
        };

        await onSubmit({
          card,
          contact,
          // sendReceipts, TODO: ivan
        });
      }}
    >
      <div className="flex gap-4">
        <Form.Input
          name="givenName"
          label="Given name"
          autoComplete="cc-given-name"
        />
        <Form.Input
          name="familyName"
          label="Family name"
          autoComplete="cc-family-name"
        />
      </div>
      <Form.Input name="email" type="email" label="Email" />
      <Form.Input
        name="cardholder"
        autoComplete="cc-cardholder"
        type="text"
        label="Cardholder"
      />
      <TokenExCreditCardNumber data={data} tokenEx={tokenEx} />
      <div className="flex gap-4">
        <Form.InputNumberFormat
          name="expiryDate"
          label="Expiry date"
          format="##/##"
          placeholder="MM/YY"
          mask={['M', 'M', 'Y', 'Y']}
          autoComplete="cc-exp"
        />
        <TokenExCreditCardCvv data={data} tokenEx={tokenEx} />
      </div>

      {children}
    </Form>
  );
};
