import React from 'react';
import {ApolloProvider} from '@apollo/client';
import {client} from 'lib/graphql/apolloClient';

import {Navigation} from './Navigation';

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Navigation />
    </ApolloProvider>
  );
};

export default App;
