import {
  camelCaseToSentance,
  formatMoney,
  getValueFromUnknownObjectByKey,
  toTitleCase,
} from '.';

const formatMapper: Record<string, (value: unknown) => unknown> = {
  isPayblePlan: (value: unknown) => (value ? 'Yes' : 'No'),
  instalmentAmount1: formatMoneyFromMetaData,
  instalmentAmount2: formatMoneyFromMetaData,
  instalmentAmount3: formatMoneyFromMetaData,
  instalmentAmount4: formatMoneyFromMetaData,
  calculatedBalance: formatMoneyFromMetaData,
  owingDelta: formatMoneyFromMetaData,
  hasBillerOwnedPlan: (value: unknown) => (value ? 'Yes' : 'No'),
  setupBreakdown: value =>
    Array.isArray(value)
      ? value.map(v => Object.values(v).join(':')).join(', ')
      : undefined,
} as const;

function formatValueForKey(accountMeta: unknown, key: string) {
  const value = getValueFromUnknownObjectByKey(accountMeta, key);

  if (!value && !formatMapper?.[key]) {
    return '';
  }
  return formatMapper?.[key]?.(value) ?? value;
}

function formatMoneyFromMetaData(value: unknown) {
  return typeof value === 'number' ? formatMoney(value) : `${value}`;
}

export const getFormattedAccountMeta = (
  accountMeta: any
): Array<{title: string; value: string | number}> => {
  return Object.keys(accountMeta)
    .map(key => ({
      title: toTitleCase(camelCaseToSentance(key)),
      value: formatValueForKey(accountMeta, key),
    }))
    .filter((obj): obj is {value: string | number; title: string} => {
      return typeof obj.value === 'string' || typeof obj.value === 'number';
    });
};
