import {
  GetAccountQuery,
  LookupAccountsQuery,
  useGetAccountLazyQuery,
  useLookupAccountsQuery,
} from 'lib/graphql/API';
import {useSearch} from 'lib/hooks/useSearch';
import {useEffect, useState} from 'react';
import {Search} from 'components/atoms/Search';
import {camelCaseToSentance, formatMoney, toTitleCase} from 'payble-shared';
import {FormErrorMessage} from 'features/audience/components/FormErrorMessage';

const AccountSummary: React.FC<{accountData: GetAccountQuery | undefined}> = ({
  accountData,
}) => {
  if (!accountData?.account) {
    return null;
  }

  const {externalId, type, description, amountOwing} = accountData.account;

  const accountSummary = {
    externalId,
    description,
    accountType: toTitleCase(type),
    amountOwing: formatMoney(amountOwing),
  };

  return (
    <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
      <div>
        <h3 className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">
          Account Summary
        </h3>
      </div>
      <div className="sm:col-span-2">
        {Object.entries(accountSummary).map(([key, value]) => (
          <div
            className="py-1 flex justify-between text-sm font-medium"
            key={key}
          >
            <dt className="text-gray-500">
              {toTitleCase(camelCaseToSentance(key))}
            </dt>
            <dd className="text-gray-900">{value}</dd>
          </div>
        ))}
      </div>
    </div>
  );
};

type SelectedValue = {
  externalId: string;
  description: string;
  accountType: string;
};

export function formatLookupResult(
  lookupData: LookupAccountsQuery | undefined
) {
  if (!lookupData?.accountLookup) {
    return [];
  }

  return lookupData.accountLookup.map(account => {
    const {externalId, accountType, description} = account;

    return {
      id: externalId,
      name: `${externalId} ${accountType} ${description}`,
      type: accountType,
    };
  });
}

export const SearchAccount = ({
  onDataChanged,
  onSearchError,
  errorMessage,
}: {
  onDataChanged: (v?: GetAccountQuery) => void;
  onSearchError: (v?: Error) => void;
  errorMessage?: string;
}) => {
  const [selected, setSelected] = useState<SelectedValue>({
    externalId: '',
    description: '',
    accountType: '',
  });
  const [
    accountFetch,
    {data: accountData, loading: accountLoading, error: accountError},
  ] = useGetAccountLazyQuery({
    variables: {},
  });

  const lookup = useSearch();

  const {
    data: lookupData,
    loading: lookupLoading,
    error: lookupError,
  } = useLookupAccountsQuery({
    variables: {
      lookup: lookup.value,
    },
    skip: !lookup.value.trim(),
  });

  useEffect(() => {
    onSearchError(accountError);
  }, [accountError]);

  useEffect(() => {
    onSearchError(lookupError);
  }, [lookupError]);

  useEffect(() => {
    onDataChanged(accountData);
  }, [accountData]);

  return (
    <>
      <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
        <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
          <div>
            <label
              htmlFor="link-account"
              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
            >
              Link To Account
            </label>
          </div>
          <div className="sm:col-span-2">
            <Search
              onSearchInputChange={searchValue => {
                lookup.onChange(searchValue);
              }}
              onSearchSelected={async value => {
                const lookup = lookupData?.accountLookup.find(
                  account =>
                    account.externalId === value.id &&
                    account.accountType === value.type
                );

                if (lookup) {
                  setSelected(lookup);

                  await accountFetch({
                    variables: {
                      externalId: lookup?.externalId,
                      type: lookup?.accountType,
                    },
                  });
                }
              }}
              selectedValue={{
                id: selected.externalId,
                type: selected.accountType,
              }}
              loading={accountLoading || lookupLoading}
              values={formatLookupResult(lookupData)}
              id="search-account"
            />
            {errorMessage && <FormErrorMessage message={errorMessage} />}
          </div>
        </div>
      </div>
      <AccountSummary accountData={accountData} />
    </>
  );
};
