import {z} from 'zod';
import {zAbsoluteDate} from './generic';

export const INSTALMENT_STATUSES = [
  'cancelled',
  'missed',
  'overdue',
  'paid',
  'processing',
  'refunded',
  'scheduled',
  'skipped',
] as const;

export type InstalmentStatus = (typeof INSTALMENT_STATUSES)[number];

export const planResponse = z.object({
  amount: z.number(),
  amountPaid: z.number(),
  amountDue: z.number(),
  instalments: z.array(
    z.object({
      instalmentId: z.string(),
      dueAt: zAbsoluteDate,
      status: z.enum(INSTALMENT_STATUSES).optional(),
      amount: z.number(),
    })
  ),
});

export type PlanResponseType = z.infer<typeof planResponse>;
