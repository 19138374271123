type GetSignUpQueryStringKeyAndValue = {
  externalId: string;
  type: string;
  admin: boolean;
  verificationCode?: string;
};
export function getSignUpQueryStringKeyValue({
  externalId,
  type,
  admin,
  verificationCode,
}: GetSignUpQueryStringKeyAndValue): Record<string, string> {
  return {
    e: externalId,
    t: type,
    v: '1',
    ...(verificationCode ? {a: verificationCode} : {}),
    ...(admin ? {s: 'admin'} : {}),
  };
}
