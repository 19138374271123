import React, {useEffect, useState} from 'react';
import {ArrowSmallDownIcon, ArrowSmallUpIcon} from '@heroicons/react/20/solid';
import {
  AccountEdge,
  PayerEdge,
  ContactEdge,
  useGetAccountsSearchLazyQuery,
  useSearchAudienceLazyQuery,
  useGetAudienceStatsQuery,
  useGetPayersSearchLazyQuery,
  useGetAccountTypeLazyQuery,
} from 'lib/graphql/API';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import classNames from 'classnames';
import {AccountTable} from './components/AccountTable';
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline';
import {useDebouncedCallback} from 'use-debounce';
import {ContactTable} from './components/ContactTable';
import {PayersTable} from './components/PayersTable';
import {formatStats} from './helpers';
import {auth} from '../../lib';
import {AudienceCta} from './components/AudienceCta';
import {AddContactForm} from './forms/AddContactFrom';
import {AddPayerForm} from './forms/AddPayerForm';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {capitalize} from 'payble-shared';
import {Select} from 'components/atoms/Select';

export function Audience() {
  const [mode, setMode] = useState<'contacts' | 'accounts' | 'payers'>(
    'contacts'
  );

  const [accountTypeFilter, setAccountTypeFilter] = React.useState<string[]>(
    []
  );

  const addContactDisclosure = useDisclosure();

  const addPayer = useDisclosure();

  const [accountTypeFetch, {data: accountTypeData}] =
    useGetAccountTypeLazyQuery();

  useEffect(() => {
    if (mode !== 'contacts') {
      addContactDisclosure.onClose();
    }
  }, [mode]);

  useEffect(() => {
    accountTypeFetch();
  }, []);

  useEffect(() => {
    setAccountTypeFilter(accountTypeData?.accountTypes || []);
  }, [accountTypeData]);

  const {
    data: statsData,
    loading: statsLoading,
    error: statsError,
  } = useGetAudienceStatsQuery();

  const [
    contactFetch,
    {data: contactData, loading: contactLoading, error: contactError},
  ] = useSearchAudienceLazyQuery({variables: {offset: 0}});
  const [
    accountFetch,
    {data: accountData, loading: accountLoading, error: accountError},
  ] = useGetAccountsSearchLazyQuery({variables: {offset: 0}});

  const [
    payersFetch,
    {data: payersData, loading: payersLoading, error: payersError},
  ] = useGetPayersSearchLazyQuery({
    variables: {offset: 0},
  });

  const [accountPage, setAccountPage] = React.useState(1);
  const [contactPage, setContactPage] = React.useState(1);
  const [payersPage, setPayersPage] = React.useState(1);
  const [isTyping, setIsTyping] = React.useState(false);

  const search = React.useRef<string>();

  useEffect(() => {
    if (accountTypeFilter.length === 0) {
      setAccountTypeFilter(accountTypeData?.accountTypes ?? []);
    }

    setAccountPage(1);
    accountFetch({
      variables: {
        search: search.current,
        first: 10,
        types: accountTypeFilter,
      },
    });
  }, [accountTypeFilter]);

  // Trigger empty search on load
  useEffect(() => {
    contactFetch({
      variables: {
        first: 10,
      },
    });
    accountFetch({
      variables: {
        first: 10,
      },
    });

    payersFetch({
      variables: {
        first: 10,
      },
    });
  }, []);

  const goToPage = (pageNum: number) => {
    if (mode === 'contacts') {
      contactFetch({
        variables: {offset: (pageNum - 1) * 10, search: search.current},
      });
      setContactPage(pageNum);
    }
    if (mode === 'accounts') {
      accountFetch({
        variables: {
          offset: (pageNum - 1) * 10,
          search: search.current,
          types: accountTypeFilter,
        },
      });
      setAccountPage(pageNum);
    }

    if (mode === 'payers') {
      payersFetch({
        variables: {offset: (pageNum - 1) * 10, search: search.current},
      });
      setPayersPage(pageNum);
    }
  };

  // Wait until they finish typing before making request
  const debouncedSearch = useDebouncedCallback(
    value => {
      accountFetch({
        variables: {
          search: value,
          first: 10,
          types: accountTypeFilter,
        },
      });
      contactFetch({
        variables: {
          search: value,
          first: 10,
        },
      });

      payersFetch({
        variables: {
          search: value,
          first: 10,
        },
      });

      search.current = value;
      setIsTyping(false);
      setAccountPage(1);
      setContactPage(1);
      setPayersPage(1);
    },
    // delay in ms
    500
  );

  const stats = formatStats(statsData);

  const {billerSlug: slug} = auth.useCurrentUser();
  const displayStats = slug !== 'agl-energy';

  return (
    <div className="py-10">
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {displayStats ? (
            <div className="mb-10">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Last 30 days
              </h3>
              <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
                {statsError ? (
                  <div className="flex justify-center col-span-3 pb-10">
                    <ErrorMessage message={statsError.message} />
                  </div>
                ) : null}
                {statsLoading ? (
                  <div className="flex justify-center col-span-3 py-10">
                    <Loading />
                  </div>
                ) : null}
                {!statsLoading &&
                  !statsError &&
                  stats.map(item => (
                    <div key={item.name} className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900">
                        {item.name}
                      </dt>
                      <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-payble-violet">
                          {item.stat}
                          <span className="ml-2 text-sm font-medium text-gray-500">
                            from {item.previousStat}
                          </span>
                        </div>

                        <div
                          className={classNames(
                            item.changeType === 'increase'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-red-100 text-red-800',
                            'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                          )}
                        >
                          {item.changeType === 'increase' ? (
                            <ArrowSmallUpIcon
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                              aria-hidden="true"
                            />
                          ) : (
                            <ArrowSmallDownIcon
                              className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                              aria-hidden="true"
                            />
                          )}

                          <span className="sr-only">
                            {item.changeType === 'increase'
                              ? 'Increased'
                              : 'Decreased'}{' '}
                            by
                          </span>
                          {item.change}
                        </div>
                      </dd>
                    </div>
                  ))}
              </dl>
            </div>
          ) : null}
          <div>
            <div className="relative border-b border-gray-200 sm:pb-0">
              <div className="md:flex md:items-center md:justify-between">
                <h3 className="text-3xl leading-6 font-bold text-gray-900">
                  Audience
                  <br />
                  <p className="flex mt-2 mb-2 text-sm text-gray-700 font-normal">
                    A list of all contacts who have been uploaded into Payble or
                    have signed up.
                  </p>
                </h3>
                <AudienceCta
                  mode={mode}
                  contacts={{
                    onAdd: () => {
                      addContactDisclosure.onOpen();
                    },
                  }}
                  payers={{
                    onAdd: () => {
                      addPayer.onOpen();
                    },
                  }}
                />
                <AddContactForm disclosure={addContactDisclosure} />
                <AddPayerForm disclosure={addPayer} />
                <div className="mt-3 flex md:mt-0 md:absolute md:bottom-3 md:right-0">
                  <div className="mt-2 relative rounded-md mr-2">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <MagnifyingGlassIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="focus:ring-blue-500 focus:border-blue-500 block pl-10 sm:text-sm border-gray-300 rounded-md w-96"
                      placeholder="Search contacts and accounts"
                      autoFocus={true}
                      onChange={e => {
                        setIsTyping(true);
                        debouncedSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    <div
                      onClick={() => setMode('contacts')}
                      id="nav-contacts"
                      className={classNames(
                        mode === 'contacts'
                          ? 'border-payble-violet text-payble-violet'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap pb-4 px-6 border-b-2 font-semibold text-sm cursor-pointer transition-all'
                      )}
                      aria-current={mode === 'contacts' ? 'page' : undefined}
                    >
                      Contacts
                    </div>
                    <div
                      onClick={() => setMode('accounts')}
                      id="nav-accounts"
                      className={classNames(
                        mode === 'accounts'
                          ? 'border-payble-violet text-payble-violet'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap pb-4 px-6 border-b-2 font-semibold text-sm cursor-pointer transition-all'
                      )}
                      aria-current={mode === 'accounts' ? 'page' : undefined}
                    >
                      Accounts
                    </div>
                    <div
                      onClick={() => setMode('payers')}
                      id="nav-payers"
                      className={classNames(
                        mode === 'payers'
                          ? 'border-payble-violet text-payble-violet'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap pb-4 px-6 border-b-2 font-semibold text-sm cursor-pointer transition-all'
                      )}
                      aria-current={mode === 'payers' ? 'page' : undefined}
                    >
                      Payers
                    </div>
                  </nav>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="flex justify-end py-2 min-w-full"></div>
                  {mode === 'contacts' && (
                    <ContactTable
                      loading={contactLoading || isTyping}
                      error={contactError}
                      contacts={contactData?.contacts?.edges as ContactEdge[]}
                      goToPage={goToPage}
                      page={contactPage}
                      total={contactData?.contacts.total}
                    />
                  )}
                  {mode === 'accounts' && (
                    <>
                      {accountTypeData?.accountTypes &&
                        accountTypeData?.accountTypes?.length > 1 && (
                          <div className="flex flex-wrap gap-4 px-2 pb-4 justify-end">
                            <div className="w-48">
                              <Select
                                values={[
                                  {
                                    id: '',
                                    name: 'All',
                                  },
                                  ...accountTypeData.accountTypes.map(t => ({
                                    id: t,
                                    name: capitalize(t),
                                  })),
                                ]}
                                onChange={async item => {
                                  setAccountTypeFilter(item ? [item] : []);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      <AccountTable
                        loading={accountLoading || isTyping}
                        error={accountError}
                        accounts={accountData?.accounts?.edges as AccountEdge[]}
                        goToPage={goToPage}
                        page={accountPage}
                        total={accountData?.accounts.total}
                      />
                    </>
                  )}
                  {mode === 'payers' && (
                    <PayersTable
                      loading={payersLoading || isTyping}
                      error={payersError}
                      payers={payersData?.payers.edges as PayerEdge[]}
                      goToPage={goToPage}
                      page={payersPage}
                      total={payersData?.payers.total}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
