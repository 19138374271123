import {activePageNumberClasses, inactivePageNumberClasses} from 'lib/styles';
import React from 'react';

type PageNumberProps = {
  page: number;
  isCurrent: boolean;
  goToPage: (p: number) => void;
};

export const PageNumber: React.FC<PageNumberProps> = ({
  page,
  isCurrent,
  goToPage,
}) => {
  return (
    <>
      {isCurrent ? (
        <button
          aria-current="page"
          className={activePageNumberClasses}
          onClick={() => {
            goToPage(page);
          }}
        >
          {page}
        </button>
      ) : (
        <button
          className={inactivePageNumberClasses}
          onClick={() => {
            goToPage(page);
          }}
        >
          {page}
        </button>
      )}
    </>
  );
};
