import React from 'react';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from '../forms/components/FormHeader';
import {
  BuildingLibraryIcon,
  ExclamationTriangleIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline';

const ICON_CLASS_NAMES = 'mx-auto h-12 w-12 text-gray-400';
type Icons =
  | 'BuildingLibraryIcon'
  | 'ExclamationTriangleIcon'
  | 'WrenchScrewdriverIcon';

export const Icon: React.FC<{icon: Icons}> = ({icon}) => {
  switch (icon) {
    case 'BuildingLibraryIcon':
      return <BuildingLibraryIcon className={ICON_CLASS_NAMES} />;
    case 'ExclamationTriangleIcon':
      return <ExclamationTriangleIcon className={ICON_CLASS_NAMES} />;
    case 'WrenchScrewdriverIcon':
      return <WrenchScrewdriverIcon className={ICON_CLASS_NAMES} />;
    default:
      return <></>;
  }
};

type BlockedSidebarProps = {
  isOpen: boolean;
  onClose: () => void;
  headerTitle: string;
  headerDescription: string;
  headline: string;
  text: string;
  icon: Icons;
};

export const BlockedSidebar: React.FC<BlockedSidebarProps> = ({
  headerTitle,
  headerDescription,
  headline,
  text,
  isOpen,
  onClose,
  icon,
}) => {
  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <FormHeader
        setOpen={onClose}
        title={headerTitle}
        description={headerDescription}
      />
      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
        <div className="relative block w-full p-12 text-center mt-32">
          <Icon icon={icon} />
          <span className="mt-2 block font-semibold text-gray-700 m-2">
            {headline}
          </span>
          <p className="text-sm text-gray-700">{text}</p>
        </div>
      </div>
    </PopOverSidebar>
  );
};
