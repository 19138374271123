import {AbsoluteDate, formatToDollars} from 'payble-shared';
import React from 'react';

const DetailsRow: React.FC<{type: string; value: string}> = ({type, value}) => {
  return (
    <>
      <p className="text-sm text-gray-600 font-bold">{type}</p>
      <p className="text-sm text-gray-600">{value}</p>
    </>
  );
};

export const AccountDetails: React.FC<{
  externalId: string;
  type: string;
  amountOwing?: number;
  description: string;
}> = ({externalId, type, amountOwing, description}) => {
  return (
    <div>
      <h4 className="mt-4 mb-2 font-medium text-gray-900">Account Details</h4>
      <div className="grid grid-cols-2 gap-4 pb-4">
        <DetailsRow type="External Id" value={externalId} />
        <DetailsRow type="Type" value={type} />
        <DetailsRow
          type="Amount Owing"
          value={amountOwing ? `$${formatToDollars(amountOwing)}` : ''}
        />
        <DetailsRow type="Description" value={description} />
      </div>
    </div>
  );
};

export const ContactDetails: React.FC<{
  name: string;
  mobile: string;
  email: string;
}> = ({name, mobile, email}) => {
  return (
    <div>
      <h4 className="mt-4 mb-2 font-medium text-gray-900">Contact Details</h4>
      <div className="grid grid-cols-2 gap-4 pb-4">
        <DetailsRow type="Name" value={name} />
        <DetailsRow type="Mobile" value={mobile} />
        <DetailsRow type="Email" value={email} />
      </div>
    </div>
  );
};

export const PlanRequestDetails: React.FC<{
  instalmentAmount: number;
  frequency: string;
  startAt: AbsoluteDate;
}> = ({instalmentAmount, frequency, startAt}) => {
  return (
    <div>
      <h4 className="mt-4 mb-2 font-medium text-gray-900">Requested Plan</h4>
      <div className="grid grid-cols-2 gap-4 pb-4">
        <DetailsRow
          type="instalment Amount"
          value={`$${formatToDollars(instalmentAmount)}`}
        />
        <DetailsRow type="Frequency" value={frequency} />
        <DetailsRow type="Start at" value={startAt.toFormat('dd/MM/yyyy')} />
      </div>
    </div>
  );
};
