import {PopOverCtaBottomRow} from 'components/atoms/PopOverCtaBottomRow';
import React from 'react';

type FormSubmissionProps = {
  isSubmitting: boolean;
  submissionButtonText: string;
  onCancel: () => void;
  submissionDisabled?: boolean;
  cancelButtonText?: string;
  children?: React.ReactNode;
};

export const FormSubmission: React.FC<FormSubmissionProps> = ({
  isSubmitting,
  submissionButtonText,
  onCancel,
  submissionDisabled = false,
  cancelButtonText = 'Cancel',
}) => (
  <PopOverCtaBottomRow>
    <button
      type="button"
      disabled={isSubmitting}
      className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
      onClick={() => onCancel()}
    >
      {cancelButtonText}
    </button>
    <button
      type="submit"
      disabled={submissionDisabled || isSubmitting}
      className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {submissionButtonText}
    </button>
  </PopOverCtaBottomRow>
);
