import {useState} from 'react';
import {useDebounce} from 'use-debounce';

export const useSearch = () => {
  const [value, setValue] = useState<string>('');
  const [debouncedValue, {isPending, flush}] = useDebounce(value, 500);

  return {
    isTyping: isPending(),
    value: debouncedValue,
    reset: () => {
      setValue('');
      flush();
    },
    onChange: (value: string) => setValue(value),
  };
};
