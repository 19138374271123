import React, {useEffect, useState} from 'react';

import {Formik, useFormikContext} from 'formik';
import {
  AdminRequestedNotificationInput,
  AdminRequestedNotificationInputType,
  InstalmentFrequency,
  useGetAccountsSearchLazyQuery,
  useGetInstalmentPlansQuery,
  useSendAdminRequestedNotificationMutation,
} from 'lib/graphql/API';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from './components/FormHeader';
import {FormSubmission} from './components/FormSubmission';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {SelectMode} from './components/SelectMode';
import {SmsPreview} from './components/SmsPreview';
import {Search} from 'components/atoms/Search';
import {useDebouncedCallback} from 'use-debounce';
import {formatSearchResult} from './utils/formatSearchResult';
import {FormErrorMessage} from '../components/FormErrorMessage';
import {auth} from 'lib/index';
import {BillerConfig, formatToCents, formatToDollars} from 'payble-shared';
import {Select} from 'components/atoms/Select';
import {MiniSpinner} from 'components/atoms/Spinner';
import {Switch} from 'payble-ui';
import CurrencyInput from 'react-currency-input-field';

const modes = (billerConfig: BillerConfig) => [
  {
    name: 'Update payment method',
    id: 'updatePaymentMethod' as const,
    description: 'Send a notification with link to update payment method',
  },
  {
    name: 'Send link to create plan',
    id: 'createPlan' as const,
    description: 'Send a notification with link to create a plan',
  },
  {
    name: 'Send link to make payment',
    id: 'oneOffPayment' as const,
    description: 'Send a notification with link to make a payment',
  },
  ...(billerConfig.hasRequestPlan
    ? [
        {
          name: 'Send plan request invite',
          id: 'planRequestInvite' as const,
          description:
            'Send a notification with a link to plan requests, where a plan can be proposed for you to review',
        },
      ]
    : []),
  {
    name: 'Send link to edit profile',
    id: 'editProfile' as const,
    description: 'Send a notification with link to edit profile',
  },
  {
    name: 'Send link to cancel plan',
    id: 'cancelPlan' as const,
    description: 'Send a notification with link to cancel a plan',
  },
];

const MODES_REQUIRE_ACCOUNT = [
  'createPlan',
  'oneOffPayment',
  'planRequestInvite',
  'cancelPlan',
];

const MODES_REQUIRE_PLAN = ['cancelPlan'];

const MODES_ACCOUNT_VALIDATION = ['cancelPlan'];

const MODES_SUGGESTED_AMOUNT = ['planRequestInvite'];

type Form = {
  mode?: AdminRequestedNotificationInputType;
  planId?: string;
  suggestedPlanSetup: boolean;
  instalmentFrequency?: InstalmentFrequency;
  instalmentAmount?: number;
  accountId: string;
  type: string;
};

const FORM_INITIAL_VALUES: Form = {
  mode: undefined,
  planId: '',
  suggestedPlanSetup: false,
  instalmentFrequency: undefined,
  instalmentAmount: undefined,
  accountId: '',
  type: '',
};

type SendNotificationFormProps = {
  contactId: string;
  disclosure: ReturnType<typeof useDisclosure>;
};

const PlanSelect = ({
  contactId,
  accountId,
  onChange,
}: {
  contactId: string;
  accountId: string;
  onChange: (v: string) => void;
}) => {
  const {errors} = useFormikContext<{planId: string}>();
  const {data, loading} = useGetInstalmentPlansQuery({
    variables: {
      accountId,
      contactId,
    },
  });
  const cancellablePlans = data?.instalmentPlans?.filter(
    ({status}) => !['completed', 'cancelled'].includes(status)
  );

  return (
    <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
      <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
        <div>
          <label
            htmlFor="link-account"
            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
          >
            Plan
          </label>
        </div>
        <div className="sm:col-span-2">
          {loading ? (
            <MiniSpinner />
          ) : cancellablePlans?.length ? (
            <Select
              values={cancellablePlans.map(value => ({
                name: `${
                  value.nextInstalmentDueAt?.toFormat('dd MMM yyyy') ??
                  'No payments due'
                }: $${formatToDollars(value.amountPaid)}/$${formatToDollars(
                  value.amount
                )} (${value.status})`,
                id: value.id,
              }))}
              defaultValue={cancellablePlans[0].id}
              onChange={async v => onChange(v)}
            />
          ) : (
            <>No plans available for cancellation</>
          )}
          {errors.planId && <FormErrorMessage message={errors.planId} />}
        </div>
      </div>
    </div>
  );
};

export const SendNotificationForm: React.FC<SendNotificationFormProps> = ({
  contactId,
  disclosure: {isOpen, onClose},
}) => {
  const [body, setBody] = useState<string[]>();
  const [sendNotification] = useSendAdminRequestedNotificationMutation();
  const {billerConfig} = auth.useCurrentUser();
  const notificationOptions = modes(billerConfig);

  useEffect(() => {
    if (isOpen) {
      setBody([]);
    }
  }, [isOpen]);

  const [accountFetch, {data: accountData, loading: accountLoading}] =
    useGetAccountsSearchLazyQuery({variables: {offset: 0}});

  const searchAccounts = useDebouncedCallback(async value => {
    accountFetch({
      variables: {
        search: value,
        first: 10,
      },
    });
  }, 500);

  function previewSms(input: Omit<AdminRequestedNotificationInput, 'dryRun'>) {
    setBody([]);
    sendNotification({
      variables: {
        input: {
          ...input,
          dryRun: true,
        },
      },

      notifyOnNetworkStatusChange: true,
    }).then(result => {
      const body = result.data?.sendAdminRequestedNotification?.body;

      if (body) {
        setBody(body);
      }
    });
  }

  const debouncedInstalmentAmount = useDebouncedCallback(values => {
    previewSms({
      contactId,
      type: values.mode!,
      accountId: values.accountId,
      planId: values.planId,
      instalmentFrequency: values.instalmentFrequency,
      instalmentAmount: values.instalmentAmount,
    });
  }, 500);

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validateOnChange={false}
        validate={values => {
          let errors = {};

          if (
            values.mode &&
            MODES_ACCOUNT_VALIDATION.includes(values.mode) &&
            !values.accountId
          ) {
            errors = {
              ...errors,
              accountId: 'Account is required',
            };
          }

          if (
            values.mode &&
            MODES_REQUIRE_PLAN.includes(values.mode) &&
            !values.planId
          ) {
            errors = {
              ...errors,
              planId: 'Plan is required',
            };
          }

          if (
            values.mode &&
            MODES_SUGGESTED_AMOUNT.includes(values.mode) &&
            values.suggestedPlanSetup &&
            !values.instalmentAmount
          ) {
            errors = {
              ...errors,
              instalmentAmount: 'Instalment amount is required',
            };
          }

          if (
            values.mode &&
            MODES_SUGGESTED_AMOUNT.includes(values.mode) &&
            values.suggestedPlanSetup &&
            !values.instalmentFrequency
          ) {
            errors = {
              ...errors,
              instalmentFrequency: 'Instalment frequency is required',
            };
          }
          return errors;
        }}
        onSubmit={async (values, {setSubmitting}) => {
          if (!values.mode || !body) {
            return;
          }
          setSubmitting(true);

          await sendNotification({
            variables: {
              input: {
                contactId,
                type: values.mode,
                dryRun: false,
                accountId: values.accountId,
                planId: values.planId,
                instalmentAmount: values.instalmentAmount,
                instalmentFrequency: values.instalmentFrequency,
              },
            },
          });

          setSubmitting(false);

          onClose();
        }}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          errors,
          touched,
        }) => (
          <form
            className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Send Notification"
                description="Send a notification to your contact."
              />
              <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="flex-shrink-0 px-4 py-5 border-t border-gray-200 sm:px-6">
                  <SelectMode
                    selectedMode={notificationOptions.find(
                      mode => mode.id === values.mode
                    )}
                    modes={notificationOptions}
                    setMode={mode => {
                      setFieldValue('mode', mode.id, true);
                      setFieldValue('accountId', '', false);
                      setFieldValue('planId', '', false);

                      previewSms({
                        contactId,
                        type: mode.id as AdminRequestedNotificationInputType,
                        accountId: values.accountId,
                        planId: values.planId,
                      });
                    }}
                  />
                </div>
              </div>
              {values.mode && MODES_REQUIRE_ACCOUNT.includes(values.mode) && (
                <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                  <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="link-account"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                      >
                        Account
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <Search
                        onSearchInputChange={async searchValue => {
                          await searchAccounts(searchValue);
                        }}
                        onSearchSelected={selected => {
                          setFieldValue('accountId', selected.id, true);
                          setFieldValue('type', selected.type);

                          if (!values.mode) {
                            return;
                          }

                          previewSms({
                            contactId,
                            type: values.mode,
                            accountId: selected.id,
                            planId: values.planId,
                          });
                        }}
                        selectedValue={{
                          id: values.accountId,
                          type: values.type,
                        }}
                        loading={accountLoading}
                        values={formatSearchResult(accountData)}
                      />
                      {errors.accountId && touched.accountId && (
                        <FormErrorMessage message={errors.accountId} />
                      )}
                    </div>
                  </div>
                </div>
              )}
              {values.mode &&
                MODES_REQUIRE_PLAN.includes(values.mode) &&
                values.accountId && (
                  <PlanSelect
                    contactId={contactId}
                    accountId={values.accountId}
                    onChange={planId => {
                      setFieldValue('planId', planId, true);

                      previewSms({
                        contactId,
                        type: values.mode!,
                        accountId: values.accountId,
                        planId,
                      });
                    }}
                  />
                )}

              {values.mode && MODES_SUGGESTED_AMOUNT.includes(values.mode) && (
                <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                  <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="link-account"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Provide suggested amount
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <Switch
                        id="send-welcome-sms"
                        onCheckedChange={checked => {
                          setFieldValue('suggestedPlanSetup', checked);
                          if (!checked) {
                            setFieldValue('instalmentFrequency', undefined);
                            setFieldValue('instalmentAmount', undefined);
                            previewSms({
                              contactId,
                              type: values.mode!,
                              accountId: values.accountId,
                              planId: values.planId,
                              instalmentFrequency: undefined,
                              instalmentAmount: undefined,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {values.mode &&
                MODES_SUGGESTED_AMOUNT.includes(values.mode) &&
                values.suggestedPlanSetup && (
                  <>
                    <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="link-account"
                            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >
                            Frequency
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <Select
                            defaultValue="weekly"
                            values={[
                              {
                                id: 'weekly',
                                name: 'Weekly',
                              },
                              {
                                id: 'fortnightly',
                                name: 'Fortnightly',
                              },
                              {
                                id: 'monthly',
                                name: 'Monthly',
                              },
                            ]}
                            onChange={async value => {
                              setFieldValue('instalmentFrequency', value);
                              previewSms({
                                contactId,
                                type: values.mode!,
                                accountId: values.accountId,
                                planId: values.planId,
                                instalmentFrequency:
                                  value as InstalmentFrequency,
                                instalmentAmount: values.instalmentAmount,
                              });
                            }}
                          />

                          {errors.instalmentFrequency &&
                            touched.instalmentFrequency && (
                              <FormErrorMessage
                                message={errors.instalmentFrequency}
                              />
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            htmlFor="instalmentAmount"
                            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >
                            Instalment Amount
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <CurrencyInput
                            name="amount"
                            id="amount"
                            className="focus:ring-blue-500 focus:border-blue-500 block sm:text-sm border-gray-300 rounded-md w-full"
                            placeholder="Amount"
                            prefix="$"
                            decimalsLimit={2}
                            onValueChange={value => {
                              const formattedValue = formatToCents(
                                Number(value)
                              );
                              setFieldValue(
                                'instalmentAmount',
                                value ? formattedValue : ''
                              );

                              debouncedInstalmentAmount({
                                ...values,
                                instalmentAmount: formattedValue,
                              });
                            }}
                          />

                          {errors.instalmentAmount &&
                            touched.instalmentAmount && (
                              <FormErrorMessage
                                message={errors.instalmentAmount}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

              <SmsPreview
                messages={
                  body?.length
                    ? [
                        {
                          key: contactId,
                          text: body,
                          sent: false,
                        },
                      ]
                    : []
                }
              />
            </div>

            <FormSubmission
              isSubmitting={isSubmitting || !body?.length}
              onCancel={onClose}
              submissionButtonText="Send Notification"
              submissionDisabled={Object.keys(errors).length > 0}
            />
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
