import React from 'react';
import {Formik} from 'formik';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from './components/FormHeader';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {
  Account,
  InstalmentFrequency,
  InstalmentPlanMode,
  PaymentMethod,
  PaymentMethodStatus,
  useCreateInstalmentPlanMutation,
  usePreviewInstalmentPlanMutation,
} from 'lib/graphql/API';
import {Select} from 'components/atoms/Select';
import {getImageAndAltText} from 'lib/logos';

import {BlockedSidebar} from '../components/BlockedSidebar';
import {useNavigate} from 'react-router-dom';

import {AbsoluteDate, errs, formatToDollars, WEB_CONTEXT} from 'payble-shared';
import {SearchAccount} from 'components/organisms/SearchAccount';
import {formatPaymentMethod} from './utils/formatPaymentMethod';
import {GenerateInstalments} from './components/GenerateInstalments';

import NumberFormat from 'react-number-format';
import {Loading} from 'components/atoms/Loading';
import {FormSubmission} from './components/FormSubmission';
import {ValidationError} from 'payble-shared/src/errs';
import {FormErrorMessage} from '../components/FormErrorMessage';

export type AddInstalmentPlanFormValues = {
  paymentMethodId?: string;
  accountId?: string;
  startDate?: AbsoluteDate;
  frequency?: InstalmentFrequency;
};

const today = AbsoluteDate.today(WEB_CONTEXT);

const FORM_INITIAL_VALUES: AddInstalmentPlanFormValues = {
  paymentMethodId: undefined,
  accountId: undefined,
  startDate: today,
  frequency: 'weekly' as InstalmentFrequency,
};

type AddFlexiblePlanFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  paymentMethods: PaymentMethod[] | null | undefined;
} & (
  | {contactId: string; payerId?: undefined; account?: undefined}
  | {contactId?: undefined; payerId: string; account: Account}
);

export const AddFlexiblePlanForm: React.FC<AddFlexiblePlanFormProps> = ({
  disclosure: {isOpen, onClose},
  paymentMethods: unfilteredPaymentMethods,
  contactId,
  payerId,
  account,
}) => {
  const navigate = useNavigate();

  const [createInstalmentPlan] = useCreateInstalmentPlanMutation();
  const [
    previewInstalmentPlan,
    {
      data: previewInstalments,
      loading: loadingPreview,
      reset,
      error: previewError,
    },
  ] = usePreviewInstalmentPlanMutation();

  const paymentMethods = unfilteredPaymentMethods?.filter(
    pm => pm.status === PaymentMethodStatus.Active
  );

  const noPaymentMethods = !paymentMethods || paymentMethods?.length === 0;
  const previewPlan =
    !!previewInstalments?.previewInstalmentPlan.instalments.length;

  if (noPaymentMethods) {
    return (
      <BlockedSidebar
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        headerTitle="Add Payment Plan"
        headerDescription="Add a new payment plan for contact."
        headline="Missing Payment Method"
        text="To be able to create a payment plan the contact need to have a payment method. Please add a payment method to the contact."
        icon="ExclamationTriangleIcon"
      />
    );
  }

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validate={values => {
          const errors: {
            paymentMethodId?: string;
            accountId?: string;
            startDate?: string;
            frequency?: string;
          } = {};

          if (values.startDate && values.startDate.isBefore(today)) {
            errors.startDate = `Start date cannot be before ${today.toFormat('dd/MM/yyyy')}`;
          }

          return errors;
        }}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          setSubmitting(true);

          const {paymentMethodId, startDate, frequency} = values;

          const linkToAccountId = account?.id ?? values.accountId;

          if (!linkToAccountId || !paymentMethodId) {
            return;
          }

          const result = await createInstalmentPlan({
            variables: {
              input: {
                accountId: linkToAccountId,
                payerId,
                contactId,
                paymentMethodId,
                payMode: InstalmentPlanMode.PayEveryX,
                startDate,
                frequency,
              },
            },
            refetchQueries: ['getInstalmentPlans', 'getInstalmentPlanEvents'],
            awaitRefetchQueries: true,
          });

          if (result?.errors) {
            setSubmitting(false);

            const error = errs
              .fromGraphQL({
                graphQLErrors: result.errors,
              })
              .first();

            if (error instanceof ValidationError) {
              setErrors({
                accountId: error.message ?? 'Something went wrong.',
              });
            }

            return;
          }

          setSubmitting(false);

          if (contactId && result.data?.createInstalmentPlan?.id) {
            onClose();
            navigate(`/audience/contact/${contactId}/instalment-plans`);
          }

          if (payerId && result.data?.createInstalmentPlan?.id) {
            onClose();
            navigate(`/audience/account/${linkToAccountId}/instalment-plans`);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldError,
          isSubmitting,
        }) => (
          <form
            className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Add Payment Plan"
                description="Add a new payment plan for contact."
              />
              <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                {paymentMethods?.length && !noPaymentMethods ? (
                  <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        htmlFor="payment-method"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                      >
                        Payment Method
                      </label>
                    </div>
                    <div className="sm:col-span-2">
                      <Select
                        onChange={async value => {
                          await setFieldValue('paymentMethodId', value);
                        }}
                        values={paymentMethods?.map(paymentMethod => ({
                          id: paymentMethod.id,
                          name: formatPaymentMethod(paymentMethod),
                          avatar: getImageAndAltText(paymentMethod).image,
                        }))}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              {!account && (
                <SearchAccount
                  onDataChanged={data => {
                    setFieldValue('accountId', data?.account?.id);
                  }}
                  onSearchError={error => {
                    setFieldError('accountId', error?.message);
                  }}
                  errorMessage={
                    errors.accountId && touched.accountId
                      ? errors.accountId
                      : undefined
                  }
                />
              )}

              {account && errors.accountId && (
                <div className="py-2 px-5 text-">
                  <p className="mt-1 text-sm text-red-600">
                    {errors.accountId}
                  </p>
                </div>
              )}

              {(values.accountId || account) && (
                <>
                  <div className="space-y-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="startDate"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Start Date
                      </label>
                      <div className="mt-2">
                        <input
                          id="startDate"
                          name="startDate"
                          type="date"
                          disabled={previewPlan}
                          min={today.toISO()}
                          defaultValue={today.toISO()}
                          onChange={e => {
                            const date = AbsoluteDate.maybeFromISO(
                              e.target.value
                            );
                            if (date) setFieldValue('startDate', date);
                          }}
                          value={values.startDate?.toISO()}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="col-span-2">
                        {errors.startDate && (
                          <FormErrorMessage message={errors.startDate} />
                        )}
                      </div>
                    </div>

                    <div className="sm:col-span-1">
                      <label
                        htmlFor="frequency"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Frequency
                      </label>
                      <div className="mt-2">
                        <Select
                          id="frequency"
                          disabled={previewPlan}
                          values={[
                            {
                              id: 'weekly',
                              name: 'Weekly',
                            },
                            {
                              id: 'fortnightly',
                              name: 'Fortnightly',
                            },
                            {
                              id: 'monthly',
                              name: 'Monthly',
                            },
                          ]}
                          onChange={async value => {
                            setFieldValue('frequency', value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {loadingPreview && <Loading />}
                  {previewError && (
                    <div className="py-2 px-5 text-">
                      <p className="mt-1 text-sm text-red-600">
                        {previewError.message}
                      </p>
                    </div>
                  )}
                  {previewInstalments?.previewInstalmentPlan &&
                    previewInstalments?.previewInstalmentPlan.instalments.map(
                      ({dueAt, amount, instalmentId}) => (
                        <div className="divide-y divide-gray-500 py-2 px-5 transition-opacity">
                          <div
                            key={instalmentId}
                            className="flex text-xs sm:text-sm items-center"
                          >
                            <div className="basis-6/12 px-2">
                              {AbsoluteDate.fromISO(dueAt as any).toISO()}
                            </div>
                            <div className="basis-6/12 text-right px-2">
                              <NumberFormat
                                value={formatToDollars(amount)}
                                displayType={'text'}
                                thousandSeparator={true}
                                decimalSeparator={'.'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={'$'}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  {previewInstalments?.previewInstalmentPlan && (
                    <div className="py-2 px-5" id="plans-preview">
                      <div className="flex text-xs sm:text-sm items-center">
                        <div className="basis-6/12 px-2">
                          <strong>Total</strong>
                        </div>
                        <div className="basis-6/12 text-right px-2">
                          <strong>
                            <NumberFormat
                              value={formatToDollars(
                                previewInstalments.previewInstalmentPlan.instalments.reduce(
                                  (acc, {amount}) => acc + amount,
                                  0
                                )
                              )}
                              displayType={'text'}
                              thousandSeparator={true}
                              decimalSeparator={'.'}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              prefix={'$'}
                            />
                          </strong>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {previewInstalments?.previewInstalmentPlan.instalments ? (
              <FormSubmission
                isSubmitting={isSubmitting || noPaymentMethods}
                onCancel={() => reset()}
                cancelButtonText="Reset"
                submissionButtonText="Add Payment Plan"
              />
            ) : (
              <GenerateInstalments
                onClick={() => {
                  const {paymentMethodId, startDate, frequency} = values;

                  const linkToAccountId = account?.id ?? values.accountId;

                  if (!linkToAccountId || !paymentMethodId) {
                    return;
                  }

                  previewInstalmentPlan({
                    variables: {
                      input: {
                        accountId: linkToAccountId,
                        payerId,
                        contactId,
                        paymentMethodId,
                        payMode: InstalmentPlanMode.PayEveryX,
                        startDate,
                        frequency,
                      },
                    },
                  });
                }}
                onCancel={() => {
                  onClose();
                }}
                submissionDisabled={Object.keys(errors).length > 0}
              />
            )}
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
