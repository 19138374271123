import React from 'react';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import {AuditLogEdge} from 'lib/graphql/API';
import {Paginator} from 'components/atoms/Paginator';
import {capitalize} from 'payble-shared';
import {DateTime} from 'luxon';
import classNames from 'classnames';

type AuditLogTableProps = {
  loading: boolean;
  error?: Error;
  auditLogs: AuditLogEdge[];
  goToPage?: (p: number) => void;
  page?: number;
  total?: number;
  onClickAdmin: (args: {adminId: string; adminName: string}) => unknown;
};

export const AuditLogTable: React.FC<AuditLogTableProps> = ({
  loading,
  error,
  auditLogs,
  goToPage,
  page,
  total,
  onClickAdmin,
}) => {
  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-2">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Action
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              User
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Outcome
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {loading ? (
            <tr key={'loading'}>
              <td colSpan={15} className="py-10">
                <Loading />
              </td>
            </tr>
          ) : null}
          {error ? (
            <tr key={'error'}>
              <td colSpan={15} className="pb-10">
                <ErrorMessage message={error.message} />
              </td>
            </tr>
          ) : null}
          {!loading &&
            !error &&
            auditLogs &&
            auditLogs.map(auditLog => (
              <tr key={auditLog.node.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div>
                    <div className="font-medium text-gray-900">
                      {capitalize(auditLog.node.action)}
                    </div>

                    <span>
                      {DateTime.fromISO(auditLog.node.timestamp).toLocaleString(
                        DateTime.DATETIME_FULL
                      )}
                    </span>
                  </div>
                </td>

                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <div className="font-medium text-gray-900">
                        {auditLog.node.adminName}
                      </div>

                      <div className="font-medium text-gray-600">
                        {auditLog.node.ip}
                      </div>
                      <a
                        className="cursor-pointer"
                        onClick={() =>
                          onClickAdmin({
                            adminId: auditLog.node.adminId,
                            adminName: auditLog.node.adminName,
                          })
                        }
                      >
                        <span className="font-extralight text-blue-600">
                          Filter for this user
                        </span>
                      </a>
                    </div>
                  </div>
                </td>

                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <span
                        className={classNames([
                          'inline-flex items-center text-xs font-medium',
                          auditLog.node.isSuccess
                            ? 'text-green-800'
                            : 'text-red-800',
                        ])}
                      >
                        {auditLog.node.isSuccess ? 'Success' : 'Failure'}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paginator
        loading={loading}
        rows={auditLogs?.length || 0}
        goToPage={goToPage}
        page={page}
        total={total}
      />
    </div>
  );
};
