import {twMerge} from 'tailwind-merge';

/**
 * It takes an array of strings, filters out the falsy values, and joins the remaining strings with a
 * space
 * @param {string[]} classes - string[] - This is a rest parameter. It means that the function can take
 * any number of parameters.
 * @returns A string of all the classes that are truthy.
 */
export const classNames = (...classes: string[]) => {
  return twMerge(classes.filter(Boolean).join(' '));
};

export const activePageNumberClasses =
  'z-10 bg-blue-50 border-blue-500 text-blue-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium';

export const inactivePageNumberClasses =
  'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium';
