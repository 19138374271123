import {useQuery} from '@tanstack/react-query';
import {GraphQLClient} from 'graphql-request';
import {Form, Icon, Loading, Text, useFormContext} from 'payble-ui';
import {useEffect} from 'react';
import {getBankByCodeGql} from '../gql/getBankByCode';
import {z} from 'zod';

export const directDebitFieldsSchema = {
  bsb: z.string().refine(value => /^\d{3}-\d{3}$/.test(value)),
  accountNumber: z.string().refine(value => /^\d{9}$/.test(value)),
};

const BANK_ICON: Record<string, string> = {
  CBA: '/cms/app-shared/bank/cba.svg',
  ING: '/cms/app-shared/bank/ing.png',
};

const BsbNumberCheck = ({client}: {client: GraphQLClient}) => {
  const {watch, setError, clearErrors} = useFormContext();
  const bsb = watch('bsb');
  const {success: valid} = directDebitFieldsSchema.bsb.safeParse(bsb ?? '');
  const {isLoading, isSuccess, data} = useQuery<{
    bankByCode: {
      name: string;
    };
  }>({
    queryKey: ['validateBsb', bsb],
    queryFn: () => {
      return client.request(getBankByCodeGql, {code: bsb, region: 'au'});
    },
    enabled: valid,
  });

  useEffect(() => {
    if (valid) {
      clearErrors('bsb');
    }
  }, [valid]);

  useEffect(() => {
    if (isSuccess && !data.bankByCode) {
      setError('bsb', {message: 'Unknown BSB number'});
    }
  }, [isSuccess, data]);

  if (isLoading)
    return (
      <div className="absolute bottom-0 right-0 py-4">
        <Loading variant="mini" />
      </div>
    );

  if (isSuccess && data.bankByCode) {
    return (
      <div className="flex items-center gap-1 mt-1">
        <Icon name="check" variant="fill" color="green" />
        <Text size="xs">{data.bankByCode.name}</Text>
        {BANK_ICON[data.bankByCode.name] && (
          <img src={BANK_ICON[data.bankByCode.name]} className="h-4" />
        )}
      </div>
    );
  }

  return null;
};

export const DirectDebitFields = ({client}: {client: GraphQLClient}) => {
  return (
    <>
      <div className="relative">
        <Form.InputNumberFormat
          name="bsb"
          label="BSB number"
          format="###-###"
          placeholder="000-000"
          allowLeadingZeros
        />
        <BsbNumberCheck client={client} />
      </div>
      <Form.InputNumberFormat
        name="accountNumber"
        label="Bank account number"
        format="#########"
        placeholder="000000000"
        autoComplete={'off'}
        allowLeadingZeros
      />
    </>
  );
};
