import React, {ReactNode} from 'react';

const CtaWrapper: React.FC<{children?: ReactNode}> = ({children}) => {
  return <div className="mr-2">{children}</div>;
};
type AudienceCtaProps = {
  mode: 'contacts' | 'accounts' | 'payers';
  contacts: {
    onAdd: () => void;
  };
  payers: {
    onAdd: () => void;
  };
};

export const AudienceCta: React.FC<AudienceCtaProps> = ({
  mode,
  contacts,
  payers,
}) => {
  switch (mode) {
    case 'contacts':
      return (
        <CtaWrapper>
          <button
            type="button"
            onClick={contacts.onAdd}
            className="w-full inline-flex justify-center disabled:opacity-50 rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Add Contact
          </button>
        </CtaWrapper>
      );
    case 'accounts':
      return <CtaWrapper />;
    case 'payers':
      return (
        <CtaWrapper>
          <button
            type="button"
            onClick={payers.onAdd}
            className="w-full inline-flex justify-center disabled:opacity-50 rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Add Payer
          </button>
        </CtaWrapper>
      );
    default:
      return <CtaWrapper />;
  }
};
