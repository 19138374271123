import React, {useEffect, useState} from 'react';

import {Formik} from 'formik';
import {PlanRequest, RequestOutcome} from 'lib/graphql/API';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from './components/FormHeader';
import {FormSubmission} from './components/FormSubmission';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {
  useGetAccountByIdLazyQuery,
  useGetContactByIdLazyQuery,
  useReviewPlanRequestMutation,
} from 'lib/graphql/API';
import {PaymentMethodItem} from 'components/organisms/PaymentMethodItem';
import {SelectMode} from './components/SelectMode';
import {
  InstalmentPreview,
  generatePreviewInstalments,
} from '../helpers/generatePreviewInstlaments';
import {PlanSummary} from './components/PlanSummary';
import {Loading} from 'components/atoms/Loading';
import {
  AccountDetails,
  ContactDetails,
  PlanRequestDetails,
} from './components/DetailsSections';
import {CancelModal} from '../components/CancelModal';
import {PopOverCtaBottomRow} from 'components/atoms/PopOverCtaBottomRow';
import {AddInstalmentPlanForm} from './AddInstalmentPlanForm';
import {DateFrequency} from 'payble-shared';
import {useCurrentUser} from '../../../lib/auth/useAuthState';

const modes: {name: string; id: RequestOutcome; description: string}[] = [
  {
    name: 'Accept Plan Request',
    id: RequestOutcome.Accepted,
    description:
      'A payment plan will be created and the contact will be notified.',
  },
  {
    name: 'Reject Plan Request',
    id: RequestOutcome.Rejected,
    description:
      'The plan request will be rejected and the contact will be notified.',
  },
];

type Form = {
  mode?: RequestOutcome;
  missed: number;
  canSkip: boolean;
};

type SubmitReviewAccept = {
  mode: RequestOutcome.Accepted;
  missed: number;
  canSkip: boolean;
  reason?: undefined;
};

type SubmitReviewReject = {
  mode: RequestOutcome.Rejected;
  reason: string;
  missed?: undefined;
  canSkip?: undefined;
};

const FORM_INITIAL_VALUES: Form = {
  mode: undefined,
  missed: 2,
  canSkip: true,
};

type ReviewPlanRequestFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  planRequest?: PlanRequest;
};

export const ReviewPlanRequestForm: React.FC<ReviewPlanRequestFormProps> = ({
  disclosure: {isOpen, onClose},
  planRequest,
}) => {
  const [getAccountById, {data: accountData, loading: loadingAccount}] =
    useGetAccountByIdLazyQuery();
  const [getContactById, {data: contactData, loading: loadingContact}] =
    useGetContactByIdLazyQuery();
  const [previewInstalments, setPreviewInstalments] = useState<
    InstalmentPreview[]
  >([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [submittingReview, setSubmittingReview] = useState(false);
  const addInstalmentPlanFormDisclosure = useDisclosure();

  const {billerConfig} = useCurrentUser();

  const [reviewPlanRequest] = useReviewPlanRequestMutation();

  useEffect(() => {
    if (planRequest) {
      getAccountById({
        variables: {
          id: planRequest.accountId,
        },
      });

      const contactId = planRequest.contactId;

      if (contactId) {
        getContactById({
          variables: {
            id: contactId,
          },
        });
      }
    }
  }, [planRequest]);

  useEffect(() => {
    if (!planRequest || !accountData?.account?.amountOwing) {
      return;
    }

    const startAt = planRequest.startAt;

    const previewInstalments = generatePreviewInstalments(
      {
        startDate: startAt,
        frequency: planRequest.frequency as DateFrequency,
        instalmentAmount: planRequest.instalmentAmount,
        mode: 'totalAmount',
        amountOwing: accountData?.account?.amountOwing,
      },
      {billerConfig}
    );

    setPreviewInstalments(previewInstalments);
  }, [planRequest, accountData?.account?.amountOwing]);

  const contact = contactData?.contact;
  const paymentMethod = contact?.paymentMethods?.find(
    paymentMethod => paymentMethod.id === planRequest?.paymentMethodId
  );

  const submitReview = async ({
    mode,
    reason,
    missed,
    canSkip,
  }: SubmitReviewAccept | SubmitReviewReject) => {
    if (!planRequest) {
      return;
    }

    setSubmittingReview(true);

    const result = await reviewPlanRequest({
      variables: {
        input: {
          id: planRequest?.id,
          outcome: mode,
          reason,
          instalments: previewInstalments.map(({amount, dueAt}) => ({
            amount,
            dueAt,
          })),
          ...(mode === RequestOutcome.Accepted
            ? {allowedMissedInstalments: missed, canSkip: canSkip}
            : {}),
        },
      },
      refetchQueries: ['getRequestedPlansBySearch'],
    });

    setSubmittingReview(false);

    return result;
  };

  const onRejectConfirm = async (reason: string) => {
    setShowRejectModal(false);
    await submitReview({mode: RequestOutcome.Rejected, reason});
    onClose();
  };

  const planNeedsReview = !planRequest?.rejectedAt && !planRequest?.acceptedAt;
  const loading = loadingAccount || loadingContact || submittingReview;
  const account = accountData?.account;

  return (
    <>
      <PopOverSidebar
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <Formik
          initialValues={FORM_INITIAL_VALUES}
          onSubmit={async (values, {setSubmitting}) => {
            setSubmitting(true);

            if (!planRequest || !values.mode) {
              return;
            }

            if (values.mode === RequestOutcome.Rejected) {
              setShowRejectModal(true);
              setSubmitting(false);
              return;
            }

            await submitReview({
              mode: values.mode,
              missed: values.missed,
              canSkip: values.canSkip,
            });

            setSubmitting(false);
            onClose();
          }}
        >
          {({values, handleSubmit, setFieldValue, isSubmitting}) => (
            <form
              className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              onSubmit={handleSubmit}
            >
              <div className="flex-1">
                <FormHeader
                  setOpen={onClose}
                  title="Review"
                  description="Review the plan request."
                />

                {loading ? (
                  <div className="mt-16">
                    <Loading />
                  </div>
                ) : (
                  <div className="p-4">
                    {!!previewInstalments.length && (
                      <PlanSummary
                        startDate={previewInstalments[0].dueAt}
                        endDate={
                          previewInstalments[previewInstalments.length - 1]
                            .dueAt
                        }
                        instalments={previewInstalments}
                      />
                    )}
                    <div className="px-4 py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      {!loadingAccount && !!accountData?.account && (
                        <AccountDetails
                          externalId={accountData.account.externalId}
                          type={accountData.account.type}
                          amountOwing={accountData.account.amountOwing}
                          description={accountData.account.description}
                        />
                      )}
                      {contact && (
                        <ContactDetails
                          name={`${contact.givenName} ${contact.familyName}`}
                          mobile={contact.mobile}
                          email={contact.email}
                        />
                      )}
                      {planRequest && (
                        <PlanRequestDetails
                          instalmentAmount={planRequest.instalmentAmount}
                          frequency={planRequest.frequency}
                          startAt={planRequest.startAt}
                        />
                      )}

                      {paymentMethod && (
                        <>
                          <div>
                            <h4 className="mt-4 mb-2 font-medium text-gray-900">
                              Payment Method
                            </h4>
                            <div>
                              <div className="flex justify-end pl-[50%]">
                                <PaymentMethodItem
                                  paymentMethod={paymentMethod}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {planNeedsReview && (
                        <div className="flex-shrink-0 border-t border-gray-200 py-5">
                          <SelectMode
                            selectedMode={modes.find(
                              mode => mode.id === values.mode
                            )}
                            modes={modes}
                            setMode={mode => {
                              setFieldValue('mode', mode.id);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {planRequest?.rejectedAt && contact && !loading && (
                <PopOverCtaBottomRow>
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={() => onClose()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      addInstalmentPlanFormDisclosure.onOpen();
                    }}
                    className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Create Plan For Contact
                  </button>
                </PopOverCtaBottomRow>
              )}
              {planNeedsReview && (
                <FormSubmission
                  isSubmitting={
                    isSubmitting &&
                    loadingAccount &&
                    loadingContact &&
                    !!values.mode
                  }
                  onCancel={onClose}
                  submissionButtonText="Submit Review"
                />
              )}
            </form>
          )}
        </Formik>

        <CancelModal
          headline="Reject Plan Request"
          description="The reason will be sent in a notification to the contact. This action cannot be undone."
          isOpen={showRejectModal}
          onClose={() => setShowRejectModal(false)}
          onReject={onRejectConfirm}
          reasons={[
            'Insufficient payments',
            'Deferred to hardship process',
            'User requested',
            'Other',
          ]}
        />
      </PopOverSidebar>
      {contact && account && (
        <AddInstalmentPlanForm
          contactId={contact.id}
          disclosure={addInstalmentPlanFormDisclosure}
          paymentMethods={contact.paymentMethods}
          accountId={account.id}
          amountOwing={account.amountOwing}
          defaultValues={{
            paymentMethodId: planRequest?.paymentMethodId,
            mode: 'totalAmount',
            instalmentAmount: planRequest?.instalmentAmount,
            planTotalAmount: account.amountOwing,
            planFrequency: planRequest?.frequency,
            startDate: planRequest?.startAt,
          }}
        />
      )}
    </>
  );
};
