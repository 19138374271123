import {z} from 'zod';

export const paymentMethodType = z.enum([
  'card',
  'direct_debit',
  'nz_direct_debit',
]);

export const paymentMethodStatus = z.enum(['active', 'disabled', 'removed']);

export const paymentMethodResponse = z.object({
  id: z.string(),
  type: paymentMethodType,
  status: paymentMethodStatus,
  reason: z.string().optional(),
  card: z.union([
    z.null(),
    z
      .object({
        holderName: z.string(),
        brand: z.string(),
        last4: z.string(),
        expiryMonth: z.number(),
        expiryYear: z.number(),
      })
      .optional(),
  ]),
  bank: z.union([
    z.null(),
    z
      .object({
        bsb: z.string(),
        name: z.string(),
        accountNumber: z.string(),
        accountName: z.string(),
      })
      .optional(),
  ]),
  nzBank: z.union([
    z.null(),
    z
      .object({
        name: z.string(),
        accountNumber: z.string(),
        accountName: z.string(),
      })
      .optional(),
  ]),
});

export type PaymentMethodResponse = NonNullable<
  z.infer<typeof paymentMethodResponse>
>;

export const contactSourceSchema = z.enum(['profile', 'setup']);

export const contactResponse = z.object({
  id: z.string(),
  billerId: z.string(),
  title: z.string(),
  givenName: z.string(),
  familyName: z.string(),
  email: z.union([z.literal(''), z.string().email()]),
  emailVerified: z.boolean(),
  mobile: z.string(),
  mobileVerified: z.boolean(),
  status: z.enum(['active', 'archived']),
  sendingEmailReceipts: z.boolean(),
  paymentMethods: z.union([
    z.null(),
    z.array(paymentMethodResponse).optional(),
  ]),
});

export type ContactResponse = NonNullable<z.infer<typeof contactResponse>>;
