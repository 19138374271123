import React from 'react';
import {Formik} from 'formik';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormErrorMessage} from '../components/FormErrorMessage';
import {FormHeader} from './components/FormHeader';
import {FormSubmission} from './components/FormSubmission';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {useAPIMutation} from 'lib/api';
import {useToast} from 'payble-ui';
import {auth} from 'lib/index';

const FORM_INITIAL_VALUES = {
  givenName: '',
  familyName: '',
  email: '',
};

type EditContactFormProps = {
  disclosure: ReturnType<typeof useDisclosure>;
  givenName: string;
  familyName: string;
  email: string;
  contactId: string;
  refetch: () => void;
};

export const EditContactForm: React.FC<EditContactFormProps> = ({
  disclosure: {isOpen, onClose},
  givenName,
  familyName,
  email,
  contactId,
  refetch,
}) => {
  const {billerConfig} = auth.useCurrentUser();
  const {toast} = useToast();
  const {mutateAsync: updateContact} = useAPIMutation('updateContact', {
    query: {
      onSuccess: () => {
        onClose();
        refetch();
      },
      onError: e => {
        toast({
          title: 'Failed to update contact',
          description: e.message,
          variant: 'destructive',
        });
      },
    },
  });

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Formik
        initialValues={{...FORM_INITIAL_VALUES, givenName, familyName, email}}
        validate={values => {
          let errors = {};
          if (!values.givenName) {
            errors = {
              ...errors,
              givenName: 'Given name is required.',
            };
          }
          if (!values.familyName) {
            errors = {
              ...errors,
              familyName: 'Last name is required.',
            };
          }
          if (billerConfig.region === 'NZ') {
            if (!values.email) {
              errors = {
                ...errors,
                email: 'E-mail is required.',
              };
            }
          }

          return errors;
        }}
        onSubmit={async (values, {setSubmitting}) => {
          setSubmitting(true);
          await updateContact({
            givenName: values.givenName,
            familyName: values.familyName,
            email: values.email,
            contactId,
          });
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          touched,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl"
            onSubmit={handleSubmit}
          >
            <div className="flex-1">
              <FormHeader
                setOpen={onClose}
                title="Edit Contact"
                description="Edit contact name and email."
              />

              <div className="py-6 space-y-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="given-name"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      Given Name
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="givenName"
                      id="given-name"
                      placeholder="Given Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.givenName}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                    {errors.givenName && touched.givenName && (
                      <FormErrorMessage message={errors.givenName} />
                    )}
                  </div>
                </div>

                <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="family-name"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      Last Name
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="familyName"
                      id="family-name"
                      placeholder="Family Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.familyName}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                    {errors.familyName && touched.familyName && (
                      <FormErrorMessage message={errors.familyName} />
                    )}
                  </div>
                </div>

                <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                    >
                      E-mail
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="E-mail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                    {errors.email && touched.email && (
                      <FormErrorMessage message={errors.email} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <FormSubmission
              isSubmitting={isSubmitting}
              onCancel={onClose}
              submissionDisabled={Object.keys(errors).length > 0}
              submissionButtonText="Update Contact"
            />
          </form>
        )}
      </Formik>
    </PopOverSidebar>
  );
};
