import React, {Fragment, useEffect, useState} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid';
import classNames from 'classnames';

type SelectProps = {
  values: {
    id: string;
    name: string;
    avatar?: string;
  }[];
  onChange: (id: string) => Promise<void>;
  wrapperClassNames?: string;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
};

export const Select: React.FC<SelectProps> = ({
  values,
  onChange,
  wrapperClassNames,
  defaultValue,
  disabled,
  id,
}) => {
  const [selected, setSelected] = useState(values[0]);

  useEffect(() => {
    onChange(values[0].id);

    if (defaultValue) {
      const selectedValue = values.find(value => value.id === defaultValue);
      if (selectedValue) {
        setSelected(selectedValue);
      }
    }
  }, []);

  return (
    <Listbox
      value={selected}
      onChange={async newSelected => {
        setSelected(newSelected);
        await onChange(newSelected.id);
      }}
      disabled={disabled}
    >
      {({open}) => (
        <div className={classNames(wrapperClassNames, 'relative')} id={id}>
          <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm sm:leading-6">
            <span className="flex items-center">
              {selected.avatar && (
                <img
                  src={selected.avatar}
                  alt=""
                  className="h-5 w-5 flex-shrink-0 rounded-full"
                />
              )}
              <span className="block truncate">{selected.name}</span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {values.map(value => (
                <Listbox.Option
                  key={value.id}
                  className={({active}) =>
                    classNames(
                      active ? 'bg-blue-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={value}
                >
                  {({active}) => (
                    <>
                      <div className="flex items-center">
                        {value.avatar && (
                          <img
                            src={value.avatar}
                            alt=""
                            className="h-5 w-5 flex-shrink-0 rounded-full"
                          />
                        )}

                        <span
                          className={classNames(
                            value.id === selected.id
                              ? 'font-semibold'
                              : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {value.name}
                        </span>
                      </div>
                      {value.id === selected.id ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-blue-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};
